/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Voltar",
      "Baixe na App Store",
      "Baixe no Google Play",
      "Carrossel",
      "Slide {{number}}",
      "Próximo slide",
      "Slide anterior",
      "Slide",
      "Slide {{number}} de {{total}}",
      "Não vender nem compartilhar as minhas informações pessoais",
      "Não vender nem compartilhar as minhas informações pessoais",
      "Envie Compliments no Bumble e chame a atenção de alguém antes mesmo de rolar conexão. Experimente agora",
      "Compliments | Como enviar um Compliment | Bumble",
      "Uma página personalizada que destaca as pessoas mais compatíveis com você, atualizada todos os dias.",
      "Descubra | Encontre pessoas compatíveis | Bumble",
      "Tenha encontros como você preferir e controle quem vê seu perfil do Bumble com o Modo Invisível. Saiba mais sobre como usar o Modo Invisível no Bumble em nosso guia",
      "Modo Invisível | Esconda Seu Perfil | Bumble",
      "Depois de ter uma conversa pessoalmente no Bumble, veja se rola uma química continuando a conversa no aplicativo.",
      "Conexão imediata | Mantenha a conversa no aplicativo | Bumble",
      "Experimente uma nova maneira de iniciar uma conversa com o Opening Move no Bumble. Chame a atenção de alguém, defina as regras e tenha boas conversas ainda mais rápido",
      "Opening Moves no Bumble | Bumble",
      "Precisa de uma pequena pausa ou de algum tempo para você? Descubra como pausar sua conta do Bumble com o Modo Não Perturbe do Bumble. Ele permite que você tenha encontros de acordo com seus próprios termos.",
      "Modo Não Perturbe | Pause Sua Conta | Bumble",
      "Acabou de ver alguém que você realmente curtiu no Bumble? Use um SuperSwipe para mostrar que você está falando sério. Toque na estrela do perfil para começar ou veja mais detalhes aqui",
      "Bumble SuperSwipe | O que é um SuperSwipe? | Bumble",
      "Viajando a trabalho ou a lazer? O Modo Viagem no Bumble pode te ajudar a fazer novas conexões divertidas em um novo lugar e ter encontros onde quer que você vá. Descubra o Modo Viagem aqui agora",
      "Modo Viagem do Bumble | Conheça Alguém em Qualquer Lugar | Bumble",
      "Quer saber se a conexão que você sente por meio das mensagens é igual na vida real? Experimente uma chamada por vídeo ou chamada de voz no Bumble e veja a vibe é boa.",
      "Chamada de vídeo | Faça uma chamada | Bumble",
      "Experimente a emoção do Bumble Web, a característica definitiva.",
      "Bumble Web | Bumble",
      "Encerrar contratos aqui",
      "Página inicial do Bumble",
      "Fechar menu",
      "Abrir menu",
      "Selecionar idioma",
      "Rodapé",
      "Acessibilidade no Bumble",
      "A Apple e o logotipo da Apple são marcas comerciais da Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Vagas",
      "Eventos",
      "O Google Play é uma marca registrada da Google LLC.",
      "Regras",
      "Investidores",
      "Gerenciar Cookies",
      "Gerenciar Cookies",
      "Lei sobre Escravatura Moderna (Reino Unido)",
      "Notificação de coleta de dados",
      "Política de Privacidade",
      "Guias de encontros sazonais",
      "Termos e Condições",
      "App",
      "Política de privacidade de dados de saúde do consumidor de Washington",
      "Sobre",
      "Embaixadores",
      "O Buzz",
      "Conheça pessoas e faça novas amizades",
      "Desenvolva sua rede profissional e impulsione sua carreira",
      "Encontre um relacionamento, algo casual ou alguém interessante no meio do caminho.",
      "Saiba mais",
      "Viaje pelo mundo com o Modo Viagem",
      "Recursos",
      "Compliments",
      "Envie alguns Compliments e ganhe destaque na multidão.",
      "Descubra",
      "Uma página personalizada que destaca as pessoas mais compatíveis com você, atualizada todos os dias.",
      "Modo Invisível",
      "Esconda seu perfil de todo mundo com o Modo Invisível.",
      "Conexão imediata",
      "Iniciou uma conversa pessoalmente em um evento do Bumble? Continue no aplicativo.",
      "Opening Moves",
      "Escolha um Opening Moves para convidar sua conexão para iniciar uma conversa, para que você não precise fazer isso.",
      "Modo Não Perturbe",
      "Reserve um momento para você com o Modo Não Perturbe. Quando quiser voltar, você sabe onde nos encontrar.",
      "SuperSwipe",
      "Curtiu o perfil de alguém? Mostre enviando um SuperSwipe.",
      "Modo Viagem",
      "Encontre sua felicidade em qualquer lugar do mundo graças ao Modo Viagem.",
      "Chamadas de vídeo",
      "Use chamadas de vídeo e áudio para verificar se você está pronto para se encontrar pessoalmente.",
      "Aplicativo Web",
      "Ative a experiência do Bumble diretamente do seu navegador.",
      "Loja",
      "Principal",
      "Ir para o conteúdo principal",
      "Logo do Bumble",
      "Quer conhecer alguém melhor? Use a chamada de vídeo no Bumble",
      "Basta tocar no ícone de vídeo na sua conversa para iniciar uma chamada de vídeo, tudo sem compartilhar nenhuma informação pessoal de contato.",
      "Como funciona a chamada de vídeo no Bumble?",
      "Não. Todas as chamadas e conversas no Bumble mantêm suas informações pessoais seguras e protegidas. Ninguém receberá seu número ou quaisquer outros dados de contato até que você os informe.",
      "Minha conexão consegue ver meu número de telefone?",
      "Absolutamente! Se você ainda não estiver pronto para um encontro cara a cara, as chamadas de voz permitem que você se conecte por meio de áudio. Basta selecionar o ícone de chamada.",
      "Posso tentar uma chamada de áudio?",
      "Adoramos aquela notificação de *mensagem recebida*. Mas sabemos que é difícil dizer se suas conversas serão tão boas ou não pessoalmente. É aí que entram as chamadas de vídeo e de áudio.",
      "Chamada de vídeo no Bumble",
      "<a href={{video_chat_url}}>Chamadas de vídeo e chamadas de áudio</a> podem ajudar vocês a se conhecerem e a decidir se desejam retirar coisas do aplicativo. A melhor parte? Ao fazer uma chamada via Bumble, você não precisa trocar números de telefone – portanto, suas informações pessoais permanecem seguras até que você queira compartilhá-las.",
      "Verifique sua química com a chamada de vídeo.",
      "Feche a lacuna entre o relacionamento online e offline e decida se agora é a hora de se encontrar.",
      "Conheça alguém melhor (e mais rápido). Uma conversa cara a cara ou uma ligação podem revelar muito sobre uma conexão.",
      "Você está no controle. Manteremos seus dados de contato privados, para que você possa escolher quando compartilhá-los.",
      "Veja se está na hora de se encontrar pessoalmente",
      "Veja se rola uma química",
      "Conectar sem fornecer dados pessoais",
      "Por que você deveria usar chamadas de vídeo e áudio",
      "Tudo pronto para iniciar uma conversa? Você pode escolher se deseja iniciar uma chamada de áudio ou vídeo diretamente das suas conversas no aplicativo do Bumble. Para iniciar uma chamada de vídeo, toque no ícone cinza da câmera de vídeo no canto superior direito. Você também verá três pontos ao lado do ícone, clique nele e verá a opção de chamada de áudio também.",
      "Como fazer chamadas de vídeo",
      "Tudo pronto para ativar o Compliments?",
      "Quando você envia um Compliment, a pessoa que o recebe vai te ver quando você aparecer na lista de conexões dela.",
      "O que acontece quando você envia um Compliment a alguém no Bumble?",
      "Se o Compliments de alguém chamou sua atenção, vá em frente e combine com eles. Você verá o Compliments em suas conversas e as regras usuais de dar o <a href={{make_the_first_move_url}}>Primeiro Passo</a> se aplicam.",
      "Como posso responder a um Compliment?",
      "Você pode enviar {{num}} [[Compliments:num]] de graça. Quando você estiver sem Compliments, poderá comprar mais no aplicativo do Bumble em pacotes de até 30. Um deles será usado para enviar esse Compliment (salve o restante para quando você ver outro perfil que goste). Não se preocupe, não tem validade!",
      "Quantos Compliments posso enviar?",
      "Uma palavra gentil (ou duas) pode ajudar muito. Se você deseja enviar um Compliment, mas não sabe por onde começar, tente estes para se inspirar:",
      "Ver mais exemplos",
      "“Qualquer pessoa que aprecie [nome do artista], tem um ótimo gosto.”",
      "\"Seu sorriso é contagiante!\"",
      "“Seu perfil me fez rir. Muito bom!”",
      "Como enviar os melhores Compliments no Bumble",
      "Quer fazer alegrar o dia de alguém? Parece que está tudo pronto para usar o Compliments.",
      "Compliments no Bumble",
      "Use Compliments no Bumble para que essa pessoa saiba que você gosta do perfil dela. Você recebe Compliments como parte da sua assinatura do {{service_level_lexeme_name}}. É também uma forma de enviar e receber mensagens antes mesmo de rolar conexão.",
      "Como o Compliments funciona",
      "Quando você receber um Compliment, ele aparecerá no perfil do remetente. Você também receberá uma notificação informando que alguém gostou de parte do seu perfil.",
      "Veja seus compliments",
      "Você pode enviar um Compliment tocando no ícone de coração amarelo na parte inferior do perfil de alguém. Onde você toca é importante, porque você estará elogiando aquela foto específica ou parte do perfil deles! Ao clicar, uma caixa de texto será aberta para você escrever sua mensagem (pode ter até 150 caracteres).",
      "Envie um compliment",
      "BAIXE O Bumble E ATIVE O DESCUBRA AGORA",
      "Todos os dias, a partir das 9 horas da manhã, você verá novas pessoas para você no Descubra. Você pode se conectar com elas pelas coisas que vocês têm em comum.",
      "Quando o Descubra atualiza?",
      "Sua página Descubra classifica possíveis conexões em 3 categorias principais: interesses semelhantes, mesmos objetivos de encontro e comunidades em comum. Recomendações de perfil também são mostradas, com base em conexões anteriores.",
      "Como minha página Descubra é selecionada?",
      "Uma página personalizada que destaca as pessoas mais compatíveis com você, atualizada todos os dias",
      "ENCONTRE PESSOAS QUE ESTÃO NA MESMA VIBE QUE VOCÊ COM {{qr_code}} O DESCUBRA",
      "Com o Descubra você recebe uma seleção de pessoas que achamos que você gostará, com base em seus interesses semelhantes, objetivos de relacionamento e comunidades.",
      "Experimente o Descubra",
      "QUER UMA NOVA MANEIRA DE ENCONTRAR COISAS EM COMUM?",
      "A página Descubra se renova a cada dia para mostrar ainda mais pessoas compatíveis com você. Assim, você pode gastar menos tempo esperando por uma conexão e mais tempo conhecendo pessoas de quem certamente gostará.",
      "TODOS OS DIAS NOVAS RECOMENDAÇÕES",
      "As recomendações do Descubra são baseadas no seu perfil e com quem já rolou uma conexão anteriormente. Com isso, você verá pessoas que curtam e queiram o mesmo que você, seja um simples rolinho à primavera ou até mesmo um relacionamento longo.",
      "PERSONALIZADO{{__EOL__}}SÓ PARA VOCÊ",
      "Baixar Bumble",
      "Perguntas Frequentes",
      "Tudo pronto para navegar pelo Bumble de forma privada?",
      "Ao ativar o Modo Invisível, você não aparecerá para outros membros do Bumble a menos que curta o perfil deles.",
      "Como funciona o Modo Invisível?",
      "Sem o Modo Invisível, seu perfil é visível a outros membros no Bumble quando você se encaixa em suas preferências, como idade e localização. Quando você ativa o Modo Invisível, as pessoas só verão você depois que você curtir o perfil delas.",
      "Quem pode me ver no Modo Invisível do Bumble?",
      "Usar o modo de navegação anônima não é o mesmo que <a href={{blocking_on_bumble_url}}>bloquear alguém no Bumble</a>. Com esse recurso, se você curtir alguém (ou se já tiver enviado mensagens para essa pessoa), essa pessoa poderá ver seu perfil.",
      "O Modo Invisível é como bloquear alguém?",
      "Tenha mais controle sobre quem vê seu perfil com o Modo Invisível do Bumble.",
      "Modo Invisível no Bumble",
      "Se quiser dar um passo para trás dos relacionamentos ou navegar no Bumble sem que outros relacionamentos anteriores vejam seu perfil, você pode alternar para o Modo Invisível. Ativar esse recurso significa que ocultaremos você de todos, a menos que você curta alguém primeiro. Ele permite que você controle o que você compartilha e quem vê você.",
      "Esconda seu perfil usando o Modo Invisível",
      "Você decide quem pode ver suas fotos e informações pessoais.",
      "Quer incluir algo pessoal em seu perfil? Você pode compartilhar discretamente o que desejar com suas conexões atuais.",
      "Navegue livremente sem se preocupar em encontrar um antigo relacionamento, alguém do trabalho ou qualquer outra pessoa que não esteja no seu radar.",
      "Assuma o controle total",
      "Escolha o que você quer compartilhar",
      "Evite encontros desconfortáveis",
      "Por que você vai amar o Modo Invisível",
      "O Modo Invisível está disponível para membros do {{service_level_lexeme_name}} e do {{service_level_lexeme_name_two}}. Após se inscrever, você pode ativá-lo acessando a guia do seu Perfil, tocando em Configurações no canto superior direito e alterando para Modo Invisível.",
      "Ative o {{service_level_lexeme_name}}",
      "Como usar o Modo Invisível no Bumble",
      "BAIXE O Bumble E EXPERIMENTE UMA CONEXÃO IMEDIATA HOJE",
      "No aplicativo do Bumble, acesse o ícone do Perfil na parte inferior da tela. A partir daí, você encontrará o ícone do QR code no canto superior direito da tela. Toque nele para acessá-lo.",
      "Onde posso encontrar o meu QR code pessoal?",
      "Não! Você precisa se conectar ao WiFi e usar o QR code atual e ativo para que a Conexão imediata funcione.",
      "Posso fazer uma captura de tela do meu QR code para compartilhar?",
      "Não! Se uma pessoa escanear o código, a conexão será automática.",
      "Ambas as pessoas precisam escanear o QR code?",
      "Com a Conexão imediata, você pode ver automaticamente o que você e sua nova conexão têm em comum. Isso te dá desde o início uma visão geral no nível de compatibilidade entre vocês.",
      "ENCONTRE O QUE VOCÊ TEM EM COMUM, DESDE O INÍCIO",
      "Iniciou uma conversa pessoalmente em um evento do Bumble? Continue no aplicativo.",
      "TRANSFORME SUA REUNIÃO EM UMA CONEXÃO IMEDIATA {{qr_code}}",
      "A Conexão imediata, facilita a transição do primeiro encontro até a continuação da conversa, sem a necessidade de troca de números ou perfis sociais. Você só precisa digitalizar seu QR code para rolar uma conexão no Bumble.",
      "Experimente uma Conexão imediata",
      "CONHECEU ALGUÉM EM UM EVENTO DO Bumble PESSOALMENTE?",
      "Em vez de compartilhar sua mídia social ou número de telefone, a Conexão imediata permite que você verifique primeiro sua química no aplicativo. Assim, vocês podem se conhecer no seu próprio ritmo, graças aos recursos de segurança do Bumble.",
      "UMA MANEIRA MAIS SEGURA DE SE CONECTAR",
      "Depois de ter uma conversa pessoalmente no Bumble, veja se rola uma química \n continuando a conversa no aplicativo e descubra quais interesses vocês têm em comum, seja a paixão por café coado ou drinks exóticos.",
      "NÃO DEIXE A CONVERSA PARAR",
      "Ative os Opening Moves no Bumble",
      "Abrir o Opening Moves é outra maneira de conversar com pessoas que você curte. Depois de escolher uma mensagem para enviar, você pode relaxar, aguardar as respostas e responder àquelas que realmente se destacarem.",
      "Como usar os Opening Moves do Bumble",
      "Para conexões não-binárias e de mesmo gênero, qualquer pessoa pode definir e responder a um Opening Move.",
      "Posso usar o Opening Moves do Bumble para encontrar pessoas do mesmo gênero?",
      "Quando alguém responder ao seu Opening Move, você verá o perfil dele aparecer em suas conversas. As mulheres têm 24 horas para responder antes que sua conexão expire. A partir daí, você pode responder à resposta ou iniciar uma nova conversa.",
      "Como posso ver se alguém respondeu meu Opening Move?",
      "Os Opening Moves são a nossa maneira de aliviar a pressão na hora de dar o primeiro passo, com uma maneira mais fácil e rápida de chegar a uma boa conversa.",
      "Opening Moves no Bumble",
      "Estamos felizes por você ter perguntado. Os Opening Moves são um novo método que facilita o início de uma conversa. Você pode escolher um Opening Move recomendado, como \"Qual é a sua música favorita se você estiver no comando de uma festa?\", ou escrever o seu próprio. Se você definir um, suas conexões poderão responder quando rolar uma conexão.",
      "O que é um Opening Move?",
      "Alivie um pouco a pressão",
      "O que te faz ser você?",
      "Tenha conversas mais legais",
      "Opening Moves podem ajudar...",
      "Você pode definir um Opening Move para enviar quando você combinar com outros membros. Basta acessar os Opening Moves em seu perfil, escolher uma das perguntas pré-selecionadas ou dar asas à sua criatividade e escrever a sua própria. Você pode alterar seus Opening Moves sempre que quiser.",
      "Experimente os Opening Moves",
      "Como usar os Opening Moves do Bumble",
      "<a href={{see_more_url}}>Saiba mais</a>",
      "Quer fazer uma pausa? Experimente o Modo Não Perturbe",
      "Sim, você pode mudar suas configurações ou sua fila e desativar o Não Perturbe sempre que quiser.",
      "Posso desativar o Modo Não Perturbe antes do tempo?",
      "Vá para configurações, toque em Não Perturbe e escolha quanto tempo deseja ficar ausente. Volte sempre que se sentir quiser.",
      "Como uso o Modo Não Perturbe no meu perfil?",
      "Não, enquanto estiver no Modo Não Perturbe, todas as atividades de deslizar e conversar serão pausadas. Ao visitar novas pessoas, você verá uma mensagem informando quanto tempo o Modo Não Perturbe durará, com a opção de desativá-lo.",
      "Posso ver perfis e ainda conversar no Modo Não Perturbe?",
      "As pessoas para quem você já enviou mensagens verão seu status ‘Ausente’ (se você decidir definir um). Caso contrário, eles verão suas mensagens anteriores na caixa de entrada normalmente.",
      "O que minhas conexões verão quando eu estiver no Modo Não Perturbe?",
      "Você tem controle total sobre a atividade do Bumble e pode sair da plataforma sempre que quiser.",
      "Você pode manter as conexões existentes atualizadas usando sua atualização de status, para que não pareça que você <a href={{ghosted_article_url}}>deu um perdido em alguém</a>. Além disso, é tudo automatizado – então você não precisa enviar nenhuma mensagem.",
      "Faça uma pausa nos encontros ativos sem perder suas conexões ou conversas.",
      "Conheça alguém (ou dê um tempo) de acordo com suas preferências",
      "Mantenha suas conexões atualizadas",
      "Pause sua atividade, mas não suas conexões",
      "Por que amamos o Modo Não Perturbe",
      "Abra o aplicativo do Bumble, acesse o guia do seu perfil e toque na engrenagem de configurações no canto superior direito. Nas configurações, toque em Não Perturbe e escolha por quanto tempo você ficará ausente. Você pode ocultar seu perfil por 24 horas, 72 horas, uma semana ou indefinidamente.{{__EOL__}}{{__EOL__}}Lembre-se: conexões sem conversas ativas expirarão enquanto você estiver no Modo Não Perturbe. Se você decidir definir um status, as pessoas com quem você está conversando no momento poderão ver que você está ausente.",
      "Como o Modo Não Perturbe funciona no Bumble",
      "Quando a vida fica agitada, o Modo Não Perturbe está aqui para ajudar. Pause sua conta do Bumble e tenha encontros de acordo com seus próprios termos.",
      "Modo Não Perturbe no Bumble",
      "O Modo Não Perturbe é uma maneira fácil de pausar sua conta quando você quiser dar uma pausa nos encontros. Você ainda pode conversar com suas conexões existentes, apenas oculta seu perfil de outros encontros até que você esteja afim para começar a deslizar novamente. Você não perderá as informações do seu perfil nem excluirá conexões existentes ativando o Modo Não Perturbe.",
      "Ative o Modo Não Pertube no Bumble",
      "Enquanto estiver no Modo Não Perturbe, você pode optar por informar às conexões existentes que está fazendo uma pausa com uma atualização de status. Escolha entre 'Estou viajando', 'Estou focado no trabalho', 'Estou em uma desintoxicação digital' ou 'Estou me priorizando'. Seja qual for a sua vibe, estamos nessa com você.",
      "Adicione um status \"Ausente\"",
      "A ativação do Modo Não Perturbe pausa sua conta do \n Bumble (ou {{page_partner_name_bff}}). Suas conexões não saberão que você está no Modo Não Perturbe, a menos que você diga a elas. Se você decidir sair do Modo Não Perturbe antes do período escolhido inicialmente, basta retornar às configurações e tocar em “desativar Modo Não Pertube\". Simples assim.",
      "Pausar suas conversas",
      "Ative o SuperSwipe no Bumble",
      "Quantos você quiser! Os membros do {{service_level_lexeme_name}} recebem {{num}} [[superswipe:num]] toda semana, mas você pode recarregar seu saldo a qualquer momento. Membros gratuitos podem comprar pacotes de SuperSwipes.",
      "Quantos SuperSwipes eu posso enviar no Bumble?",
      "Depois que alguém usar um SuperSwipe em seu perfil, você será receberá uma notificação imediatamente. Você também verá um rótulo acima do nome dessa pessoa em seu perfil, informando que ela gosta de você.",
      "Como faço para saber se alguém me deu um SuperSwipe?",
      "Está curtindo alguém de verdade? Existe uma maneira legal de quebrar o gelo. Basta enviar um SuperSwipe para que essa pessoa saiba que você gosta dela e tenha ótimas  conversas mais rápido.",
      "SuperSwipe no Bumble",
      "Ter destaque nem sempre é fácil, mas o SuperSwipe pode ajudar você a causar uma ótima primeira impressão.{{__EOL__}}{{__EOL__}}Quando você usa um SuperSwipe, uma notificação será enviada para a pessoa de quem você gostou. Você então pulará para o início da fila com um rótulo em seu perfil. Se gostarem de você também, é hora de começar a conversar.",
      "O que é um SuperSwipe?",
      "Ousar e se destacar",
      "Começar mais conversas",
      "Fazer mais conexões",
      "SuperSwipe te ajudar...",
      "Se você tem <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> ou Bumble {{service_level_lexeme_name_one}}, você ganha {{num}} [[superswipe:num]] grátis por semana. Além disso, todos os membros podem comprar pacotes de até {{num_two}} [[superswipe:num_two]] na guia do perfil. Se você tiver {{service_level_lexeme_name_three}}, receberá {{num_three}} [[superswipe:num_three]] por semana{{__EOL__}}{{__EOL__}}Usar o SuperSwipe é simples: quando você vir alguém de quem gosta, basta tocar no ícone de estrela amarela na parte inferior do perfil.",
      "Como usar o SuperSwipe",
      "A Apple e o logotipo da Apple são marcas comerciais da Apple Inc. O Google Play é uma marca registrada da Google LLC.",
      "Conheça alguém com o Modo Viagem do Bumble",
      "<a href={{bumble_premium_url}}>membros do {{service_level_lexeme_name}}</a> podem usar o Modo Viagem. Ative agora e a sua fila mostrará novas pessoas no seu destino escolhido.",
      "Como posso usar o Modo Viagem?",
      "Basicamente, temos algumas restrições de segurança que podem mudar com o tempo, mas o Modo Viagem está disponível para a maioria dos destinos.",
      "Posso usar o Modo Viagem em qualquer lugar?",
      "O Modo Viagem mostrará sua localização como o centro da cidade escolhida, para que novas conexões na área possam te encontrar. As pessoas poderão ver uma nota em seu perfil informando que você está no Modo Viagem, para que saibam que você está visitando ou planejando visitar.",
      "Fazendo as malas para as férias ou indo para uma nova cidade a trabalho? Ative o Modo Viagem do Bumble e tenha encontros para onde for.",
      "Modo Viagem no Bumble",
      "O Modo Viagem é a melhor ferramenta para fazer conexões enquanto você estiver ausente. Defina sua localização para qualquer lugar do mundo e seu perfil aparecerá na cidade que você escolher. Isso significa que você pode começar um relacionamento onde quer que suas aventuras te levem.",
      "O que é Modo Viagem?",
      "Ninguém conhece um lugar melhor do que os habitantes locais. O Modo Viagem pode te ajudar a se conectar com pessoas que tornarão sua viagem ainda mais especial.",
      "Defina a sua localização para a cidade até sete dias antes de ir, para que você possa planejar encontros e encontrar conexões antes de chegar ao seu destino.",
      "Pense além de apenas viagens à praia: você pode estar em casa durante as férias, viajando a trabalho ou explorando uma nova cidade. Encontre o amor onde quer que você vá.",
      "Escolha os melhores pontos de encontro locais",
      "Tenha uma aventura",
      "Faça conexões duradoras",
      "Motivos para amar o Modo Viagem",
      "O Modo Viagem está disponível para membros <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} e {{service_level_lexeme_name_two}} </a> no Bumble. Toque na engrenagem Configurações no canto superior direito do seu perfil e role até ver o Modo Viagem. Escolha seu novo local para começar a ver pessoas nessa área. Seu perfil permitirá que potenciais parceiros saibam que você está no Modo Viagem.",
      "Como funciona o Modo Viagem?",
      "Testar agora",
      "Experimente o Bumble no navegador",
      "Com certeza. O aplicativo da web do Bumble funciona em PCs, Macs e tablets em todos os principais navegadores da web.",
      "Posso usar o Bumble em meu PC ou Mac?",
      "Sim, você pode. Você pode enviar mensagens ou mesmo fazer uma chamada de vídeo ou áudio na aplicativo da web do Bumble.",
      "Posso conversar com conexões no aplicativo da web?",
      "Para fazer login, escolha 'Entrar' - ou clique <a href={{sign_in_url}}>aqui</a>. Você pode fazer login usando seu ID Apple, Facebook ou número de telefone.",
      "Como faço para entrar no Bumble pelo navegador?",
      "Precisa de uma pausa na tela do seu telefone? Experimente o aplicativo da web do Bumble, a mesma experiência do Bumble que você adora, na tela grande.",
      "Usar o Bumble no navegador",
      "Fazer login no Bumble na web é simples. Basta visitar nosso site, clicar em “Entrar” e escolher seu método de login habitual com seu ID Apple, detalhes do Facebook ou número de telefone e pronto! Você está dentro e suas conversas e suas conexões estão exatamente onde você as deixou.",
      "Entrar",
      "Entre com o seu navegador",
      "Editar ou fazer alterações no seu perfil, e aplicar Filtros Avançados.",
      "Confirme que é você para aumentar a probabilidade de você fazer uma conexão incrível.",
      "Compartilhe novas fotos incríveis ou exclua as mais antigas.",
      "Complete seu perfil",
      "Deixe as pessoas saberem que você é realmente você",
      "Adicionar ou remover fotos",
      "O que você pode fazer no Bumble na web",
      "Você encontrará suas conversas e lista de conexões à sua esquerda, e novas conexões em potencial à sua direita.{{__EOL__}}{{__EOL__}}Use as teclas de seta para a esquerda e para a direita ou clique nos símbolos no lado direito da tela para verificar perfis e curtir pessoas como faria em seu telefone.",
      "Como posso usar o Bumble na web?",
      "Aqui ser gentil é irresistível, todo mundo é perfeito do jeito que é e as mulheres vêm em primeiro lugar, sempre.",
      "É aqui que se Dá o Primeiro Passo™",
      "Relacionamentos saudáveis ​​contribuem para uma vida produtiva e positiva. O Bumble permite que você faça conexões das maneiras mais gratificantes, seja por meio de encontros, amizades ou oportunidades de carreira.",
      "O diferencial do Bumble",
      "Defendemos integridade, gentileza, igualdade, confiança e respeito em todas as fases de um relacionamento. Celebramos todas as orientações, quer você esteja aqui para encontrar o amor ou apenas para se divertir.",
      "Os valores do Bumble",
      "No Bumble são as mulheres que dão o Primeiro Passo ou escolhem uma Mensagem inicial para que as conexões possam responder. Decidimos sacudir o mundo do encontros e colocar a igualdade em primeiro lugar.",
      "Como o Bumble funciona",
      "Sobre nós",
      "Baixe agora",
      "É melhor no aplicativo.",
      "Recursos que tornam sua vida online muito mais agradável",
      "12 de fevereiro, 2024",
      "Como aumentar a intimidade emocional em um relacionamento",
      "25 de janeiro, 2024",
      "Como usar o Bumble quando você nunca usou um aplicativo de relacionamento antes",
      "5 de fevereiro, 2024",
      "Deception Detector™",
      "Todas as últimas notícias do mundo do encontros",
      "Se o tamanho importa, acesse nosso site. É exatamente como nosso aplicativo, só que maior.",
      "Aplicativo Web",
      "Reserve um momento para você com o <b>Modo Não Perturbe</b>. Quando quiser voltar, você sabe onde nos encontrar.",
      "Faça uma pausa",
      "Use as <b>chamadas de vídeo</b> e as <b>chamadas de áudio</b> para se aproximar das suas conexões sem sair do casa.",
      "Diga em voz alta",
      "Ver mais",
      "Escolha um <b>Opening Moves</b> para convidar sua conexão para iniciar uma conversa, para que você não precise fazer isso.",
      "Menos esforço, mais conexões",
      "Encontre sua felicidade em qualquer lugar do mundo graças ao <b>Modo Viagem</b>.",
      "Para onde vamos?",
      "Curtiu o perfil de alguém? Mostre enviando um <b>SuperSwipe</b>.",
      "Chame a atenção de alguém",
      "Ganhe destaque na multidão com <b>Compliments</b>.",
      "Dê um sorriso",
      "Esconda seu perfil de todo mundo com o <b>Modo Invisível</b>.",
      "Fique invisível",
      "Conheça pessoas do seu jeito",
      "Alguns meses depois, ela também conheceu uma de suas madrinhas lá",
      "Hannah conheceu seu atual marido no Bumble.",
      "Antes de construirem sua casa juntos, Carène e You-Liang se conheceram no Bumble, onde as fotos de viagem de Carène chamaram a atenção de You-Liang.",
      "Carène e You-Liang",
      "Breno e Karen trabalharam juntos no exterior, mas se conheceram no Bumble",
      "Breno e Karen",
      "Se funcionou para alguém, pode funcionar para você",
      "Dê o próximo passo",
      "Veja o que há de novo",
      "Você merece o melhor, por isso criamos ótimas maneiras para você namorar mais e se estressar menos.",
      "Pra que se contentar quando você pode ter mais?",
      "Sobre o Bumble",
      "Somos o único aplicativo que torna os encontros melhores, colocando as experiências das mulheres em primeiro lugar. Porque quando as coisas melhoram para as mulheres, as coisas melhoram para todos.",
      "Make the first move™",
      "Encontre alguém que você curta e dê o primeiro passo. Crie conexões genuínas, discuta interesses comuns e, acima de tudo, divirta-se.",
      "Encontre alguém com o Bumble Date",
      "Encontre amizades para te ajudar a progredir em sua carreira. Conecte-se com outros profissionais para encontrar seu próximo emprego, uma mentoria ou até mesmo uma nova carreira.",
      "Faça mudanças na carreira com o Bumble Bizz",
      "Conheça alguém que se pareça com você. Faça amizades e encontre atividades para fazerem juntos, na sua cidade ou no exterior.",
      "Encontre novas amizades com o Bumble For Friends",
      "Somos muito mais do que encontros",
      "O Bumble é o aplicativo de relacionamento onde as mulheres dão o primeiro passo. É o aplicativo que mudou a maneira como as pessoas se relacionam, criam conexões verdadeiras e fazem networking. Baixe agora.",
      "Bumble no {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "Página não encontrada.",
      "Contato",
      "Entrar",
      "Ops, algo deu errado!",
      "(abre em nova janela)",
      "FAQ",
      "Política de Cookies",
      "Premium",
      "Blog do Bumble",
      "Vagas",
      "Conversas",
      "Cidades",
      "Gerenciar cookies",
      "Copyright © 2006 – presente. Bumble. Todos os direitos reservados.",
      "Relatório de Encontros com Confiança",
      "Apple e o logotipo da Apple são marcas comerciais da Apple Inc.{{__EOL__}}Google Play é uma marca registrada da Google LLC.",
      "Baixe o app",
      "Facebook",
      "Siga-nos",
      "Central de Ajuda",
      "Instagram",
      "Idioma",
      "LinkedIn",
      "O Bumble faz parte da Bumble Inc.",
      "Política de privacidade",
      "Safety Centre",
      "Mapa do site",
      "Declaração da Lei Modern Slavery Act",
      "Termos e condições",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "plural": [
                  "interações"
            ],
            "singular": [
                  "interação"
            ]
      },
      "day": {
            "plural": [
                  "dias"
            ],
            "singular": [
                  "dia"
            ]
      },
      "hour": {
            "plural": [
                  "horas"
            ],
            "singular": [
                  "hora"
            ]
      },
      "minute": {
            "plural": [
                  "minutos"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "meses"
            ],
            "singular": [
                  "mês"
            ]
      },
      "second": {
            "plural": [
                  "segundos"
            ],
            "singular": [
                  "segundo"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipes"
            ],
            "singular": [
                  "SuperSwipe"
            ]
      },
      "year": {
            "plural": [
                  "anos"
            ],
            "singular": [
                  "ano"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
