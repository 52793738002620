/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Atrás",
      "Descargar en la App Store",
      "Descargar en Google Play",
      "Carrusel",
      "Diapositiva {{number}}",
      "Siguiente diapositiva",
      "Diapositiva anterior",
      "Diapositiva",
      "Diapositiva {{number}} de {{total}}",
      "No vender ni compartir mi información personal",
      "No vendan o compartan mi información personal",
      "Envía Compliments en Bumble para hacerle saber que te gusta su perfil y captar su interés antes de conectar. Pruébalo ya",
      "Compliments | Cómo enviar un Compliment | Bumble",
      "Una página personalizada que destaca a las personas más compatibles contigo, actualizada cada día.",
      "Descubre | Encuentra personas compatibles | Bumble",
      "Ten dates a tu ritmo y controla quién ve tu perfil de Bumble con el Modo Incógnito. Descubre cómo usar el Modo Incógnito en Bumble en nuestra guía",
      "Modo Incógnito | Oculta tu perfil | Bumble",
      "Después de conocerse en un evento IRL de Bumble, mantén la química conversando en la app.",
      "Conexión inmediata | Sigue la conversación en la App | Bumble",
      "Prueba una nueva forma de romper el hielo con Opening Moves de Bumble. Llama su atención, marca el ritmo y llega a la parte buena, más rápido",
      "Bumble Opening Moves | Bumble",
      "¿Necesitas un breve descanso o tiempo para ti? Descubre cómo pausar tu cuenta de Bumble con el Modo No Molestar de Bumble. Te permite tener dates a tu ritmo",
      "Modo No Molestar | Pausa tu cuenta | Bumble",
      "¿Acabas de ver a alguien que realmente te gusta en Bumble? Usa un SuperSwipe para mostrarle que realmente te interesa. Toca la estrella en su perfil para comenzar, o consulta más detalles aquí.",
      "Bumble SuperSwipe | ¿Qué es un SuperSwipe? | Bumble",
      "¿De viaje por trabajo o por placer? El Modo Travel de Bumble te ayudará a conocer gente nueva y divertida en cualquier lugar y a tener citas allá donde vayas. Descubre el Modo Travel aquí ya mismo",
      "Modo Travel de Bumble | Dates a donde vayas | Bumble",
      "¿Quieres saber si la conexión que estás sintiendo a través de mensajes sea la misma que en persona? Prueba una videollamada o una llamada de voz en Bumble ahora y comprueba que la vibra sea la que buscas",
      "Videollamada | Hacer una llamada | Bumble",
      "Vive la experiencia de Bumble Web. No es una función, es la función.",
      "Bumble Sitio web | Bumble",
      "Rescindir contratos aquí",
      "Página principal de Bumble",
      "Cerrar menú",
      "Abrir menú",
      "Selección de idioma",
      "Pie de página",
      "Accesibilidad en Bumble",
      "Apple y el logotipo de Apple son marcas comerciales de Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Empleo",
      "Eventos",
      "Google Play es una marca de Google LLC.",
      "Reglas",
      "Inversionistas",
      "Gestionar cookies",
      "Gestionar cookies",
      "Declaración sobre la Ley de Esclavitud Moderna",
      "Notificación de recopilación",
      "Política de privacidad",
      "Guía de citas por estaciones",
      "Condiciones de Uso",
      "La aplicación",
      "Política de privacidad de Washington Consumer Health",
      "Acerca de",
      "Embajadores",
      "Blog",
      "Haz nuevas amistades y encuentra tu comunidad",
      "Haz networking y avanza en tu carrera",
      "Encuentra una relación, algo casual o cualquier cosa intermedia",
      "Descubrir más",
      "Globalízate con el Modo Travel",
      "Funciones",
      "Compliments",
      "Utiliza Compliments para que sepa por qué te gusta.",
      "Descubre",
      "Una página personalizada que destaca a las personas más compatibles contigo, actualizada cada día.",
      "Modo Incógnito",
      "Utiliza el Modo Incógnito para ocultar tu perfil de la gente de tu oficina, de tus papás y de tu ex.",
      "Conexión inmediata",
      "¿Empezaron a hablar en un evento IRL de Bumble? Sigan la conversación en la app.",
      "Opening Moves",
      "Elige Opening Moves que animen a tu conexión a iniciar el chat, así no tendrás que hacerlo tú.",
      "Modo No Molestar",
      "Tómate un descanso con el Modo No molestar. Cuando te quieras volver a las citas, ya sabes dónde estamos.",
      "SuperSwipe",
      "¿Su perfil te encantó? Demuéstraselo con un SuperSwipe.",
      "Travel",
      "Encuentra tu souvenir duty-free y conecta en cualquier parte del mundo con el Modo Travel.",
      "Videollamada",
      "Usa las videollamadas y llamadas de audio para ver si les gustaría conocerse en persona.",
      "App web",
      "¡Obtén la experiencia de Bumble directamente desde tu navegador!",
      "Nuestra Tienda",
      "Principal",
      "Ir al contenido principal",
      "Logotipo de Bumble",
      "¿Quieres conocer a alguien? ¡Haz una videollamada en Bumble!",
      "Simplemente toca el icono de video en tu chat para iniciar una videollamada, todo ello sin compartir ninguna información de contacto personal.",
      "¿Cómo funciona la videollamada en Bumble?",
      "No. Todas las llamadas y chats de Bumble garantizan la seguridad y protección de tus datos personales. No tendrá tu número ni ningún otro dato de contacto hasta que tú se lo compartas.",
      "¿Mi conexión puede ver mi número de teléfono?",
      "¡Claro que sí! Si todavía no te atreves a un cara a cara, las llamadas de voz te permiten conectar a través de audio. Solo tienes que seleccionar el icono de llamada.",
      "¿Puedo probar solo con una llamada de audio?",
      "Nos encantan las notificaciones de *mensaje recibido*. Pero entendemos que es difícil saber si la química será así de buena en persona. Ahí es donde entran las videollamadas y las llamadas de audio.",
      "Videollamada en Bumble",
      "<a href={{video_chat_url}}>Las videollamadas y llamadas de audio</a> te pueden ayudar a que se conozcan mejor y decidir si quieres llevar las cosas más allá de la app. Lo mejor de todo es que cuando realizas una llamada a través de Bumble, no tienes que intercambiar números de teléfono, por lo que tu información personal permanece a salvo hasta que quieras compartirla.",
      "Desubran su química con videollamadas",
      "Acorta la distancia entre el dating online y en persona, y decide si ya llegó el momento de verse en persona.",
      "Conóceles mejor (y más rápido). Una conversación en vivo por video o por teléfono puede revelar mucho sobre alguien.",
      "Tú mandas. Mantendremos tus datos de contacto en privado, para que puedas elegir cuándo compartirlos.",
      "¿Les gustaría conocerse en persona?",
      "Hacer un vibe-check rápido",
      "Conectar sin proporcionar información personal",
      "Motivos para utilizar las llamadas y videollamadas",
      "¿Todo listo para empezar una conversación? Puedes elegir entre iniciar una llamada de audio o una videollamada directamente desde el chat de la app de Bumble. Para realizar una videollamada, toca el icono gris de la cámara de video en la esquina superior derecha. También verás tres puntos junto al icono, pulsa sobre ellos y verás adicionalmente la opción de realizar una llamada de audio.",
      "Hacer una videollamada",
      "¿Quieres probar Compliments?",
      "Cuando envíes un Compliment, la persona que lo recibe lo verá cuando aparezcas en su fila.",
      "¿Qué ocurre cuando alguien recibe un Compliment en Bumble?",
      "Si su Compliment te llamó la atención, puedes conectar. Verás el Compliment en tu chat y se aplicarán las reglas habituales para <a href={{make_the_first_move_url}}>Dar el primer paso</a>.",
      "¿Cómo respondo a un Compliment?",
      "Puedes enviar {{num}} [[Compliments:num]] de forma gratuita. Si se te acaban, podrás comprar más en la app de Bumble en paquetes de hasta 30. Uno de ellos se utilizará para enviar ese Compliment (guarda el resto para cuando veas otro perfil que te guste). No te preocupes, ¡no caducan!",
      "¿Cuántos Compliments puedo enviar?",
      "Una palabra linda (o dos) puede llevarte muy lejos. Si quieres enviar un Compliment pero no sabes qué decir, inspírate con estos:",
      "Ver más ejemplos",
      "“Cualquier persona a quien le guste [nombre de artista], tiene buen gusto.”",
      "\"¡Tu sonrisa es contagiosa!\"",
      "“Tu biografía me hizo reír. Súper cool.”",
      "Envía mejores Compliments en Bumble",
      "¿Quieres alegrarle el día? Compliments es para ti.",
      "Compliments en Bumble",
      "Utiliza Compliments en Bumble para hacer saber a tus posibles conexiones que te gusta su perfil (en una de esas, hasta le sonrojas). Compliments forma parte de tu suscripción a {{service_level_lexeme_name}}. También es una forma de enviar y recibir mensajes incluso antes de conectar.",
      "¿Cómo funciona Compliments?",
      "Cuando recibas un Compliment, aparecerá en el perfil de quien te lo envía en tu Para ti. También recibirás una notificación indicándote que a alguien le gusta parte de tu perfil.",
      "Mira tus Compliments",
      "Puedes enviar un Compliment pulsando el icono del corazón amarillo que aparece en la parte inferior del perfil de alguien. El lugar donde pulses es importante, porque estarás reaccionando a esa foto o parte específica de su perfil. Al hacer clic, se abrirá un cuadro de texto para que escribas tu mensaje (¡que tenga máximo 150 caracteres!).",
      "Envía un Compliment",
      "DESCARGA Bumble PARA PROBAR DESCUBRE",
      "Todos los días a partir de las 9 de la mañana, verás gente nueva para ti en Descubre. Puedes conectar con esas personas por las cosas que tienen en común.",
      "¿Cuándo se actualiza Descubre?",
      "Tu página Descubre clasifica a tus potenciales conexiones en 3 categorías principales: intereses similares, buscan lo mismo y comunidades en común. También se muestran recomendaciones de perfiles basadas en conexiones previas.",
      "¿Cómo se seleccionan los perfiles en Descubre?",
      "Una página personalizada que destaca a las personas más compatibles contigo, actualizada cada día.",
      "ENCUENTRA PERSONAS CON TU MISMA VIBRA CON {{qr_code}} DESCUBRE",
      "Descubre te muestra una selección de personas que creemos que te gustarán, basándonos sus intereses, intenciones y comunidades afines.",
      "Probar Descubre",
      "¿BUSCAS UNA NUEVA FORMA DE SABER LO QUE TIENEN EN COMÚN?",
      "La página Descubre se actualiza cada día para mostrarte aún más personas con las que puedes ser compatible, para que dediques menos tiempo a buscar perfiles y más a profundizar con personas con las que sabes que vas a vibrar.",
      "RECOMENDACIONES ACTUALIZADAS CADA DÍA",
      "Las recomendaciones de Descubre se basan en tu perfil y en las personas con las que has conectado previamente, de modo que verás a personas que buscan lo mismo y que tienen gustos en común, ya sean rollitos veganos o una relación estable.",
      "PERSONALIZADA{{__EOL__}}A TU MEDIDA",
      "Descargar Bumble",
      "Preguntas frecuentes (FAQs)",
      "¿Te gustaría explorar Bumble sin que te vean?",
      "Cuando actives el Modo Incógnito, no aparecerás ante otros miembros de Bumble a menos que le des like a su perfil.",
      "¿Cómo funciona el Modo Incógnito?",
      "Sin el Modo Incógnito, tu perfil es visible para otras personas en Bumble cuando encajas con sus preferencias, como la edad y la ubicación. Si activas el Modo Incógnito, solo te verán las personas a las que hayas dado like.",
      "¿Quién puede verme en el Modo Incógnito de Bumble?",
      "Usar el Modo Incógnito no es lo mismo que <a href={{blocking_on_bumble_url}}>bloquear a alguien en Bumble</a>. Con esta función, si te gusta alguien (o si les has enviado un mensaje en el pasado), podrá ver tu perfil.",
      "¿El Modo Incógnito es como bloquear a alguien?",
      "Gestiona quién ve tu perfil con el Modo Incógnito de Bumble.",
      "Modo Incógnito en Bumble",
      "Si quieres dar un paso atrás en tus citas o navegar por Bumble sin que otras personas disponibles vean tu perfil, puedes activar la función Incógnito. Al hacerlo, ocultarás tu perfil a todo el mundo, a menos que les dieras like antes. Esto te permite controlar lo que compartes y quién lo ve.",
      "Oculta tu perfil con el Modo Incógnito",
      "Tú decides quién puede ver tus fotos y tu información personal.",
      "¿Quieres incluir algo personal en tu perfil? Puedes compartir discretamente lo que quieras con tus conexiones actuales.",
      "Navega libremente sin preocuparte de encontrarte con tu ex, tu colega de trabajo o cualquier otra persona que no esté en tu radar de dating.",
      "Tienes el control total",
      "Eliges lo que compartes",
      "Evitas encuentros incómodos",
      "¿Por qué amar el Modo Incógnito?",
      "El Modo Incógnito está disponible para las personas usuarias de {{service_level_lexeme_name}} y {{service_level_lexeme_name_two}}. Una vez que te hayas registrado, puedes activarlo yendo a la pestaña de tu perfil, pulsando Ajustes en la esquina superior derecha y cambiando a Incógnito.",
      "Pasar a {{service_level_lexeme_name}}",
      "¿Cómo usar el modo Incógnito en Bumble?",
      "DESCARGA Bumble PARA PROBAR CONEXIÓN INMEDIATA",
      "En la app de Bumble, dirígete al icono del Perfil en la parte inferior de tu pantalla. Desde allí, encontrarás el icono del código QR en la esquina superior derecha de la pantalla. Tócalo para acceder a él.",
      "¿Dónde puedo encontrar mi código QR personal?",
      "¡No! Tendrás que conectarte a una red wifi y utilizar el código QR vigente para que Conexión inmediata funcione.",
      "¿Puedo hacer una captura de pantalla de mi código QR para compartir?",
      "¡No! Si una persona escanea el código, conectarán automáticamente.",
      "¿Ambas personas tienen que escanear el código QR?",
      "Con Conexión inmediata, verás automáticamente lo que tú y tu nueva conexión tienen en común, dándote una idea de la compatibilidad que tienen desde el principio.",
      "ENCUENTRA COSAS EN COMÚN, MÁS RÁPIDO",
      "¿Empezaron a hablar en un evento IRL de Bumble? Sigan la conversación en la app.",
      "CONVIERTE TU ENCUENTRO CASUAL EN UNA {{qr_code}} CONEXIÓN INMEDIATA",
      "Conexión inmediata facilita que la conversación de tus encuentros casuales siga viva sin necesidad de intercambiar números ni redes sociales. Solo tienes que pedirles que escaneen tu código QR para conectar en Bumble.",
      "Probar Conexión inmediata",
      "¿CONOCISTE A ALGUIEN EN UN EVENTO IRL DE Bumble?",
      "En lugar de intercambiar redes sociales o números de teléfono, Conexión inmediata te permite percibir la vibra en la aplicación primero. De esta manera, pueden conocerse mejor a su propio ritmo, gracias a las funciones de seguridad de Bumble.",
      "UNA FORMA MÁS SEGURA DE CONECTAR",
      "Después de conocerse en un evento IRL de Bumble, continúen la conversación en la app, así podrán trabajar en su química y conectar a través de intereses compartidos, ya sea el amor por el café artesanal o por las plantas suculentas.",
      "QUE LA CONVERSACIÓN NO PARE",
      "Prueba Opening Moves en Bumble",
      "Los Opening Moves son otra forma de chatear con la gente que te gusta. Una vez elegida la pregunta o el mensaje que quieres enviar, puedes relajarte, esperar las respuestas y contestar a las que más te gusten.",
      "¿Cómo usar los Opening Moves de Bumble?",
      "Para conexiones no binarias y de mismo género, cualquiera de las personas puede establecer y responder a un Opening Move.",
      "¿Puedo usar Opening Moves de Bumble para salir con personas del mismo género?",
      "Cuando alguien responde a tu Opening Move, verás que su perfil aparece en tus chats. Las mujeres tienen 24 horas para responder antes de que la conexión caduque. A partir de ahí, puedes contestar a su respuesta o iniciar una nueva conversación.",
      "¿Cómo puedo ver si alguien ha respondido a mis Opening Moves?",
      "Opening Moves es nuestra forma de quitar presión a la hora de dar el primer paso. Es una manera más fácil y rápida de romper el hielo para ir directo a una conversación increíble.",
      "Opening Moves en Bumble",
      "¡Buena pregunta! Opening Moves es una forma nueva y más sencilla de iniciar una conversación mediante preguntas que rompen el hielo. Puedes elegir un Opening Move sugerido, por ejemplo, \"¿Qué canción agregas si te pasan la playlist compartida para una fiesta?\" O también puedes escribir uno propio. Si tienes un Opening Move en tu perfil, tus conexiones pueden responder a tus preguntas cuando te vean en su pantalla de conexión.",
      "¿Qué es un Opening Move?",
      "Quitarte algo de presión",
      "Compartir lo que te hace ser tú",
      "Tener mejores conversaciones",
      "Los Opening Moves pueden ayudarte a...",
      "Puedes configurar un Opening Move para que se envíe cuando conectes con otras personas. Solo tienes que ir a Opening Moves en tu perfil, elegir una de las preguntas preescritas o ser original y escribir la tuya. Puedes cambiar tus Opening Moves siempre que quieras.",
      "Usar Opening Moves",
      "¿Cómo usar los Opening Moves de Bumble?",
      "<a href={{see_more_url}}>Ver más</a>",
      "¿Quieres tomar un descanso? Prueba el Modo No molestar",
      "Sí, puedes ir a tus ajustes o a tu fila y desactivar el Modo No Molestar cuando quieras.",
      "¿Puedo desactivar el Modo No Molestar antes?",
      "Ve a ajustes, pulsa en Modo No Molestar y elige el tiempo que quieras estar ausente. Vuelve cuando sientas que quieres hacerlo.",
      "¿Cómo uso el Modo No Molestar en mi perfil?",
      "No, mientras utilices el Modo No Molestar, toda la actividad de deslizar y chatear estará en pausa. Cuando vayas a ver a gente nueva, te aparecerá un mensaje indicándote cuánto tiempo del Modo No Molestar te queda, con la opción de desactivarlo.",
      "¿Puedo seguir deslizando y chateando en el Modo No Molestar?",
      "Las personas a las que ya les has enviado un mensaje verán tu estado como \"Ausente\" (si decides configurarlo). De lo contrario, verán tus mensajes anteriores en su bandeja de entrada como de costumbre.",
      "¿Qué verán mis conexiones actuales cuando tenga activado el Modo No Molestar?",
      "Tienes control total sobre tu actividad en Bumble, y puedes alejarte de la plataforma cuando quieras.",
      "Puedes mantener al día las conexiones existentes mediante tu actualización de estado, para que no parezca que estás haciendo <a href={{ghosted_article_url}}>ghosting</a>. Además, todo está automatizado, por lo que no tienes que enviar ningún mensaje.",
      "Tómate un descanso de la búsqueda activa de citas sin perder tus conexiones o tus conversaciones.",
      "Puedes tener citas (o desconectar) a tu manera",
      "Mantén actualizadas a tus conexiones",
      "Pausa tu actividad, pero no tus conexiones",
      "Razones para amar el Modo No molestar",
      "Abre la app de Bumble, ve a la pestaña de tu perfil y pulsa el icono del engranaje en la esquina superior derecha. En tus Ajustes, toca en Modo No Molestar y elige cuánto tiempo te ausentarás. Puedes ocultar tu perfil durante 24 horas, 72 horas, una semana o indefinidamente.{{__EOL__}}{{__EOL__}}Recuerda que tus conexiones sin chats activos caducarán mientras estés en el Modo No Molestar. Si decides establecer un estado, las personas con las que estés chateando en ese momento podrán ver que estás ausente.",
      "¿Cómo funciona el Modo No molestar en Bumble?",
      "Cuando la vida se pone difícil, el Modo No Molestar te ayuda. Pausa tu cuenta de Bumble y ten citas a tu ritmo.",
      "Modo No molestar en Bumble",
      "El Modo No Molestar es una forma fácil de pausar tu cuenta cuando deseas darte un descanso del dating. Aún puedes chatear con tus conexiones existentes, solo que oculta tu perfil a personas hasta que tengas ganas de volver a deslizar. Al activar el Modo No Molestar, no perderás tu información de perfil ni eliminarás las conexiones existentes.",
      "Pon tu perfil de Bumble en Modo No molestar",
      "Mientras estás en Modo No Molestar, puedes elegir notificar a las conexiones existentes que estás descansando con una actualización de estado. Elige entre \"Estoy de viaje\", \"Estoy enfocándome en el trabajo\", \"Estoy en un detox digital\" o \"Me estoy tomando un tiempo\". Sea cual sea tu energía, te apoyamos.",
      "Configura un estado \"Ausente\"",
      "Activar el Modo No Molestar pausa tu cuenta de Bumble (o {{page_partner_name_bff}}). Tus conexiones no sabrán que estás en Modo No Molestar a menos que se los comuniques. Si decides salir del Modo No Molestar antes del tiempo que elegiste inicialmente, vuelve a Ajustes y pulsa \"Desactivar Modo No Molestar\". Así de fácil.",
      "Pausa tus chats",
      "Prueba SuperSwipe en Bumble",
      "¡Tantos como quieras! Los usuarios de {{service_level_lexeme_name}} obtienen {{num}} [[superswipe:num]] por semana, pero puedes recargar tu saldo en cualquier momento. Los miembros gratuitos pueden comprar paquetes de SuperSwipes.",
      "¿Cuántos SuperSwipes puedo enviar en Bumble?",
      "Después de que alguien use un SuperSwipe en tu perfil, recibirás una notificación de inmediato. También verás una etiqueta en el nombre de esa persona en su perfil, indicándote que le gustas.",
      "¿Cómo sé si alguien me envía un SuperSwipe?",
      "¿Sientes algo especial por alguien? Hay una forma súper cool de romper el hielo. Solo tienes que enviar un SuperSwipe para hacerle saber que te gusta y empezar conversaciones inolvidables, más rápido.",
      "SuperSwipe en Bumble",
      "Destacar entre la multitud no siempre es fácil, pero SuperSwipe puede ayudarte a causar una gran primera impresión.{{__EOL__}}{{__EOL__}}Cuando utilices SuperSwipe, se enviará una notificación a la persona que te gustó. Así, pasarás al primer puesto de su lista con una etiqueta en tu perfil. Si tú también le gustas, ya está, es hora de empezar a chatear.",
      "¿Qué es un SuperSwipe?",
      "Ser valiente y destacar",
      "Iniciar más chats",
      "Hacer más conexiones",
      "SuperSwipe puede ayudarte a…",
      "Si tienes <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> o Bumble {{service_level_lexeme_name_one}}, obtienes {{num}} [[superswipe:num]] gratis a la semana. Además, todos los miembros pueden comprar paquetes de hasta {{num_two}} [[superswipe:num_two]] desde la pestaña de perfil. Si tienes {{service_level_lexeme_name_three}}, obtendrás {{num_three}} [[superswipe:num_three]] semanales.{{__EOL__}}{{__EOL__}}Utilizar SuperSwipe es muy sencillo: cuando veas a alguien que te gusta, toca el icono de la estrella amarilla en la parte inferior de su perfil.",
      "¿Cómo usar SuperSwipe?",
      "Apple y el logotipo de Apple son marcas comerciales de Apple Inc. Google Play es una marca de Google LLC.",
      "Planea dates donde vayas con el Modo Travel de Bumble",
      "<a href={{bumble_premium_url}}>Los miembros {{service_level_lexeme_name}}</a> pueden usar el Modo Travel. Actívalo y en tu fila verás nuevos perfiles de la ciudad que elijas.",
      "¿Cómo uso el Modo Travel?",
      "Algo así. Tenemos algunas restricciones de seguridad que pueden cambiar con el tiempo, pero el Modo Travel está disponible para la mayoría de los destinos.",
      "¿Puedo usar el Modo Travel en cualquier lugar?",
      "El Modo Travel mostrará tu ubicación como el centro de la ciudad elegida, para que las nuevas conexiones de la zona puedan encontrarte. Verán una nota en tu perfil indicando que estás en Modo Travel, para que sepan que estás de visita o planeas visitarla.",
      "¿Haciendo las maletas para irte de vacaciones o a una nueva ciudad por trabajo? Activa el Modo Travel de Bumble para llevarte las citas contigo.",
      "Modo Travel en Bumble",
      "El Modo Travel es tu herramienta increíble para hacer conexiones mientras estás fuera. Establece tu ubicación en cualquier parte del mundo, y tu perfil aparecerá como si ya estuvieras en la ciudad que elijas. Esto significa que puedes unirte al dating en donde sea que quieras vivir la aventura.",
      "¿Qué es el modo Travel?",
      "Nadie conoce un lugar mejor que la gente local. El Modo Travel puede ayudarte a conectar con las personas que harán que tu viaje sea aún más especial.",
      "Establece tu ubicación en la ciudad hasta siete días antes de viajar, para que puedas planear dates y conectar desde antes.",
      "Piensa más allá de los viajes a la playa: puede que estés en casa de vacaciones, viajando por trabajo o explorando una nueva ciudad. Encuentra el romance a donde sea que vayas.",
      "Encuentra los mejores spots de citas",
      "Vive una aventura",
      "Crea conexiones duraderas",
      "¿Por qué amar el Modo Travel?",
      "El Modo Travel está disponible para miembros <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} y {{service_level_lexeme_name_two}}</a> de Bumble. Pulsa en el engranaje de Ajustes en la parte superior derecha de tu perfil y desplázate hasta que veas Travel. Elige tu nueva ubicación para empezar a conocer gente de esa zona. Tu perfil informará a tus posibles citas de que estás usando el Modo Travel.",
      "¿Cómo funciona el Modo Travel?",
      "Probar ahora",
      "Prueba Bumble en la web",
      "Claro que sí. La aplicación web de Bumble funciona en PC, Mac y todo tipo de tabletas en todos los navegadores web principales.",
      "¿Puedo usar Bumble en mi PC o Mac?",
      "Sí, sí puedes. Puedes enviar mensajes o incluso hacer una videollamada o una llamada de audio en la aplicación web de Bumble.",
      "¿Puedo chatear con mis conexiones en la aplicación web?",
      "Para iniciar sesión, selecciona \"Iniciar sesión\" o haz clic <a href={{sign_in_url}}>aquí</a>. Puedes iniciar sesión con tu Apple ID, Facebook o número de teléfono.",
      "¿Cómo inicio sesión en Bumble en la web?",
      "¿Necesitas pasar menos tiempo en el teléfono? Prueba la aplicación web de Bumble: la misma experiencia Bumble que adoras pero en una pantalla más grande.",
      "Usa Bumble en la web",
      "Iniciar sesión en Bumble en la web es muy sencillo. Solo tienes que visitar nuestro sitio web, hacer clic en \"Iniciar sesión\" y elegir tu método de inicio de sesión habitual con tu Apple ID, tus datos de Facebook o tu número de teléfono. ¡Listo! Ya estás dentro, y tus chats y conexiones están justo donde los dejaste.",
      "Entrar",
      "Inicia sesión a través de tu navegador",
      "Edita o haz cambios en tu perfil y aplica Filtros avanzados.",
      "Confirma que eres tú para aumentar la probabilidad de que conectes.",
      "Comparte fotos cool nuevas o elimina las más viejas.",
      "Actualizar tu perfil",
      "Mostrarle a la gente que eres real",
      "Agregar o quitar fotos",
      "Lo que puedes hacer en Bumble versión web",
      "Encontrarás tus chats y lista de conexiones a tu izquierda, y posibles nuevas conexiones a tu derecha.{{__EOL__}}{{__EOL__}}Utiliza las teclas de flecha izquierda y derecha, o haz clic en los símbolos de la parte derecha de la pantalla para ver los perfiles y dar likes, como harías en tu teléfono.",
      "¿Cómo utilizo Bumble en la web?",
      "Aquí, ser buenos es sexy, ser tal como somos es perfecto, y las mujeres son la prioridad. Siempre.",
      "El lugar donde se Da el primer paso™",
      "Las relaciones sanas son fundamentales para llevar una vida positiva y productiva. Bumble está diseñada para ayudarte a que te empoderes mientras creas nuevas conexiones, tanto si quieres salir con alguien, hacer nuevas amistades o ampliar tu red de contactos profesionales.",
      "Qué hace a Bumble diferente",
      "Defendemos la integridad, la amabilidad, la igualdad, la confianza y el respeto durante todas las etapas de cualquier relación. No solo acogemos, sino que celebramos todas las orientaciones, ya estés aquí para encontrar el amor o simplemente para divertirte.",
      "Los valores de Bumble",
      "En Bumble, las mujeres marcan el ritmo dando el primer paso o proponiendo un Opening Move para que sus conexiones respondan. Así se cambian las viejas dinámicas de poder y se fomenta la igualdad desde el principio.",
      "Cómo funciona Bumble",
      "Conócenos",
      "Descargar ahora",
      "Es mejor en la app.",
      "Funciones diseñadas para que las citas online sean, de verdad, disfrutables.",
      "12 de febrero, 2024",
      "Cómo incrementar la intimidad emocional en tu relación de pareja",
      "25 de enero, 2024",
      "Cómo utilizar Bumble cuando nunca antes has usado una app de citas",
      "5 de febrero, 2024",
      "Deception Detector™",
      "Todas las novedades sobre el mundo de las citas",
      "Si el tamaño importa, visita nuestro sitio web. Es igual que nuestra app, pero más grande.",
      "App web",
      "Tómate un descanso con el <b>Modo no molestar</b>. Cuando te apetezca volver a las citas, ya sabes dónde estamos.",
      "Tómate un tiempo",
      "Utiliza <b>videollamadas</b> y <b>chats de audio</b> para una experiencia lo más real posible sin salir de casa.",
      "Díselo fuerte",
      "Ver más",
      "Elige <b>Opening Moves</b> para invitar a tu conexión a iniciar el chat, así no tendrás que hacerlo tú.",
      "Menos esfuerzo, más citas",
      "Encuentra tu souvenir duty-free y conecta en cualquier parte del mundo con el <b>Modo Travel</b>.",
      "Llévanos contigo",
      "¿Su perfil te encantó? Demuéstraselo con un <b>SuperSwipe</b>.",
      "Llama su atención",
      "Usa <b>Compliments</b> para que sepa por qué te gusta.",
      "Haz que se sonrojen",
      "Utiliza el <b>Modo Incógnito</b> para ocultar tu perfil de tu jefa, familiares o de tu ex.",
      "Perfil secreto",
      "Ten las citas que tú quieres",
      "Unos meses después, también conoció allí a una de sus damas de honor",
      "Hannah conoció a su ahora esposo en Bumble.",
      "Antes de construir su casita juntos, Carène y You-Liang se conocieron en Bumble, donde las fotos de viajes de Carène llamaron la atención de You-Liang.",
      "Carène y You-Liang",
      "Brian y Kaci compartieron destino en el extranjero, pero se conocieron en Bumble",
      "Brian y Kaci",
      "Si funcionó en su caso, podría funcionarte a ti.",
      "Da el siguiente paso",
      "Ver qué hay de nuevo",
      "Mereces lo mejor, así que hemos diseñado formas geniales para que tengas más citas sin tanto estrés.",
      "Vuelve al sitio al que perteneces",
      "Sobre Bumble",
      "Somos la única app que mejora las citas dando prioridad a las experiencias de las mujeres. Porque cuando las cosas mejoran para las mujeres, mejoran para todo el mundo.",
      "Make the first move™",
      "Encuentra a alguien con quien valga la pena salir y Da el primer paso. Haz conexiones auténticas, vibra con lo que os apasiona y, lo más importante, diviértete.",
      "Conoce a alguien con Bumble Date",
      "Amplía tu círculo con beneficios laborales. Conecta con profesionales para encontrar tu próximo trabajo, una mentoría o incluso tu nueva profesión.",
      "Avanza en tu carrera con Bumble Bizz",
      "Conoce a alguien como tú. O diferente. Haz nuevas amistades con las que descubrir cosas nuevas que hacer, en tu ciudad o fuera.",
      "Haz amistades nuevas en Bumble For Friends",
      "Somos mucho más que citas",
      "Bumble es la app de citas donde las mujeres dan el primer paso. Es la app que ha revolucionado la forma de tener citas, crea relaciones significativas y networking. Descárgala ahora.",
      "Bumble en {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "Lo sentimos, pero no pudimos encontrar la página que estabas buscando.",
      "Contacto",
      "Entrar",
      "Parece que algo falló...",
      "(se abre en otra ventana)",
      "Preguntas",
      "Política de cookies",
      "Premium",
      "Bumble - The Blog",
      "Empleo",
      "Chat",
      "Ciudades",
      "Gestionar cookies",
      "Copyright © 2006 – presente. Bumble. Todos los derechos reservados.",
      "Reporte de Confianza en las citas",
      "Apple y el logotipo de Apple son marcas comerciales de Apple Inc. {{__EOL__}}Google Play es una marca de Google LLC. ",
      "Descargar",
      "Facebook",
      "Síguenos",
      "Centro de soporte",
      "Instagram",
      "Idioma",
      "LinkedIn",
      "Bumble es parte de Bumble Inc.",
      "Política de privacidad",
      "Centro de seguridad",
      "Mapa del sitio",
      "Declaración del Modern Slavery Act",
      "Términos de uso",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "plural": [
                  "cumplidos"
            ],
            "singular": [
                  "cumplido"
            ]
      },
      "day": {
            "plural": [
                  "días"
            ],
            "singular": [
                  "día"
            ]
      },
      "hour": {
            "plural": [
                  "horas"
            ],
            "singular": [
                  "hora"
            ]
      },
      "minute": {
            "plural": [
                  "minutos"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "meses"
            ],
            "singular": [
                  "mes"
            ]
      },
      "second": {
            "plural": [
                  "segundos"
            ],
            "singular": [
                  "segundo"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipes"
            ],
            "singular": [
                  "SuperSwipe"
            ]
      },
      "year": {
            "plural": [
                  "años"
            ],
            "singular": [
                  "año"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
