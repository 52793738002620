/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Gå tillbaka",
      "Hämta från App Store",
      "Hämta från Google Play",
      "Karusell",
      "Bild {{number}}",
      "Nästa bild",
      "Föregående bild",
      "Bild",
      "Bild {{number}} av {{total}}",
      "Sälj eller dela inte mina personuppgifter",
      "Sälj eller dela inte mina personliga uppgifter",
      "Skicka komplimanger på Bumble för att visa att du gillar en profil och fånga deras uppmärksamhet innan ni matchar. Prova nu!",
      "Compliments | Så skickar du en komplimang | Bumble",
      "En personlig sida som visar dina mest kompatibla personer, och uppdateras dagligen.",
      "Upptäck | Hitta kompatibla personer | Bumble",
      "Dejta i din takt och ta kontroll över vem som ser din Bumble-profil med Inkognito-läge. Läs mer om hur du använder Inkognito-läget på Bumble i vår guide.",
      "Inkognito-läge | Dölj din profil | Bumble",
      "Har ni träffats på ett Bumble-event IRL? Jobba vidare på kemin genom att fortsätta samtalet i appen.",
      "Matcha direkt | Fortsätt snacka i appen | Bumble",
      "Prova ett nytt sätt att inleda samtalet med Opening Moves på Bumble. Fånga deras intresse, sätt tonen och lär känna varandra snabbare.",
      "Opening Moves på Bumble | Bumble",
      "Behöver du en paus eller tid för dig själv? Upptäck hur du kan pausa ditt Bumble-konto med Bumbles Snooze-läge. Dags att dejta på dina villkor.",
      "Snooze-läge | Pausa ditt konto | Bumble",
      "Sett någon på Bumble som du verkligen gillar? Visa hur du känner med en SuperSwipe. Tryck på stjärnan på deras profil för att komma igång, eller läs mer här.",
      "SuperSwipe på Bumble | Vad är en SuperSwipe? | Bumble",
      "Reser du i jobbet eller för skojs skull? Med Travel-läget på Bumble kan du hitta roliga kontakter på en ny plats och dejta var du än är. Upptäck Travel-läget nu.",
      "Bumbles Travel-läge | Dejta var som helst | Bumble",
      "Vill du veta om det du känner i chatten kommer att leda till kemi IRL? Ring ett video- eller röstsamtal på Bumble nu och känn av stämningen.",
      "Videosamtal | Ringa någon | Bumble",
      "Upplev spänningen på Bumble på webben – den ultimata funktionen.",
      "Bumble på webben | Bumble",
      "Avsluta avtal här",
      "Bumbles startsida",
      "Stäng meny",
      "Öppna meny",
      "Val av språk",
      "Sidfot",
      "Tillgänglighet på Bumble",
      "Apple och logotypen för Apple är varumärken som tillhör Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Lediga jobb",
      "Events",
      "Google Play är ett varumärke som tillhör Google LLC.",
      "Riktlinjer",
      "Investerare",
      "Hantera cookies",
      "Hantera cookies",
      "Modern Slavery Act Statement",
      "Meddelande om insamling",
      "Sekretesspolicy",
      "Dejtidéer för varje årstid",
      "Villkor och regler",
      "Appen",
      "Sekretesspolicy för Consumer Health Data i delstaten Washington",
      "Om",
      "Ambassadörer",
      "The Buzz",
      "Träffa vänner och bli del av en gemenskap",
      "Nätverka och gör framsteg inom karriären",
      "Hitta ett förhållande, något lättsamt eller något mitt emellan.",
      "Läs mer",
      "Swipa över hela världen med Travel-läge",
      "Funktioner",
      "Compliments",
      "Stick ut från mängden med Compliments.",
      "Upptäck",
      "En personlig sida som visar dina mest kompatibla personer, och uppdateras dagligen.",
      "Inkognito-läge",
      "Dölj din profil från ditt ex, släkten eller chefen med Inkognito-läget.",
      "Matcha direkt",
      "Börjat snacka på ett Bumble-event? Fortsätt i appen.",
      "Opening Moves",
      "Låt din matchning starta chatten med Opening Moves.",
      "Snooze-läge",
      "Pausa dejtandet med Snooze-läget. Vi finns här när du är redo att komma igång igen.",
      "SuperSwipe",
      "Fastnat för någon? Visa hur du känner med en SuperSwipe.",
      "Travel-läge",
      "Dejta och matcha var som helst i världen med Travel-läget.",
      "Videosamtal",
      "Ring video- och röstsamtal för att se om ni är redo att träffas personligen.",
      "Webbapp",
      "Få Bumble-upplevelsen direkt från din webbläsare.",
      "Shop",
      "Huvudmeny",
      "Gå till huvudinnehåll",
      "Logotypen för Bumble",
      "Vill du lära känna personen? Ring videosamtal på Bumble",
      "Bara tryck på videoikonen i chatten för att starta ett videosamtal, helt utan att dela några kontaktuppgifter.",
      "Hur fungerar videosamtal på Bumble?",
      "Nej. Dina personuppgifter är säkra och skyddade i alla samtal och chattar på Bumble. Personen får inte ditt nummer eller några andra kontaktuppgifter förrän du själv lämnar ut dem.",
      "Ser min matchning mitt telefonnummer?",
      "Absolut! Om ni inte är redo att prata ansikte mot ansikte kan ni helt enkelt ringa ett röstsamtal. Bara tryck på samtalsikonen istället.",
      "Går det att bara ringa ett röstsamtal?",
      "Vem älskar inte att få meddelanden? Samtidigt kan det vara svårt att veta om snacket kommer att flyta på lika bra i verkligheten. Det är där video- och röstsamtal kommer in!",
      "Videosamtal på Bumble",
      "<a href={{video_chat_url}}>Video- och röstsamtal</a> kan hjälpa er att lära känna varandra och se om ni vill gå vidare utanför appen. Det bästa av allt? När du ringer ett samtal via Bumble behöver du inte lämna ut ditt telefonnummer, så dina kontaktuppgifter förblir hemliga tills du är redo att dela dem.",
      "Kolla kemin med videosamtal",
      "Fyll i luckan mellan online- och offlinedejtandet och se om det är dags att ta steget att träffas.",
      "Lär känna dem bättre (och snabbare). Ett video- eller röstsamtal kan säga mycket om någon!",
      "Du bestämmer. Vi håller dina kontaktuppgifter privata, så att du kan välja när du vill dela dem.",
      "Se om ni är redo att träffas personligen",
      "Gör en snabb vibe check",
      "Prata utan att ge ut kontaktuppgifter",
      "Fördelarna med röst- och videosamtal",
      "Redo att snacka? Ring ett röst- eller videosamtal direkt från chatten i Bumble-appen genom att trycka på den grå videokameran högst upp till höger, eller de tre prickarna bredvid ikonen.",
      "Så ringer du ett videosamtal",
      "Redo att prova Compliments?",
      "Om du skickar en komplimang visas den för personen som får den när du dyker upp i deras kö.",
      "Vad händer när jag ger någon på Bumble en komplimang?",
      "Fångade komplimangen ditt hjärta? Matcha med personen direkt! Komplimangen visas i chatten, och de vanliga reglerna för <a href={{make_the_first_move_url}}>Ta första steget</a> gäller.",
      "Hur svarar jag på en komplimang?",
      "Du kan skicka {{num}} [[Compliments:num]] utan kostnad. När de är slut kan du köpa fler i paket om upp till 30 i Bumble-appen. En av dessa används för att skicka komplimangen (spara resten till när du ser en annan profil du gillar). Var inte orolig – de förfaller inte!",
      "Hur många komplimanger kan jag skicka?",
      "Ett vänligt ord (eller två) kan gå långt. Här kommer lite inspiration till dig som vill skicka en komplimang men har svårt att komma igång:",
      "Se fler exempel",
      "”Alla som gillar [artist] har bra smak.”",
      "”Ditt leende smittar!”",
      "”Din bio fick mig att le. Bra jobbat!”",
      "Så skickar du bättre komplimanger på Bumble",
      "Vill du göra någons dag? Då är du redo för Compliments.",
      "Compliments på Bumble",
      "Med Compliments på Bumble kan du visa en potentiell matchning att du gillar deras profil (och kanske få dem att rodna!). Komplimanger ingår om du har {{service_level_lexeme_name}}. Det är också ett sätt att skicka och ta emot meddelanden innan ni har matchat.",
      "Så fungerar Compliments",
      "När du får en komplimang visas den på avsändarens profil i För dig. Du får även ett meddelande där det står att någon gillar en del av din profil.",
      "Se dina komplimanger",
      "Du kan skicka en komplimang genom att trycka på den gula hjärtikonen längst ned på någons profil. Var du trycker är viktigt, eftersom komplimangen hamnar på ett visst foto eller en viss del av profilen. När du trycker öppnas en textruta där du kan skriva ditt meddelande – bara se till att det är under 150 tecken!",
      "Skicka en komplimang",
      "HÄMTA Bumble OCH PROVA UPPTÄCK NU",
      "Varje dag kl. 09.00 ser du nya personer som valts ut för dig i Upptäck. Ni har alltid saker gemensamt, så att det blir lättare att ta kontakt.",
      "När uppdateras Upptäck?",
      "Din Upptäck-sida visar potentiella matchningar inom tre huvudkategorier: liknande intressen, samma dejtingmål och gemensamma grupper. Vi visar även profilrekommendationer baserat på tidigare matchningar.",
      "Hur fungerar Upptäck?",
      "En personlig sida som visar dina mest kompatibla personer, och uppdateras dagligen.",
      "HITTA SÅDANA SOM DU MED {{qr_code}} UPPTÄCK",
      "Upptäck visar utvalda personer som vi tror att du skulle gilla. De har liknande intressen, samma dejtingmål och brinner för samma frågor.",
      "Prova Upptäck",
      "REDO FÖR FLER GEMENSAMMA NÄMNARE?",
      "Sidan Upptäck uppdateras varje dag, så att du ser ännu fler personer du är kompatibel med. Lägg mindre tid på att leta efter matchningar och mer tid åt att lära känna människor du vet att du kommer överens med.",
      "NYA REKOMMENDATIONER VARJE DAG",
      "Rekommendationerna på Upptäck utgår från din profil och vilka du har matchat med tidigare. Du ser alltså personer som gillar och vill samma saker som du, oavsett om det är vegansk korv eller ett långvarigt förhållande.",
      "UTVALT{{__EOL__}}FÖR DIG",
      "Ladda ner Bumble",
      "Vanliga frågor",
      "Vill du använda Bumble anonymt?",
      "När du aktiverar Inkognito-läge blir du inte sedd av andra Bumble-medlemmar, förutom om du gillar deras profil.",
      "Hur fungerar Inkognito-läget?",
      "Utan Inkognito-läget är din profil synlig för andra dejtare på Bumble om du stämmer in på deras preferenser, som ålder och plats. När du aktiverar Inkognito-läget blir du bara sedd av personer som du själv har gillat.",
      "Vilka kan se mig med Bumbles Inkognito-läge?",
      "Att använda Inkognito-läget är inte samma sak som att <a href={{blocking_on_bumble_url}}>blockera någon på Bumble</a>. Med denna funktion kan andra fortfarande se din profil om du har gillat dem eller skrivit med dem tidigare.",
      "Är Inkognito-läge samma som att blockera någon?",
      "Ta kontroll över vem som kan se din profil med Bumbles Inkognito-läge.",
      "Inkognito-läget på Bumble",
      "Om du vill ta ett steg tillbaka från dejtandet eller använda Bumble utan att andra dejtare ser din profil kan du växla till Inkognito-läge. När du aktiverar denna funktion döljs du för alla, med undantag för personer du redan gillat. På så vis kan du styra vad du delar och vilka som ser dig.",
      "Dölj din profil med Inkognito-läget",
      "Du bestämmer vem som kan se dina foton och personuppgifter.",
      "Vill du visa något personligt på din profil? Dela vad du vill med dina befintliga matchningar på ett diskret sätt.",
      "Bläddra fritt utan att oroa dig för att stöta på ett ex, en kollega eller någon annan som inte är på din dejtlista.",
      "Ta full kontroll",
      "Välj vilken information du delar",
      "Undvik pinsamma möten",
      "Anledningar att älska Inkognito-läget",
      "Inkognito-läget är tillgängligt för dig som har {{service_level_lexeme_name}} eller {{service_level_lexeme_name_two}}. När du har uppgraderat kan du aktivera funktionen genom att gå till din profilflik, trycka på Inställningar i det övre högra hörnet och växla till Inkognito-läge.",
      "Uppgradera till {{service_level_lexeme_name}}",
      "Så använder du Inkognito-läget på Bumble",
      "HÄMTA Bumble OCH PROVA MATCHA DIREKT IDAG",
      "Gå till din profil genom att trycka på ikonen längst ned i Bumble-appen. Där hittar du QR-koden i det övre högra hörnet. Tryck för att öppna den.",
      "Var hittar jag min personliga QR-kod?",
      "Nej! Du måste vara ansluten till internet och använda den senaste QR-koden för att Matcha direkt ska fungera.",
      "Kan jag visa en skärmbild av min QR-kod?",
      "Nej! Om någon skannar koden matchar ni automatiskt.",
      "Måste båda skanna QR-koden?",
      "Med Matcha direkt kan du se vad du och din nya matchning har gemensamt automatiskt och få en glimt av er kompatibilitet redan från början.",
      "HITTA GEMENSAMMA NÄMNARE SNABBARE",
      "Börjat snacka på ett Bumble-event? Fortsätt i appen.",
      "FRÅN MÖTE TILL {{qr_code}} MATCHNING",
      "Matcha direkt gör det lätt att ta småpratet till att hålla kontakten utan att byta nummer eller sociala profiler. Bara be dem att skanna din QR-kod så matchar ni på Bumble direkt.",
      "Prova Matcha direkt",
      "TRÄFFAT NÅGON PÅ ETT Bumble-EVENT?",
      "Med Matcha direkt kan du se hur det känns i appen innan ni byter sociala profiler eller telefonnummer. På så sätt kan ni lära känna varandra på era villkor och med Bumbles säkerhetsfunktioner.",
      "ETT SÄKRARE SÄTT ATT TA KONTAKT",
      "Har ni träffats på ett Bumble-event IRL? Jobba vidare på kemin genom att fortsätta samtalet i appen och bonda över era intressen, oavsett om det är kärleken till den perfekta koppen kaffe eller att odla sällsynta suckulenter ni har gemensamt.",
      "SNACK SOM FLYTER PÅ",
      "Prova Opening Moves på Bumble",
      "Opening Moves är ett annat sätt att prata med personer du gillar. När du har valt ett meddelande att skicka är det bara att luta dig tillbaka, vänta på svar och skriva till de som sticker ut lite extra.",
      "Hur använder jag Opening Moves på Bumble?",
      "Vid icke-binära och samkönade kontakter kan båda personerna ställa in och svara på en samtalspunkt.",
      "Kan jag använda Opening Moves på Bumble om jag dejtar personer med samma kön?",
      "När någon svarar på din samtalspunkt dyker deras profil upp i dina chattar. Kvinnor har 24 timmar på sig att svara innan matchningen upphör. Därefter kan du antingen svara eller starta ett nytt samtal.",
      "Hur ser jag om någon har svarat på min samtalspunkt?",
      "Opening Moves är vårt sätt att minska pressen kring att ta första steget, och göra det enklare och snabbare att komma igång.",
      "Opening Moves på Bumble",
      "Tack för att du frågar! Opening Moves är ett nytt, enklare sätt att starta en konversation. Du kan välja förslag som t.ex. ”Vilken låt spelar du helst på en fest?” eller skriva din egen. Om du har en förinställd samtalspunkt kan dina matchningar svara redan när de ser dig på matchningsskärmen.",
      "Vad är en samtalspunkt?",
      "minska pressen",
      "visa vad som gör dig till dig",
      "starta bättre samtal",
      "Opening Moves kan...",
      "Du kan ställa in en samtalspunkt som skickas ut när du matchar med andra dejtare. Gå till Opening Moves i din profil, välj en av de färdiga frågorna eller tänk kreativt och skriv din egen. Du kan ändra dina samtalspunkter när som helst.",
      "Prova Opening Moves",
      "Så använder du Opening Moves på Bumble",
      "<a href={{see_more_url}}>Se mer</a>",
      "Vill du ta en paus? Prova Snooze-läget",
      "Ja, du kan stänga av Snooze i inställningarna eller kön när du vill.",
      "Går det att inaktivera Snooze-läget i förtid?",
      "Gå till inställningar, tryck på Snooze och välj hur länge du vill vara borta. Kom tillbaka när du känner dig redo.",
      "Hur använder jag Snooze-läget på min profil?",
      "Nej, all swipe- och chattaktivitet pausas när Snooze-läget är på. Om du går till din kö ser du ett meddelande om hur länge Snooze-läget kommer att vara igång, med möjligheten att inaktivera det.",
      "Går det fortfarande att swipa och chatta i Snooze-läget?",
      "Personer du redan har skickat meddelanden till ser din Borta-status (om du väljer att ställa in den). Annars ser de dina tidigare meddelanden i sin inkorg som vanligt.",
      "Vad ser nuvarande matchningar när jag är i Snooze-läge?",
      "Du har total kontroll över din aktivitet på Bumble och kan ta ett steg bort när du vill.",
      "Du kan hålla befintliga kontakter uppdaterade genom en statusuppdatering, så det inte ser ut som att du <a href={{ghosted_article_url}}>ghostar</a>. Dessutom är allt automatiserat – du behöver inte skicka några meddelanden.",
      "Ta en paus från dejtandet utan att förlora dina matchningar eller chattar.",
      "Dejta (eller pausa) på dina egna villkor",
      "Håll dina kontakter uppdaterade",
      "Pausa din aktivitet, men inte dina kontakter",
      "Därför älskar vi Snooze-läget",
      "Öppna Bumble-appen, gå till profilfliken och tryck på kugghjulet i det övre högra hörnet. I inställningarna trycker du på Snooze och väljer hur länge du ska vara borta. Du kan dölja din profil i 24 timmar, 72 timmar, en vecka eller tills vidare.{{__EOL__}}{{__EOL__}}Kom ihåg att matchningar utan aktiva chattar kan upphöra under tiden Snooze-läget är aktiverat. Om du väljer att ställa in en status kan de som du chattar med för närvarande se att du är borta.",
      "Så fungerar Snooze-läget på Bumble",
      "Snooze-läget finns där när livet blir hektiskt. Pausa ditt Bumble-konto och dejta på dina egna villkor.",
      "Snooze-läge på Bumble",
      "Snooze-läget är ett enkelt sätt att dölja ditt konto när du vill ta en paus från dejtandet. Du kan fortfarande chatta med befintliga kontakter – profilen göms bara från andra dejtare tills du är redo att börja swipa igen. Du förlorar ingen profilinformation eller befintliga kontakter genom att aktivera Snooze-läge.",
      "Pausa din Bumble-profil",
      "När du aktiverar Snooze-läget kan du välja att meddela befintliga kontakter om att du tar en paus genom att ställa in en status. Välj mellan Jag är ute och reser, Jag fokuserar på jobbet, Jag är på digital detox eller Jag prioriterar mig själv. Du bestämmer!",
      "Lägg till Borta-status",
      "Om du aktiverar Snooze-läget pausas ditt konto på Bumble eller {{page_partner_name_bff}}. Dina matchningar får inte reda på att du har aktiverat Snooze-läge om du inte berättar det för dem. Om du vill stänga av Snooze-läget tidigare än du valde från början går du till Inställningar och trycker på Inaktivera Snooze-läge. Lätt som en plätt!",
      "Pausa dina chattar",
      "Prova SuperSwipe på Bumble",
      "Så många du vill! Om du har {{service_level_lexeme_name}} får du {{num}} [[superswipe:num]] per vecka, men du kan fylla på saldot när som helst. Gratismedlemmar kan köpa paket med SuperSwipes.",
      "Hur många SuperSwipes kan jag skicka på Bumble?",
      "När någon ger din profil en SuperSwipe får du ett meddelande direkt. Du ser även en etikett ovanför personens namn på deras profil där det står att de gillar dig.",
      "Hur vet jag om jag fått en SuperSwipe av någon?",
      "Gillar du någon lite extra? Det finns ett coolt sätt att bryta isen. Skicka en SuperSwipe för att visa att du är intresserad och ta genvägen till fina samtal.",
      "SuperSwipe på Bumble",
      "Att sticka ut från mängden är inte alltid lätt, men SuperSwipe kan hjälpa dig att göra ett bra första intryck.{{__EOL__}}{{__EOL__}}När du använder en SuperSwipe får personen du gillar en avisering. Du visas sedan först i deras kö med en etikett på din profil. Om de gillar dig tillbaka är det bara att börja snacka!",
      "Vad är en SuperSwipe?",
      "våga stå ut från mängden",
      "starta fler chattar",
      "få fler matchningar",
      "SuperSwipe kan hjälpa dig att...",
      "Om du har <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> eller Bumble {{service_level_lexeme_name_one}} får du {{num}} kostnadsfria [[superswipe:num]] per vecka. Alla medlemmar kan också köpa paket med upp till {{num_two}} [[superswipe:num_two]] på profilfliken. Om du har {{service_level_lexeme_name_three}} får du {{num_three}} [[superswipe:num_three]] per vecka.{{__EOL__}}{{__EOL__}}Det är enkelt att använda SuperSwipe – bara tryck på den gula stjärnikonen i nedre hörnet av profilen när du ser någon du gillar.",
      "Så här använder du SuperSwipe",
      "Apple och logotypen för Apple är varumärken som tillhör Apple Inc. Google Play är ett varumärke som tillhör Google LLC.",
      "Dejta på språng med Bumbles Travel-läge",
      "<a href={{bumble_premium_url}}>{{service_level_lexeme_name}}-medlemmar</a> kan använda Travel-läget. Aktivera det så visas nya ansikten i din valda stad.",
      "Hur använder jag Travel-läget?",
      "Ja, i princip. Vi har en del säkerhetsbegränsningar som kan förändras över tid, men Travel-läget är tillgängligt för de flesta destinationer.",
      "Kan jag använda Travel-läget var som helst?",
      "Travel-läget visar din position som mitt i den valda staden, så att nya kontakter i området kan hitta dig. De ser även en etikett på din profil som visar att du är på besök eller på väg.",
      "Packar du väskan inför en semester eller ska du till en ny stad med jobbet? Aktivera Bumbles Travel-läge och ta med dig dejtandet ut i världen.",
      "Travel-läge på Bumble",
      "Travel-läget är det ultimata sättet att knyta kontakter på resande fot. Ställ in din plats som var som helst i världen, så visas din profil som att du befinner dig där. Nu kan du ta med dig dejtandet vart äventyret än bär!",
      "Vad är Travel-läget?",
      "Ingen känner till en plats bättre än lokalborna. Med Travel-läget kan du få kontakt med de som gör resan extra speciell.",
      "Ställ in din plats upp till sju dagar innan du åker, så att du kan planera dejter och hitta matchningar i förväg.",
      "Det behöver inte vara en solsemester – kanske åker du hem över jul, reser i jobbet eller utforskar en ny stad. Romantik finns överallt.",
      "Hitta de bästa dejtställena i närheten",
      "Åk på äventyr",
      "Knyt meningsfulla kontakter",
      "Anledningar att älska Travel-läget",
      "Travel-läget är tillgängligt för Bumble-medlemmar som har <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} eller {{service_level_lexeme_name_two}}</a>. Tryck på kugghjulet i det övre högra hörnet av din profil, scrolla tills du ser Travel-läge och välj din nya plats, så kan du börja dejta personer i det området. Potentiella matchningar kan se att du använder Travel-läge på din profil.",
      "Hur fungerar Travel-läget?",
      "Prova nu",
      "Prova Bumble på webben",
      "Absolut. Bumbles webbapp fungerar på PC, Mac och surfplattor och med alla vanliga webbläsare.",
      "Kan jag använda Bumble på min PC eller Mac?",
      "Ja, det gör det. Du kan skicka meddelanden och till och med ringa röst- eller videosamtal på Bumble på webben.",
      "Går det att chatta med matchningar i webbappen?",
      "Välj Logga in eller klicka <a href={{sign_in_url}}>här</a>. Du kan logga in med ditt Apple ID, Facebook eller telefonnummer.",
      "Hur loggar jag in på Bumble på webben?",
      "Behöver du en paus från telefonen? Prova Bumble på webben – Bumble-upplevelsen du älskar på större skärm.",
      "Använd Bumble på webben",
      "Det är enkelt att logga in på Bumble på webben. Besök vår webbplats, klicka på Logga in och välj din vanliga inloggningsmetod med ditt Apple ID, Facebook eller telefonnummer. Voila! Nu är du inne, och dina chattar och kontakter finns kvar precis där du lämnade dem.",
      "Logga in",
      "Logga in via webbläsare",
      "Redigera eller gör ändringar i din profil och använd avancerade sökfilter.",
      "Bekräfta att du är du och öka chansen till en riktigt bra matchning.",
      "Dela fina nya foton eller ta bort gamla.",
      "Uppdatera din profil",
      "Visa andra vem du är",
      "Lägg till eller ta bort foton",
      "Det här kan du göra på Bumble på webben",
      "Du hittar dina chattar och listan över dina kontakter till vänster, samt potentiella nya matchningar till höger.{{__EOL__}}{{__EOL__}}Använd vänster- och högerpilknapparna eller klicka på symbolerna på höger sida av skärmen för att titta på profiler och gilla personer precis som på telefonen.",
      "Hur använder jag Bumble på webben?",
      "På Bumble är det sexigt att vara snäll. Här duger man som man är, och kvinnorna tar första steget. Alltid.",
      "Våga ta steget",
      "Sunda relationer är centralt för att leva ett positivt och produktivt liv. Bumble är designat för att du ska känna dig trygg när du skapar nya kontakter, oavsett om du vill dejta, lära känna nya vänner eller utvidga ditt jobbnätverk.",
      "Så gör Bumble skillnad",
      "Vi uppmuntrar till integritet, vänlighet, jämlikhet, trygghet och respekt i alla relationsstadier. Vi både välkomnar och hyllar alla orienteringar – oavsett om du är här för kärlek eller bara för att ha kul.",
      "Bumbles värderingar",
      "På Bumble är det kvinnorna som sätter tempot genom att ta första steget eller ställa in samtalspunkter för matchningar att svara på. På så sätt bryter vi de förlegade könsnormerna i dejtingvärlden och uppmanar till jämlikhet redan från början.",
      "Så fungerar Bumble",
      "Om oss",
      "Ladda ned nu",
      "Det är bättre på appen.",
      "Funktioner som gör nätdejting rolig på riktigt",
      "12 februari 2024",
      "Så får du mer känslomässig intimitet i ditt förhållande",
      "25 januari 2024",
      "Så använder du Bumble om du aldrig appdejtat innan",
      "5 februari 2024",
      "Deception Detector™",
      "Senaste nytt från dejtingvärlden",
      "Om storlek spelar roll, gå till vår webbplats. Den är precis som vår app fast större.",
      "Webbapp",
      "Pausa dejtandet med <b>Snooze-läget</b>. Vi finns här när du är redo att komma igång igen.",
      "Ta en paus",
      "Med <b>video-</b> och <b>röstsamtal</b> kommer du så nära IRL som möjligt utan att lämna hemmet.",
      "Säg som det är",
      "Se mer",
      "Låt din matchning starta chatten med <b>Opening Moves</b>.",
      "Gör mindre, dejta mer",
      "Dejta och matcha var som helst i världen med <b>Travel-läget</b>.",
      "Ta Bumble med dig",
      "Fastnat för någon? Visa hur du känner med en <b>SuperSwipe</b>.",
      "Fånga deras uppmärksamhet",
      "Stick ut från mängden med <b>Compliments</b>.",
      "Få dem att rodna",
      "Dölj din profil från ditt ex, släkten eller chefen med <b>Inkognito-läget</b>.",
      "Gå undercover",
      "Dejta på ditt sätt",
      "Ett par månader senare träffade hon en av sina brudtärnor här också.",
      "Hannah träffade sin man på Bumble",
      "Carène och You-Liang träffades på Bumble, där You-Liang drogs till Carènes resebilder. Nu har de byggt hus ihop.",
      "Carène och You-Liang",
      "Brian och Kaci var stationerade utomlands tillsammans – men de träffades på Bumble.",
      "Brian och Kaci",
      "Om det funkade för dem kan det funka för dig",
      "Ta nästa steg",
      "Se nyheterna",
      "Du förtjänar det bästa. Därför har vi tagit fram riktigt bra sätt att dejta mer och stressa mindre.",
      "Sätt ribban där den hör hemma",
      "Om Bumble",
      "Vi är den enda appen som gör dejting bättre genom att sätta kvinnornas upplevelse först. För när saker och ting är bättre för kvinnor är de bättre för alla.",
      "Make the first move™",
      "Hitta någon du vill dejta på riktigt och ta första steget. Knyt äkta kontakter, bonda över sådant ni båda älskar och – viktigast av allt – ha kul!",
      "Träffa någon med Bumble Date",
      "Hitta vänner med jobbförmåner. Träffa andra yrkespersoner och skaffa ett nytt jobb, en mentor eller en helt ny karriär.",
      "Ge karriären en knuff med Bumble Bizz",
      "Träffa någon som du. Eller inte som du. Skaffa nya vänner och hitta saker att göra ihop – hemma eller borta.",
      "Hitta nya vänner på Bumble For Friends",
      "Vi är mer än bara dejting",
      "Bumble är dejtingappen där kvinnorna tar första steget. Det är appen som förändrade hur människor dejtar, knyter meningsfulla kontakter och nätverkar. Hämta nu.",
      "Bumble på {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "Sorry, vi hittar tyvärr inte sidan du letar efter.",
      "Kontakta oss",
      "Logga in",
      "Hoppsan, något gick fel...",
      "(opens in new window)",
      "Vanliga frågor",
      "Cookie Policy",
      "Premium",
      "Bumble – The Blog",
      "Lediga jobb",
      "Chatt",
      "Städer",
      "Hantera cookies",
      "Copyright © 2006 – dagens datum. Bumble. Alla rättigheter förbehålls.",
      "Rapport om dejting och trygghet",
      "Apple och logotypen för Apple är varumärken som tillhör Apple Inc.{{__EOL__}}Google Play är ett varumärke som tillhör Google LLC.",
      "Ladda ner",
      "Facebook",
      "Följ oss",
      "Hjälpcenter",
      "Instagram",
      "Språk",
      "LinkedIn",
      "Bumble är del av Bumble Inc.",
      "Sekretesspolicy",
      "Säkerhetscenter",
      "Webbplatskarta",
      "Uttalande om Modern Slavery Act",
      "Villkor och regler",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "plural": [
                  "komplimanger",
                  "komplimangers"
            ],
            "singular": [
                  "komplimang",
                  "komplimangs"
            ]
      },
      "day": {
            "plural": [
                  "dagar",
                  "dagars"
            ],
            "singular": [
                  "dag",
                  "dags"
            ]
      },
      "hour": {
            "plural": [
                  "timmar",
                  "timmars"
            ],
            "singular": [
                  "timme",
                  "timmes"
            ]
      },
      "minute": {
            "plural": [
                  "minuter",
                  "minuters"
            ],
            "singular": [
                  "minut",
                  "minuts"
            ]
      },
      "month": {
            "plural": [
                  "månader",
                  "månaders"
            ],
            "singular": [
                  "månad",
                  "månads"
            ]
      },
      "second": {
            "plural": [
                  "sekunder",
                  "sekunders"
            ],
            "singular": [
                  "sekund",
                  "sekunds"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipes",
                  "SuperSwipes"
            ],
            "singular": [
                  "SuperSwipe",
                  "SuperSwipes"
            ]
      },
      "year": {
            "plural": [
                  "år",
                  "års"
            ],
            "singular": [
                  "år",
                  "års"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
