/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Retour",
      "Télécharger sur l'App Store",
      "Télécharger sur Google Play",
      "Carrousel",
      "Diapositive {{number}}",
      "Diapositive suivante",
      "Diapositive précédente",
      "Diapositive",
      "Diapositive {{number}} sur {{total}}",
      "Ne pas vendre ou partager mes données personnelles",
      "Ne pas vendre ou partager mes données personnelles",
      "Envoie des Compliments sur Bumble pour montrer ton intérêt et attirer l'attention avant de matcher. Essaie-le maintenant",
      "Compliments | Comment envoyer un Compliment | Bumble",
      "Une page personnalisée qui met en avant les profils les plus compatibles, actualisée chaque jour.",
      "À découvrir | Trouve des gens compatibles | Bumble",
      "Fais des rencontres à ton rythme et contrôle qui voit ton profil Bumble avec le mode Incognito. Consulte notre guide pour en savoir plus sur l'utilisation du mode Incognito sur Bumble",
      "Mode Incognito | Cacher ton profil | Bumble",
      "Après avoir fait connaissance lors d'un événement Bumble en personne, renforcez votre alchimie en poursuivant la conversation sur l'appli.",
      "Match instantané | Continue la conversation sur l'appli | Bumble",
      "Essaie une nouvelle façon de lancer la conversation avec la fonctionnalité Opening Moves™ de Bumble. Attire l'attention, donne le ton et discute plus rapidement",
      "Opening Moves™ de Bumble | Bumble",
      "Tu as besoin de prendre du temps pour toi? Découvre comment mettre ton compte Bumble sur pause avec le mode Invisible de Bumble, et fais des rencontres à ton rythme",
      "Mode Invisible | Fais une pause | Bumble",
      "Quelqu'un sur Bumble te plaît beaucoup? Utilise un SuperSwipe pour montrer ton intérêt. Appuie sur l'étoile d'un profil pour commencer, ou trouve plus de détails ici",
      "SuperSwipe de Bumble | Qu'est-ce qu'un SuperSwipe? | Bumble",
      "Tu voyages pour le travail ou le plaisir? Le mode VVoyage sur Bumble peut t'aider à faire des rencontres où que tu ailles. Découvrez le mode Voyage ici maintenant",
      "Mode Voyage de Bumble | Matche n'importe où | Bumble",
      "Tu veux savoir si votre complicité sera aussi bonne en personne? Essaie un appel vidéo ou audio sur Bumble et vérifie votre compatibilité",
      "Appel vidéo | Faire un appel | Bumble",
      "Découvre la magie de Bumble Web, la fonctionnalité ultime.",
      "Bumble Web | Bumble",
      "Résilier les contrats",
      "Page d'accueil de Bumble",
      "Ferme le menu",
      "Ouvre le menu",
      "Choix de la langue",
      "Bas de page",
      "Accessibilité chez Bumble",
      "Apple et le logo Apple sont des marques déposées d'Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Carrières",
      "Événements",
      "Google Play est une marque de commerce de Google LLC.",
      "Règlement",
      "Investisseurs",
      "Gérer les témoins",
      "Gérer les témoins",
      "Loi sur l'esclavage moderne",
      "Avis de collecte",
      "Politique de confidentialité",
      "Guides de rencontres saisonniers",
      "Conditions d'utilisation",
      "L'appli",
      "Politique de confidentialité des données de santé du consommateur de Washington",
      "À propos",
      "Ambassadeurs",
      "Le blog",
      "Fais-toi de nouveaux amis qui te correspondent",
      "Réseaute et donne un coup de pouce à ta carrière",
      "Trouve une relation sérieuse, un coup d'un soir ou autre chose",
      "En savoir plus",
      "Parcours le monde avec le mode Voyage",
      "Fonctions",
      "Compliments",
      "Envoie des Compliments pour te démarquer.",
      "À découvrir",
      "Une page personnalisée qui met en avant les profils les plus compatibles, actualisée chaque jour.",
      "Mode Incognito",
      "Utilise le mode Incognito pour cacher ton profil à ton patron, tes proches ou tes ex.",
      "Match instantané",
      "Tu as entamé une conversation à un événement Bumble en personne? Continue-la sur l'appli.",
      "Opening Moves™",
      "Choisis des Opening Moves™ pour inviter ton match à lancer la discussion et t'éviter d'avoir à le faire.",
      "Mode Invisible",
      "Prends un moment pour toi avec le mode Invisible. Quand tu voudras revenir, tu sais où nous trouver.",
      "SuperSwipe",
      "Son profil t'intéresse? Fais-lui savoir en lui envoyant un SuperSwipe.",
      "Mode Voyage",
      "Trouve ton bonheur n'importe où dans le monde grâce au mode Voyage.",
      "Appel vidéo",
      "Passe des appels vidéo et audio pour vérifier si c'est le bon moment pour une rencontre en personne.",
      "Application Web",
      "Découvre l'expérience Bumble directement depuis ton navigateur.",
      "Boutique",
      "Principale",
      "Passer au contenu principal",
      "Logo de Bumble",
      "Tu veux faire sa connaissance? Lance un appel vidéo sur Bumble",
      "Il te suffit de toucher l'icône vidéo dans ta discussion pour démarrer un appel vidéo, tout cela sans partager aucune information de contact personnelle.",
      "Comment fonctionne l'appel vidéo sur Bumble?",
      "Non. Tous les appels et discussions sur Bumble conservent tes informations personnelles en sécurité. Tes matchs auront accès à ton numéro ou à tes coordonnées uniquement si tu les partages toi-même.",
      "Est-ce que mon match pourra voir mon numéro de téléphone?",
      "Absolument! Si tu penses que ce n'est pas encore le bon moment pour un face à face, les appels vocaux te permettent de faire connaissance de manière audio. Il te suffit de sélectionner l'icône d'appel à la place.",
      "Puis-je simplement essayer un appel audio?",
      "Qui n'aime pas voir la notification de *message reçu*? Mais nous savons qu'il est difficile de savoir si vos discussions seront aussi agréables en personne. C'est là que les appels vidéo et audio entrent en jeu.",
      "Les appels vidéo sur Bumble",
      "<a href={{video_chat_url}}>Les appels audio et vidéo</a> peuvent t'aider à faire connaissance et à décider si tu souhaites continuer en dehors de l'application. Le plus beau dans tout ça? Lorsque tu passes un appel via Bumble, tu n'as pas besoin de donner ton numéro de téléphone. Tes infos personnelles restent donc en sécurité jusqu'à ce que tu penses que c'est le bon moment de les partager.",
      "Vérifie votre alchimie avec un appel vidéo",
      "Réduis l'écart entre les rencontres en ligne et hors ligne, et décide si c'est le moment de vous rencontrer en personne.",
      "Fais plus ample connaissance (et plus rapidement). Une conversation en face à face ou un appel téléphonique peut en révéler beaucoup sur quelqu'un.",
      "Tu as le contrôle. Nous garderons tes informations privées; tu peux donc décider quand les partager.",
      "Vois si c'est le moment pour une rencontre en personne",
      "Vérifie votre alchimie",
      "Fais connaissance sans donner tes infos personnelles",
      "Pourquoi tu devrais utiliser les appels audio et vidéo",
      "Tu veux entamer une discussion? Tu peux choisir de démarrer un appel audio ou vidéo directement depuis la discussion dans l'application Bumble. Pour lancer un appel vidéo, appuie sur l'icône en forme de caméra grise dans le coin supérieur droit. Tu verras également trois points à côté de l'icône; clique dessus et tu verras l'option d'appel audio également.",
      "Comment lancer un appel vidéo",
      "Envie d'essayer les Compliments?",
      "Lorsque tu envoies un Compliment, la personne qui le reçoit le verra quand tu apparaîtras dans ses Pour toi.",
      "Qu'est-ce qui se passe lorsque j'envoie un Compliment à quelqu'un sur Bumble?",
      "Si son Compliment a attiré ton attention, alors n'hésite pas à matcher. Tu verras le Compliment dans votre discussion, et les règles habituelles pour <a href={{make_the_first_move_url}}>faire le premier pas</a> s'appliqueront.",
      "Comment puis-je répondre à un Compliment?",
      "Tu peux envoyer {{num}} [[Compliments:num]] gratuitement. Lorsque tu n'en as plus, tu peux en acheter davantage dans l'application Bumble par lots allant jusqu'à 30 Compliments. L'un de ces derniers sera utilisé pour envoyer ce Compliment (conserve le reste pour quand tu verras un autre profil qui te plaît). Ne t'inquiète pas, ils n'expireront pas!",
      "Combien de Compliments puis-je envoyer?",
      "Un petit mot gentil (ou deux) peut faire du bien. Si tu veux envoyer un Compliment mais que tu ne sais pas quoi dire, tu peux t'inspirer de ces exemples :",
      "Voir d'autres exemples",
      "« Tu aimes [nom de l'artiste]? Tu as très bon goût. »",
      "« Ton sourire est contagieux! »",
      "« Ta bio est vraiment drôle. »",
      "Comment envoyer de meilleurs compliments sur Bumble",
      "Tu aimes répandre la bonne humeur? Les Compliments sont là pour t'aider.",
      "Les Compliments sur Bumble",
      "Utilise des Compliments sur Bumble pour indiquer à un match potentiel que tu aimes son profil (et peut-être même lui donner le sourire). Tu obtiens des Compliments en tant que membre {{service_level_lexeme_name}}. C'est aussi un moyen d'envoyer et de recevoir des messages avant même de matcher.",
      "Comment les Compliments fonctionnent",
      "Lorsque tu reçois un Compliment, il apparaît sur le profil de l'expéditeur dans tes Pour toi. Tu reçois aussi une notification t'indiquant qu'une personne aime une partie de ton profil.",
      "Découvre tes Compliments",
      "Tu peux envoyer un Compliment en appuyant sur l'icône du cœur jaune en bas du profil de quelqu'un. L'endroit où tu appuies est important, car tu complimenteras cette photo ou cette partie de son profil! Lorsque tu appuies, une zone de texte s'ouvre pour que tu puisses écrire ton message (il peut contenir jusqu'à 150 caractères).",
      "Envoie un Compliment",
      "TÉLÉCHARGE Bumble POUR ESSAYER À DÉCOUVRIR",
      "Tous les jours à partir de 9 h, tu verras de nouvelles personnes dans « À découvrir ». Tu pourras entrer en contact avec elles sur la base de vos points communs.",
      "Quand la page « À découvrir » est-elle mise à jour?",
      "La page À découvrir classe les matchs potentiels en 3 catégories principales : passions similaires, objectifs de relation identiques et communautés en commun. Des recommandations de profil sont également affichées et sont basées sur tes matchs précédents.",
      "Comment ma page « À découvrir » est-elle composée?",
      "Une page personnalisée qui met en avant les profils les plus compatibles, actualisée chaque jour",
      "TROUVE DES GENS QUI TE RESSEMBLENT AVEC {{qr_code}} À DÉCOUVRIR",
      "À découvrir te présente une sélection de personnes qui devraient t'intéresser, en fonction des passions, des objectifs de rencontre et des communautés.",
      "Essayer À découvrir",
      "À LA RECHERCHE D'UNE NOUVELLE FAÇON DE TROUVER DES POINTS COMMUNS?",
      "La page À découvrir est actualisée tous les jours pour te montrer encore plus de profils compatibles. Tu peux ainsi passer moins de temps à chercher une personne compatible et plus de temps à approfondir tes relations.",
      "DES RECOMMANDATIONS ACTUALISÉES CHAQUE JOUR",
      "Les recommandations de la page À découvrir sont basées sur ton profil et sur les personnes avec qui tu as déjà matchées. Tu verras donc des gens qui aiment ce que tu aimes et qui veulent ce que tu veux, qu'il s'agisse de rouleaux de homard végétaliens ou d'une relation à long terme.",
      "SUR MESURE{{__EOL__}}RIEN QUE POUR TOI",
      "Télécharger Bumble",
      "Questions fréquentes",
      "Tu veux parcourir Bumble en toute discrétion?",
      "Lorsque tu actives le mode Incognito, ton profil est masqué aux autres utilisateurs de Bumble à moins que tu ne leur donnes un J'aime.",
      "Comment fonctionne le mode Incognito?",
      "Sans le mode Incognito, ton profil est visible pour les autres utilisateurs sur Bumble lorsque tu corresponds à leurs préférences, comme l'âge et la position. Lorsque tu actives le mode Incognito, tu ne seras visible que pour les personnes auxquelles tu as donné un J'aime.",
      "Qui peut me voir en mode Incognito sur Bumble ?",
      "Utiliser le mode Incognito ne signifie pas <a href={{blocking_on_bumble_url}}>bloquer quelqu'un sur Bumble</a>. Si tu donnes un J'aime à une personne (ou si tu lui as envoyé un message par le passé), elle pourra quand même voir ton profil.",
      "Le mode Incognito est-il similaire à la fonctionnalité Bloquer?",
      "Contrôle qui peut voir ton profil grâce au mode Incognito sur Bumble.",
      "Mode Incognito sur Bumble",
      "Si tu souhaites t'éloigner des rencontres, ou parcourir Bumble sans que d'autres utilisateurs ne voient ton profil, tu peux activer le mode Incognito. Cela signifie que nous te cacherons à la vue de tous, à moins que tu ne leur donnes un J'aime en premier. Tu peux ainsi contrôler ce que tu partages et qui te voit.",
      "Cache ton profil avec le mode Incognito",
      "Tu décides qui peut voir tes photos et tes informations personnelles.",
      "Tu veux ajouter quelque chose de personnel sur ton profil? Tu peux partager discrètement ce que tu souhaites avec tes matchs actuels.",
      "Navigue librement sans te soucier de tomber sur les personnes que tu souhaites éviter.",
      "Prends le contrôle total",
      "Choisis ce que tu partages",
      "Évite les rencontres gênantes",
      "Pourquoi tu vas adorer le mode Incognito",
      "Le mode Incognito est disponible pour les membres {{service_level_lexeme_name}} et {{service_level_lexeme_name_two}}. Une fois ton inscription finie, tu peux l'activer en te rendant sur l'onglet de ton profil, en appuyant sur Paramètres dans le coin supérieur droit, et en passant en mode Incognito.",
      "Activer {{service_level_lexeme_name}}",
      "Comment utiliser le mode Incognito sur Bumble",
      "TÉLÉCHARGE Bumble POUR ESSAYER MATCH INSTANTANÉ",
      "Dans l'application Bumble, rends-toi sur l'icône Profil en bas de ton écran. À partir de là, tu verras l'icône du code QR en haut à droite de l'écran. Appuie dessus pour y accéder.",
      "Où puis-je trouver mon code QR personnel?",
      "Non! Tu dois te connecter à Internet et utiliser le code QR actuel pour que Match instantané fonctionne.",
      "Est-ce que je peux faire une capture d'écran de mon code QR pour le partager?",
      "Non! Si une personne scanne le code, le match sera établi automatiquement.",
      "Est-ce que les deux personnes doivent scanner le code QR?",
      "Avec Match instantané, tu peux automatiquement voir ce que toi et l'autre personne avez en commun, ce qui te donne un aperçu de votre compatibilité dès le départ.",
      "TROUVE VOS POINTS COMMUNS, RAPIDEMENT",
      "Tu as entamé une conversation à un événement Bumble en personne? Continue-la sur l'appli.",
      "TRANSFORME VOTRE RENCONTRE EN {{qr_code}} MATCH INSTANTANÉ",
      "Match instantané te permet de faire passer votre rencontre au niveau « continuons à parler » sans échanger de numéros ou de profils sociaux. Il suffit de demander à l'autre de scanner ton code QR pour que vous matchiez sur Bumble.",
      "Essayer Match instantané",
      "TU AS RENCONTRÉ QUELQU'UN À UN ÉVÉNEMENT Bumble EN PERSONNE?",
      "Oublie l'échange des profils sociaux ou des numéros de téléphone. Match instantané te permet de vérifier votre alchimie dans l'appli au préalable. De cette façon, vous pouvez mieux vous connaître à vos rythmes, grâce à la gamme de fonctionnalités de sécurité de Bumble.",
      "UNE FAÇON PLUS SÉCURITAIRE DE TISSER DES LIENS",
      "Après avoir fait connaissance lors d'un événement Bumble en personne, renforcez votre alchimie en poursuivant la conversation sur l'appli et en échangeant sur des passions communes, qu'il s'agisse de l'amour du café parfait ou de la culture des succulentes rares.",
      "POURSUIVEZ LA CONVERSATION",
      "Essaie les Opening Moves™ sur Bumble",
      "Opening Moves™ est une autre façon de discuter avec les personnes qui te plaisent. Une fois que tu as choisi un message à envoyer, tu peux te détendre, attendre les réponses, et répondre à celles qui se démarquent réellement.",
      "Comment utiliser les Opening Moves™ sur Bumble",
      "En ce qui concerne les relations non-binaires et de même genre, les deux personnes peuvent ajouter un Opening Move™ et y répondre.",
      "Puis-je utiliser Opening Moves™ sur Bumble pour rencontrer des personnes du même genre que moi?",
      "Lorsqu'une personne répond à ton Opening Move™, tu verras son profil apparaître dans tes discussions. Les femmes ont 24 heures pour répondre avant que le match n'expire. À partir de là, tu peux soit répondre à sa réponse, soit entamer une nouvelle conversation.",
      "Comment puis-je voir si quelqu'un a répondu à mes Opening Moves™?",
      "Les Opening Moves™ permettent de relâcher la pression lorsqu'il s'agit de faire le premier pas, avec une méthode plus facile et plus rapide d'entamer une discussion intéressante.",
      "Opening Moves™ sur Bumble",
      "Nous sommes heureux que tu poses la question. Les Opening Moves™ sont une nouvelle méthode qui facilite le lancement d'une discussion. Tu peux choisir un Opening Move™ recommandé comme « Quelle est ta chanson préférée si tu es aux commandes à une soirée? », ou écrire le tien. Si tu en as défini un, tes matchs peuvent y répondre lorsqu'ils te voient apparaître dans leurs matchs.",
      "Qu'est-ce qu'un Opening Move™?",
      "Relâcher la pression",
      "Partager ce qui te rend unique",
      "Lancer des discussions plus intéressantes",
      "Les Opening Moves™ peuvent t'aider à…",
      "Tu peux définir un Opening Move™ à envoyer lorsque tu matches avec d'autres utilisateurs. Il te suffit de te rendre dans les Opening Moves™ sur ton profil, de choisir l'une des questions pré-écrites ou de laisser libre cours à ta créativité et écrire la tienne. Tu peux modifier tes Opening Moves™ quand tu le souhaites.",
      "Essayer les Opening Moves™",
      "Comment utiliser les Opening Moves™ sur Bumble",
      "<a href={{see_more_url}}>Voir plus</a>",
      "Tu veux faire une pause? Essaie le mode Invisible",
      "Oui, tu peux te rendre dans tes paramètres ou ta file d'attente et désactiver le mode Invisible quand tu veux.",
      "Puis-je désactiver le mode Invisible plus tôt que prévu?",
      "Va dans tes paramètres, appuie sur le mode Invisible, et choisis la durée pendant laquelle tu souhaites t'absenter. Reviens quand ce sera le bon moment.",
      "Comment puis-je utiliser le mode Invisible sur mon profil?",
      "Non. Tant que tu es en mode Invisible, les fonctionnalités de balayage et de clavardage sont sur pause. Si tu vas dans ta file d'attente, tu verras un message indiquant la durée du mode Invisible, avec une option pour le désactiver.",
      "Puis-je balayer et discuter avec le mode Invisible activé?",
      "Les personnes avec lesquelles tu as déjà discuté verront ton statut d'absence (si tu choisis d'en définir un). Sinon, elles verront tes messages précédents comme d'habitude.",
      "Que verront mes matchs existants lorsque je serai en mode Invisible?",
      "Tu as le contrôle total sur ton activité sur Bumble, et tu peux t'éloigner de la plateforme quand tu le souhaites.",
      "Tu peux mettre au courant tes connexions existantes en mettant ton statut à jour, pour ne pas donner l'impression que tu <a href={{ghosted_article_url}}>les ignores</a>. De plus, tout est automatisé : tu n'as donc pas besoin d'envoyer de messages.",
      "Fais une pause vis-à-vis des rencontres sans perdre tes connexions ou tes discussions.",
      "Fais des rencontres (ou fais une pause) à ton rythme",
      "Tiens tes connexions au courant",
      "Mets ton activité sur pause, mais pas tes connexions",
      "Pourquoi nous aimons le mode Invisible",
      "Ouvre l'application Bumble, rends-toi sur l'onglet de ton profil, et appuie sur la roue dentée en haut à droite. Dans tes paramètres, appuie sur le mode Invisible et choisis la durée de ton absence. Tu peux masquer ton profil pendant 24 heures, 72 heures, une semaine ou jusqu'à nouvel ordre.{{__EOL__}}{{__EOL__}}Les matchs sans discussion active expireront lorsque tu seras en mode Invisible. Si tu définis un statut, les personnes avec lesquelles tu discutes actuellement pourront voir que tu prends une pause.",
      "Comment fonctionne le mode Invisible sur Bumble",
      "Lorsque le temps te manque, le mode Invisible est là pour t'aider. Mets ton compte Bumble sur pause, et fais des rencontres à ton rythme.",
      "Le mode Invisible sur Bumble",
      "Le mode Invisible te permet de mettre facilement ton compte sur pause lorsque tu souhaites prendre de la distance vis-à-vis des rencontres. Tu peux toujours discuter avec tes connexions existantes; il masque simplement ton profil aux autres utilisateurs jusqu'à ce que tu aies envie de recommencer à balayer. Tu ne perdras pas tes informations de profil ni tes connexions existantes en activant le mode Invisible.",
      "Mets ton profil Bumble sur pause",
      "Tant que tu es en mode Invisible, tu peux choisir d'informer tes connexions existantes que tu fais une pause avec une mise à jour de ton statut. Sélectionne « Je suis en voyage », « Je me concentre sur mon travail », « Je suis en désintox numérique », ou « Je prends du temps pour moi ». Quelle que soit ton humeur, nous sommes là pour toi.",
      "Ajoute un statut d'absence",
      "Activer le mode Invisible met ton compte Bumble (ou {{page_partner_name_bff}}) sur pause. Tes matchs ne sauront pas que tu es en mode Invisible à moins que tu ne leur dises. Si tu décides de désactiver le mode Invisible plus tôt que la durée que tu avais initialement choisie, il te suffit de retourner dans les paramètres et d'appuyer sur « Désactiver le mode Invisible ». C'est aussi simple que ça.",
      "Mets tes convos en pause",
      "Essaie SuperSwipe sur Bumble",
      "Autant que tu le souhaites! Les utilisateurs {{service_level_lexeme_name}} obtiennent {{num}} [[superswipe:num]] chaque semaine, mais tu peux recharger ton solde à tout moment. Les utilisateurs non abonnés peuvent acheter des lots de SuperSwipes.",
      "Combien de SuperSwipes puis-je envoyer sur Bumble?",
      "Tout de suite après qu'une personne a utilisé un SuperSwipe sur ton profil, tu recevras une notification. Tu verras également une étiquette au-dessus du nom de cette personne sur son profil, ce qui indique qu'elle t'a donné un J'aime.",
      "Comment savoir si je reçois un SuperSwipe de quelqu'un?",
      "Quelqu'un t'intéresse vraiment? Il y a une façon géniale de briser la glace. Envoie un SuperSwipe pour manifester ton intérêt et passe à la discussion plus rapidement.",
      "SuperSwipe sur Bumble",
      "Il n’est pas toujours facile de sortir du lot, mais SuperSwipe peut t'aider à faire une excellente première impression.{{__EOL__}}{{__EOL__}}Lorsque tu utilises un SuperSwipe, une notification sera envoyée à la personne à qui tu as donné un J'aime. Tu passeras ensuite en tête de sa file d’attente avec une étiquette sur ton profil. Si tu reçois un J'aime en retour, il est temps de commencer à discuter.",
      "Qu'est-ce qu'un SuperSwipe?",
      "Sortir du lot",
      "Entamer plus de discussions",
      "Nouer davantage de liens",
      "SuperSwipe peut t'aider à…",
      "Si tu as activé <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> ou Bumble {{service_level_lexeme_name_one}}, tu recevras {{num}} [[superswipe:num]] gratuitement par semaine. De plus, tous les membres peuvent acheter des lots de jusqu'à {{num_two}} [[superswipe:num_two]] depuis l'onglet du profil. Si tu as activé {{service_level_lexeme_name_three}}, tu recevras {{num_three}} [[superswipe:num_three]] par semaine.{{__EOL__}}{{__EOL__}}L'utilisation de SuperSwipe est simple : lorsque tu vois quelqu'un qui te plaît, appuie simplement sur l'icône de l'étoile jaune en bas de son profil.",
      "Comment utiliser SuperSwipe",
      "Apple et le logo Apple sont des marques déposées d'Apple Inc. Google Play est une marque de commerce de Google LLC.",
      "Trouve un match en déplacement grâce au mode Voyage sur Bumble",
      "<a href={{bumble_premium_url}}>Les membres {{service_level_lexeme_name}}</a> peuvent utiliser le mode Voyage. Active-le et ta file d'attente affichera de nouveaux visages dans la ville que tu as choisie.",
      "Comment utiliser le mode Voyage?",
      "Presque. Nous avons quelques restrictions de sécurité qui peuvent changer avec le temps, mais le mode Voyage est disponible pour la plupart des destinations.",
      "Puis-je utiliser le mode Voyage n'importe où?",
      "Le mode Voyage affichera ta position au centre de la ville choisie, de sorte que les nouvelles connexions dans la région puissent te trouver. Elles verront une note sur ton profil indiquant que tu es en mode Voyage, afin qu'elles sachent que tu visites ou que tu prévois de visiter.",
      "Tu prépares tes bagages pour les vacances ou tu te rends dans une nouvelle ville pour le travail? Active le mode Voyage sur Bumble pour faire des rencontres en chemin.",
      "Le mode Voyage sur Bumble",
      "Le mode Voyage est ton outil ultime pour établir des connexions lorsque tu es en déplacement. Définis ta position n'importe où dans le monde, et ton profil indiquera que tu te trouves dans la ville que tu as choisie. Cela signifie que tu peux vivre ton expérience de rencontres partout où tes aventures te guident.",
      "Qu'est-ce que le mode Voyage?",
      "Personne ne connaît mieux un endroit que ses propres habitants. Le mode Voyage peut t'aider à entrer en contact avec des gens qui rendront ton séjour encore plus spécial.",
      "Définis ta position sur la ville jusqu'à sept jours avant le voyage, afin que tu puisses planifier des rendez-vous et rencontrer des matchs en avance.",
      "Pense au-delà des simples séjours à la plage... Tu pourrais être de retour chez toi pour les Fêtes, voyager pour le travail ou explorer une nouvelle ville. Trouve l'amour où que tu atterrisses.",
      "Trouve les meilleurs endroits du coin pour un rendez-vous",
      "Pars à l'aventure",
      "Crée des relations qui durent",
      "Raisons d'aimer le mode Voyage",
      "Le mode Voyage est disponible pour les membres <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} et {{service_level_lexeme_name_two}}</a> sur Bumble. Appuie sur l'engrenage des paramètres en haut à droite de ton profil et fais défiler jusqu'à ce que tu vois le mode Voyage. Choisis ta nouvelle position pour commencer à voir des personnes dans cette zone. Ton profil indiquera aux matchs potentiels que tu es en mode Voyage.",
      "Comment fonctionne le mode Voyage?",
      "Essayer",
      "Essaie la version Web de Bumble",
      "Absolument. L'application Web de Bumble fonctionne sur les PC, les Mac et les tablettes sur tous les principaux navigateurs Web.",
      "Puis-je utiliser Bumble sur mon PC ou mon Mac?",
      "Oui, tu peux. Tu peux envoyer des messages ou même passer un appel vidéo ou audio sur l'appli Web de Bumble.",
      "Puis-je discuter avec des matchs sur l'application Web?",
      "Pour te connecter, choisis « Se connecter » ou clique <a href={{sign_in_url}}>ici</a>. Tu peux te connecter en utilisant ton identifiant Apple, Facebook ou ton numéro de téléphone.",
      "Comment puis-je me connecter à la version Web de Bumble?",
      "Envie de lâcher ton téléphone? Essaie l'application Web de Bumble; la même expérience Bumble, mais sur un plus grand écran.",
      "Utilise la version Web de Bumble",
      "Se connecter sur la version Web de Bumble est simple. Il suffit de visiter notre site Web, de cliquer sur « Se connecter » et de choisir ta méthode de connexion habituelle avec ton identifiant Apple, tes informations Facebook ou ton numéro de téléphone. Et voilà! Tu es en ligne et tes discussions et matchs sont exactement là où tu les as laissés.",
      "Connexion",
      "Connecte-toi via ton navigateur",
      "Modifie ton profil et applique des filtres avancés.",
      "Confirme que c'est bien toi pour augmenter tes chances de trouver le match idéal.",
      "Partage de nouvelles photos mignonnes ou supprime les plus anciennes.",
      "Mettre à jour ton profil",
      "Montrer que tu es vraiment toi",
      "Ajouter ou supprimer des photos",
      "Ce que tu peux faire sur la version Web de Bumble",
      "Tu trouveras tes discussions et ta liste de matchs sur ta gauche, et des matchs potentiels sur ta droite.{{__EOL__}}{{__EOL__}}Utilise tes flèches gauche et droite, ou clique sur les symboles sur le côté droit de ton écran pour consulter les profils et donner un J'aime aux personnes comme tu le ferais sur ton téléphone.",
      "Comment utiliser la version Web de Bumble?",
      "Ici, la gentillesse est sexy, être soi-même est parfait, et les femmes passent avant tout. Toujours.",
      "Le sanctuaire de Fais le premier pas™",
      "Des relations saines contribuent à une vie productive et positive. Bumble est une plateforme sociale qui te permet d'en établir de la manière la plus gratifiante qui soit, qu'il s'agisse de rencontres amoureuses, d'amitié ou d'opportunités de carrière.",
      "Pourquoi Bumble fait toute la différence",
      "Nous prônons l'intégrité, la gentillesse, l'égalité, la confiance et le respect à tous les stades d'une relation. Nous célébrons toutes les orientations, que tu sois ici pour trouver l'amour ou simplement pour t'amuser.",
      "Les valeurs de Bumble",
      "Sur Bumble, ce sont les femmes qui donnent le ton en faisant le premier pas ou en proposant un premier pas auquel leurs matchs peuvent répondre, changeant ainsi les conventions sociales dépassées et promouvant l'égalité dès le début.",
      "Comment Bumble fonctionne",
      "À propos de nous",
      "Télécharger",
      "C'est mieux dans l'appli.",
      "Des options conçues pour rendre les rencontres en ligne vraiment agréables",
      "12 février 2024",
      "Comment accroître l'intimité émotionnelle dans une relation",
      "25 janvier 2024",
      "Comment utiliser Bumble quand on n'a jamais utilisé d'appli de rencontre",
      "5 février 2024",
      "Deception Detector™",
      "Toute l'actualité du monde des rencontres",
      "Si la taille compte, rends-toi sur notre site Web. Il est comme notre appli, mais en plus grand.",
      "Application Web",
      "Prends un moment pour toi avec le <b>mode Invisible</b>. Quand tu voudras revenir, tu sais où nous trouver.",
      "Fais une pause",
      "Utilise les <b>appels vidéo</b> et les <b>appels audio</b> pour te rapprocher sans quitter la maison.",
      "Dis-le à voix haute",
      "Afficher plus",
      "Choisis des <b>Opening Moves™</b> pour inviter ton match à lancer la discussion et t'éviter d'avoir à le faire.",
      "Moins d'efforts, plus de rencontres",
      "Trouve ton bonheur n'importe où dans le monde grâce au <b>mode Voyage</b>.",
      "Où allons-nous?",
      "Son profil t'intéresse? Fais-lui savoir en lui envoyant un <b>SuperSwipe</b>.",
      "Attire son attention",
      "Envoie des <b>Compliments</b> pour te démarquer des autres.",
      "Donne le sourire",
      "Utilise le <b>mode Incognito</b> pour cacher ton profil à ton patron, tes proches ou tes ex.",
      "En mode furtif",
      "Fais des rencontres à ta façon",
      "Quelques mois plus tard, elle y a également rencontré une de ses demoiselles d'honneur.",
      "Hannah a rencontré son mari sur Bumble.",
      "Avant de construire leur petite maison, Carène et You-Liang se sont rencontrés sur Bumble, où You-Liang a été attiré par les photos de voyage de Carène.",
      "Carène et You-Liang",
      "Brian et Kaci étaient en poste à l'étranger ensemble, mais ils se sont rencontrés sur Bumble.",
      "Brian et Kaci",
      "Si ça a marché pour eux, ça peut marcher pour toi",
      "Passe à l'étape suivante",
      "Voir les nouveautés",
      "Nous proposons d'excellents moyens pour te permettre de faire des rencontres sans stress.",
      "Mets la barre haut",
      "À propos de Bumble",
      "Nous sommes la seule appli qui améliore les rencontres en donnant la priorité à l'expérience des femmes. Parce que quand ça va mieux pour les femmes, ça va mieux pour tout le monde.",
      "Make the first move™",
      "Trouve quelqu'un qui te plaît, puis fais le premier pas. Crée des liens authentiques, discute de vos passions communes, et surtout, amuse-toi bien.",
      "Rencontre quelqu'un avec Bumble Date",
      "Trouve des amis pour t'aider à faire progresser ta carrière. Entre en contact avec d'autres professionnels pour trouver ton prochain emploi, un mentor ou même une nouvelle carrière.",
      "Fais avancer ta carrière avec Bumble Bizz",
      "Rencontre quelqu'un qui te ressemble. Ou pas. Fais-toi des amis et trouvez des activités à faire ensemble, dans ta ville ou à l'étranger.",
      "Fais-toi des amis sur Bumble For Friends",
      "Pas juste pour trouver l'amour",
      "Bumble est l'appli de rencontre où les femmes font le premier pas. C'est l'appli qui a transformé les relations, les amitiés et le réseautage. Télécharge-la maintenant.",
      "Bumble sur {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "Nous n'avons pas pu trouver la page que tu cherches.",
      "Nous contacter",
      "Connexion",
      "Une erreur est survenue",
      "(ouvre une nouvelle fenêtre)",
      "FAQ",
      "Politique des cookies",
      "Premium",
      "Bumble - Le Blog",
      "Emploi",
      "Chat",
      "Villes",
      "Gérer les cookies",
      "Dépôt légal © 2006 - aujourd'hui. Bumble. Tous droits réservés.",
      "Rapport sur la confiance dans les rencontres",
      "Apple et le logo Apple sont des marques déposées d'Apple Inc.{{__EOL__}}Google Play est une marque déposée de Google LLC.",
      "Télécharger",
      "Facebook",
      "Pour nous suivre :",
      "Centre d'aide",
      "Instagram",
      "Langue",
      "LinkedIn",
      "Bumble fait partie de Bumble Inc.",
      "Politique de Confidentialité",
      "Safety Centre",
      "Plan du site",
      "Loi sur l'esclavage moderne",
      "Conditions Générales d'Utilisation",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "plural": [
                  "compliments"
            ],
            "singular": [
                  "compliment"
            ]
      },
      "day": {
            "plural": [
                  "jours"
            ],
            "singular": [
                  "jour"
            ]
      },
      "hour": {
            "plural": [
                  "heures"
            ],
            "singular": [
                  "heure"
            ]
      },
      "minute": {
            "plural": [
                  "minutes"
            ],
            "singular": [
                  "minute"
            ]
      },
      "month": {
            "plural": [
                  "mois"
            ],
            "singular": [
                  "mois"
            ]
      },
      "second": {
            "plural": [
                  "secondes"
            ],
            "singular": [
                  "seconde"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipes"
            ],
            "singular": [
                  "SuperSwipe"
            ]
      },
      "year": {
            "plural": [
                  "ans"
            ],
            "singular": [
                  "an"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
