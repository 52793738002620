/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Tilbage",
      "Download på App Store",
      "Få den på Google Play",
      "Karrusel",
      "Slide {{number}}",
      "Næste slide",
      "Forrige slide",
      "Slide",
      "Slide {{number}} af {{total}}",
      "Sælg eller del ikke mine personlige oplysninger",
      "Sælg eller del ikke mine personlige informationer",
      "Brug Compliments på Bumble for at vise, at du synes godt om deres profil, og fang deres opmærksomhed før I matcher. Prøv det nu",
      "Compliments | Sådan sender du et kompliment | Bumble",
      "En personlig side, der fremhæver de personer, som du har mest tilfælles med, opdateret dagligt.",
      "Udforsk | Find kompatible personer | Bumble",
      "Dato i dit eget tempo, og hav kontrol over, hvem der ser din Bumble-profil med Inkognito-tilstand. Lær mere om at bruge Inkognito-tilstand på Bumble i vores guide",
      "Inkognito-tilstand | Skjul din profil | Bumble",
      "Efter at have mødt hinanden til et fysisk Bumble-event, kan I bygge videre på den gode kemi i en samtale i appen.",
      "Match øjeblikkeligt | Hold samtalen kørende i appen | Bumble",
      "Prøv en ny måde at starte samtalen med Bumbles Opening Moves-funktion. Fang deres opmærksomhed, skab den gode vibe, og kom hurtigere til de gode samtaler.",
      "Bumble Opening Moves | Bumble",
      "Har du brug for en pause? Find ud af, hvordan du sætter din Bumble-konto på pause med Bumbles Snooze-tilstand. Den giver dig mulighed for at date på din måde",
      "Snooze-tilstand | Sæt din konto på pause | Bumble",
      "Har du lige set en, du virkelig kan lide på Bumble? Brug et SuperSwipe for at vise dem, at du er interesseret. Tryk på stjernen på deres profil for at begynde, eller se flere oplysninger her",
      "Bumble SuperSwipe | Hvad er et SuperSwipe? | Bumble",
      "Skal du rejse på grund af arbejde eller fornøjelse? Rejse-tilstand på Bumble kan hjælpe dig med at få spændende nye forbindelser et nyt sted, så du kan date hvor som helst. Udforsk Rejse-tilstand her",
      "Rejse-tilstand på Bumble | Date hvor som helst | Bumble",
      "Vil du finde ud af, om jeres kemi i beskederne også kan overføres til den virkelige verden? Prøv en videochat eller et stemmeopkald på Bumble nu, og bliv klogere på jeres vibe",
      "Videochat | Lav et opkald | Bumble",
      "Oplev spændingen fra Bumble Web, den ultimative funktion.",
      "Bumble Web | Bumble",
      "Opsig kontrakter her",
      "Bumble startside",
      "Luk menu",
      "Åbn menu",
      "Sprogvalg",
      "Footer",
      "Adgang til Bumble",
      "Apple og Apple-logoet er varemærker tilhørende Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Jobmuligheder",
      "Begivenheder",
      "Google Play er et varemærke tilhørende Google LLC.",
      "Retningslinjer",
      "Investorer",
      "Administrer cookies",
      "Administrer cookies",
      "Modern Slavery Act Statement",
      "Meddelelse ved indsamling",
      "Privatlivspolitik",
      "Sæsonbestemte datingguides",
      "Vilkår & betingelser",
      "Appen",
      "Privatlivspolitik for Consumer Health Data i Washington",
      "Om",
      "Ambassadører",
      "Blog",
      "Få nye venner, og find et fællesskab",
      "Skab netværk, og gør fremskridt i din karriere",
      "Find et forhold, noget uforpligtende eller noget derimellem",
      "Se mere",
      "Bliv global med Rejse-tilstand",
      "Funktioner",
      "Compliments",
      "Send Compliments til dem for at gøre dig ekstra bemærket.",
      "Udforsk",
      "En personlig side, der fremhæver de personer, som du har mest tilfælles med, opdateret dagligt.",
      "Inkognito-tilstand",
      "Brug Inkognito-tilstand for at skjule din profil for din chef, familie eller ekskærester.",
      "Match øjeblikkeligt",
      "Fik I gang i samtalen til et fysisk Bumble-event? Fortsæt snakken på appen.",
      "Opening Moves",
      "Vælg Opening Moves for at invitere dit match til at starte chatten, så du ikke behøver at gøre det.",
      "Snooze-tilstand",
      "Sæt dating på pause med Snooze-tilstand. Når du er klar til at date igen, ved du, hvor vi er.",
      "SuperSwipe",
      "Var du vild med profilen? Fortæl dem det med et SuperSwipe.",
      "Rejse-tilstand",
      "Find din rejsesnack, og match over hele verden med Rejse-tilstand.",
      "Videochat",
      "Brug videochat og audioopkald for at se, om I er klar til at mødes i den virkelige verden.",
      "Webapp",
      "Få Bumble-oplevelsen direkte fra din browser.",
      "Shoppen",
      "Primære",
      "Spring over til primært indhold",
      "Bumble-logo",
      "Vil du lære personen at kende? Brug videochat på Bumble",
      "Tryk blot på videoikonet i din chat for at starte et videoopkald – helt uden at dele nogen personlige kontaktoplysninger.",
      "Hvordan fungerer videochat på Bumble?",
      "Nej. Alle opkald og chats på Bumble holder dine personlige oplysninger sikre. Ingen matches vil få dit nummer eller andre kontaktoplysninger, før du selv deler dem.",
      "Vil mit match kunne se mit telefonnummer?",
      "Helt bestemt! Hvis du ikke er klar til at mødes ansigt til ansigt, kan I mødes via audioopkald. Vælg blot ringeikonet i stedet for.",
      "Kan jeg nøjes med et audioopkald?",
      "Vi elsker at få en notifikation om en *modtaget besked*. Men det kan være svært at vide, om den gode kemi fra en chat kan overføres til virkeligheden. Det er her video- og audioopkald kommer ind i billedet.",
      "Videochat på Bumble",
      "<a href={{video_chat_url}}>Videochats og audioopkald</a> kan hjælpe jer med at lære hinanden at kende og finde ud af, om I vil mødes personligt. Det bedste ved det hele? Når I ringer sammen igennem Bumble, behøver I ikke at udveksle telefonnumre, og jeres personlige oplysninger forbliver sikre, indtil I er klar til at dele dem.",
      "Tjek jeres kemi med en videochat",
      "Byg bro mellem online- og offline-dating, og beslut, om det er tid til at mødes i virkeligheden.",
      "Lær dem bedre (og hurtigere) at kende. En samtale ansigt til ansigt på telefonen kan afsløre meget om en person.",
      "Du har kontrollen. Vi holder dine kontaktoplysninger private, så du kan vælge, hvornår du vil dele dem.",
      "Se, om du er klar til at mødes personligt",
      "Tjek hurtigt stemningen",
      "Skab forbindelse uden at dele dine personlige oplysninger",
      "Derfor er videochats og -opkald en god idé",
      "Klar til at starte en samtale? Du kan vælge, om du vil starte et audioopkald eller et videoopkald direkte fra din chat i Bumble-appen. For at starte et videoopkald skal du trykke på det grå videoikon i øverste højre hjørne. Du vil også se tre prikker ved siden af ikonet, og når du klikker på dem, vil du også se muligheden for at lave et audioopkald.",
      "Sådan starter du en videochat",
      "Klar til at prøve Compliments?",
      "Når du sender et kompliment, vil personen, der modtager det, se det, når du dukker op i deres kø.",
      "Hvad sker der, når du sender et kompliment til en person på Bumble?",
      "Hvis komplimentet fanger din opmærksomhed, kan du gøre afsenderen til et match. Du kan se komplimentet i jeres chat, og de sædvanlige regler for at <a href={{make_the_first_move_url}}>tage det første skridt</a> gælder stadig.",
      "Hvordan svarer jeg på et kompliment?",
      "Du kan sende {{num}} [[Compliments:num]] gratis. Når du løber tør, kan du købe flere i Bumble-appen i pakker på op til 30. En af disse vil blive brugt til at sende komplimentet (gem resten til du ser en anden profil, du finder interessant). Bare rolig, de udløber ikke!",
      "Hvor mange komplimenter kan jeg sende?",
      "Man kan komme langt med et venligt ord (eller to). Hvis du vil sende et kompliment, men ikke ved, hvad du skal skrive, så kan du blive inspireret af disse:",
      "Se flere eksempler",
      "\"Alle, der elsker [kunstnerens navn], har god smag.\"",
      "\"Dit smil er smittende!\"",
      "\"Din bio fik mig til at grine. Godt lavet.\"",
      "Sådan sender du bedre komplimenter på Bumble",
      "Vil du gøre nogens dag bedre? Det lyder til, at du er klar til at bruge Compliments.",
      "Compliments på Bumble",
      "Brug Compliments på Bumble for at fortælle et potentielt match, at du kan lide deres profil (og få dem til at rødme!). Du får Compliments som en del af dit {{service_level_lexeme_name}}-medlemskab. Det gør det også muligt at sende og modtage beskeder, før I matcher.",
      "Sådan fungerer Compliments",
      "Når du modtager et kompliment, vil det blive vist på afsenderens profil. Du får også en notifikation, der fortæller dig, at nogen er vild med noget fra din profil.",
      "Se dine komplimenter",
      "Du kan sende et kompliment ved at trykke på det gule hjerteikon nederst på en persons profil. Det er vigtigt, hvor du præcist trykker, fordi du sender et kompliment til det specifikke billede eller den specifikke del af profilen. Når du klikker, åbnes en tekstboks, så du kan skrive din besked (den kan være på maks. 150 tegn).",
      "Send et kompliment",
      "DOWNLOAD Bumble FOR AT PRØVE UDFORSK",
      "Hver dag fra kl. 9 kan du se nye personer i Udforsk. Du kan skabe kontakt til dem ud fra de ting, I har tilfælles.",
      "Hvornår bliver Udforsk opdateret?",
      "I Udforsk bliver potentielle matches opdelt i 3 hovedkategorier: lignende interesser, samme datingmål og delte fællesskaber. Profilanbefalinger bliver også vist baseret på dine tidligere matches.",
      "Hvordan bliver folk i Udforsk udvalgt?",
      "En personlig side, der fremhæver de personer, som du har mest tilfælles med, opdateret dagligt",
      "FIND PERSONER, SOM MATCHER DIN ENERGI MED {{qr_code}} UDFORSK",
      "Udforsk viser dig et udvalg af personer, som vi tror, du vil synes om – baseret på fælles interesser, datingmål og fællesskaber.",
      "Prøv Udforsk",
      "SØGER DU EN NY MÅDE AT FINDE ET FÆLLES UDGANGSPUNKT PÅ?",
      "Udforsk bliver opdateret hver dag, så du ser endnu flere personer, du passer godt med — det betyder mindre tid på at søge efter et match og mere tid på at komme tættere på de personer, du har god kemi med.",
      "ANBEFALINGER OPDATERES DAGLIGT",
      "Anbefalingerne i Udforsk er baseret på din profil, og hvem du har matchet med før, så du vil se personer, der kan lide de samme ting som dig og er på udkig efter det samme – uanset om det er opskrifter med plantefars eller et seriøst forhold.",
      "PERSONLIGT TILPASSET{{__EOL__}}TIL DIG",
      "Download Bumble",
      "Ofte stillede spørgsmål",
      "Klar til at browse Bumble anonymt?",
      "Når du aktiverer Inkognito-tilstand, vil du ikke blive vist til andre Bumble-medlemmer, medmindre du giver deres profil et like.",
      "Hvordan fungerer Inkognito-tilstand?",
      "Uden Inkognito-tilstand er din profil synlig for andre på Bumble, når du passer til deres præferencer som alder og lokation. Når du aktiverer Inkognito-tilstand, vil du kun være synlig for personer, når du har givet dem et like.",
      "Hvem kan se mig i Bumbles Inkognito-tilstand?",
      "Brug af Inkognito-tilstand er ikke det samme som at <a href={{blocking_on_bumble_url}}>blokere nogen på Bumble</a>. Med denne funktion kan personer stadig se din profil, hvis du har givet dem et like, eller hvis du har skrevet til dem tidligere.",
      "Er Inkognito-tilstand det samme som at blokere nogen?",
      "Få kontrol over, hvem der ser din profil med Inkognito-tilstand på Bumble.",
      "Inkognito-tilstand på Bumble",
      "Hvis du vil tage en pause fra dating, eller browse Bumble uden at andre kan se din profil, kan du skifte til Inkognito-tilstand. Når du slår denne funktion til, betyder det, at vi skjuler dig fra alle – medmindre du har givet dem et like. Det gør, at du kan tage kontrol over, hvad du deler, og hvem der kan se dig.",
      "Skjul din profil med Inkognito-tilstand",
      "Du bestemmer, hvem der kan se dine billeder og personlige oplysninger.",
      "Vil du dele noget personligt på din profil? Du kan diskret dele, hvad du vil, med dine nuværende matches.",
      "Browse uden at være bekymret for at ramme ind i din ex, en kollega eller andre, der ikke er på din dating-radar.",
      "Tag fuld kontrol",
      "Vælg, hvad du vil dele",
      "Undgå akavede øjeblikke",
      "Derfor vil du elske Inkognito-tilstand",
      "Inkognito-tilstand er tilgængelig for medlemmer med {{service_level_lexeme_name}} og {{service_level_lexeme_name_two}}. Når du har tilmeldt dig, kan du slå det til ved at gå til din profilfane, trykke på indstillinger i øverste højre hjørne og skifte til Inkognito-tilstand.",
      "Opgrader til {{service_level_lexeme_name}}",
      "Sådan bruger du Inkognito-tilstand på Bumble",
      "DOWNLOAD Bumble FOR AT PRØVE \"MATCH ØJEBLIKKELIGT\" I DAG",
      "I Bumble-appen skal du gå til profilikonet nederst på skærmen. Herfra finder du QR-kodeikonet øverst til højre på skærmen. Tryk for at få adgang til den.",
      "Hvor kan jeg finde min personlige QR-kode?",
      "Nej! Du skal være på wi-fi og bruge den aktuelle QR-livekode for, at Match øjeblikkeligt virker.",
      "Kan jeg dele min QR-kode med et skærmbillede?",
      "Nej! Hvis en person scanner koden, vil I blive matchet automatisk.",
      "Skal begge personer scanne QR-koden?",
      "Med Match øjeblikkeligt kan du automatisk se, hvad du og dit nye match har tilfælles – og få et indblik i, hvordan I passer sammen med det samme.",
      "FIND ET FÆLLES UDGANGSPUNKT, HURTIGERE",
      "Fik I gang i samtalen til et fysisk Bumble-event? Fortsæt snakken på appen.",
      "ET HYGGELIGT MØDE FORVANDLES TIL ET {{qr_code}} ØJEBLIKKELIGT MATCH",
      "Match øjeblikkeligt gør det nemt at fortsætte samtalen med en sød person, du lige har mødt – uden at udveksle numre eller sociale profiler. Når vedkommende scanner din QR-kode, matcher I på Bumble.",
      "Prøv Match øjeblikkeligt",
      "HAR DU MØDT NOGEN TIL ET FYSISK Bumble-EVENT?",
      "Frem for at udveksle sociale profiler eller telefonnumre, gør Match øjeblikkeligt det muligt at undersøge kemien i appen først. På den måde kan I bedre lære hinanden at kende på jeres måde, takket være Bumbles række af sikkerhedsfunktioner.",
      "KONTAKT PÅ EN MERE SIKKER MÅDE",
      "Efter at have mødt hinanden til et fysisk Bumble-event, kan I bygge videre på kemien ved at fortsætte samtalen i appen og tale om fælles interesser, fx den perfekte kop kaffe eller en sjælden plante.",
      "HOLD SAMTALEN KØRENDE",
      "Prøv Opening Moves på Bumble",
      "Opening Moves er en anden måde at chatte med personer, du kan lide. Når du har valgt en besked, kan du slappe af, vente på svar og svare de personer, der virkelig skiller sig ud.",
      "Sådan bruger du Opening Moves på Bumble",
      "I nonbinære forbindelser og forbindelser med samme køn kan begge personer indstille og svare på et Opening Move.",
      "Kan jeg bruge Opening Moves på Bumble til at date personer af samme køn?",
      "Når nogen besvarer dit Opening Move, vil du se deres profil dukke op i dine chats. Kvinder har 24 timer til at svare, før matchet udløber. Herefter kan du enten svare på deres besked eller starte en ny samtale.",
      "Hvordan kan jeg se, om nogen har svaret på mine Opening Moves?",
      "Opening Moves er vores måde at hjælpe dig med at tage det første skridt – med en nemmere og hurtigere måde at starte en god samtale på.",
      "Opening Moves på Bumble",
      "Vi er glade for, at du spurgte. Opening Moves er en ny og nemmere måde at starte en samtale på. Du kan vælge et foreslået Opening Move, fx: \"Hvad er din yndlingssang til en fest?\", eller du kan skrive dit eget. Hvis du har indstillet et, kan dine matches svare på dine spørgsmål, når du dukker op i deres kø.",
      "Hvad er et Opening Move?",
      "Fjern noget af presset",
      "Del, hvad der gør dig unik",
      "Start bedre samtaler",
      "Opening Moves hjælper dig",
      "Du kan indstille et Opening Move, der sendes ud, når du matcher med andre. Gå til Opening Moves på din profil, vælg et af de færdiglavede spørgsmål, eller vær kreativ, og skriv dit eget. Du kan ændre dine Opening Moves når som helst.",
      "Prøv Opening Moves",
      "Sådan bruger du Opening Moves på Bumble",
      "<a href={{see_more_url}}>Se mere</a>",
      "Vil du have en pause? Prøv Snooze-tilstand",
      "Ja, du kan gå til dine indstillinger eller din kø, og slå Snooze fra på ethvert tidspunkt.",
      "Kan jeg deaktivere Snooze-tilstand tidligere?",
      "Gå til indstillinger, tryk på Snooze, og vælg, hvor længe du vil være væk. Kom tilbage, når du er klar.",
      "Hvordan bruger jeg Snooze-tilstand på min profil?",
      "Nej, i Snooze-tilstand er al swipe- og chataktivitet sat på pause. Hvis du vil se nye personer, vil du se en besked, der fortæller dig, hvor lang tid du stadig er i Snooze-tilstand, og der vil være en mulighed for at deaktivere den.",
      "Kan jeg stadig swipe og chatte i Snooze-tilstand?",
      "De personer, som du allerede har skrevet med, vil kunne se din \"Væk\"-status (hvis du indstiller den). Ellers vil de kunne se jeres tidligere beskeder i indbakken som normalt.",
      "Hvad vil nuværende matches se, når jeg er i Snooze-tilstand?",
      "Du har total kontrol over din Bumble-aktivitet og kan trække dig tilbage fra platformen, når du har lyst til det.",
      "Du kan opdatere eksisterende forbindelser ved at bruge din statusopdatering, så det ikke ligner, at du <a href={{ghosted_article_url}}>ghoster dem</a>. Derudover er det hele automatiseret – så du ikke behøver at sende nogen beskeder.",
      "Tag en pause fra aktiv dating uden at miste dine forbindelser eller chats.",
      "Date (eller afbryd forbindelsen) på din måde",
      "Hold dine forbindelser opdateret",
      "Sæt din aktivitet på pause – ikke dine forbindelser",
      "Derfor elsker vi Snooze-tilstand",
      "Åbn Bumble-appen, gå til din profilfane, og tryk på indstillingshjulet øverst til højre. I indstillingerne trykker du på Snooze og vælger, hvor lang tid du vil være væk. Du kan skjule din profil i 24 timer, 72 timer, en uge eller uendeligt.{{__EOL__}}{{__EOL__}}Husk, at matches uden aktive chats udløber, når du er i Snooze-tilstand. Hvis du beslutter dig for at sætte en status, kan personer, som du chatter med i øjeblikket, se, at du er væk.",
      "Sådan fungerer Snooze-tilstand på Bumble",
      "Når livet bliver hektisk, kan Snooze-tilstand være en hjælp. Sæt din Bumble-konto på pause, og skab dine egne datingregler.",
      "Snooze-tilstand på Bumble",
      "Snooze-tilstand er en nem måde at sætte din konto på pause på, når du har brug for at trække dig lidt væk fra datingverdenen. Du kan fortsat chatte med dine eksisterende forbindelser, men din profil bliver skjult for andre, indtil du er klar til at swipe igen. Du mister ikke dine profiloplysninger eller sletter eksisterende forbindelser ved at aktivere Snooze-tilstand.",
      "Snooze din Bumble-profil",
      "I Snooze-tilstand kan du vælge at give eksisterende forbindelser besked om, at du tager en pause, ved at opdatere din status. Vælg mellem \"Jeg er ude at rejse\", \"Jeg er optaget af arbejde\", \"Jeg er på en digital detox\" eller \"Jeg prioriterer mig selv\". Du kan vælge den status, der passer bedst til din vibe.",
      "Tilføj en \"Væk\"-status",
      "Når du aktiverer Snooze-tilstand, sætter du din Bumble-konto (eller {{page_partner_name_bff}}-konto) på pause. Dine matches ved ikke, at du er i Snooze-tilstand, medmindre du fortæller dem det. Hvis du beslutter dig for at vågne op fra din Snooze-tilstand tidligere end forventet, så trykker du bare på \"Deaktiver Snooze-tilstand\" i indstillingerne. Hvor nemt kan det være?",
      "Sæt dine chats på pause",
      "Prøv SuperSwipe på Bumble",
      "Så mange du vil! {{service_level_lexeme_name}}-brugere får {{num}} [[superswipe:num]] hver uge, men du kan fylde din saldo op på ethvert tidspunkt. Gratismedlemmer kan købe pakker af SuperSwipes.",
      "Hvor mange SuperSwipes kan jeg sende på Bumble?",
      "Når nogen bruger et SuperSwipe på din profil, får du straks besked. Du vil også se et label over personens navn på deres profil, så du kan se, at vedkommende kan lide dig.",
      "Hvordan ved jeg, om nogen gav mig et SuperSwipe?",
      "Er du virkelig interesseret i en? Der er en cool måde at bryde isen på: Send blot et SuperSwipe for at vise dem, at du er interesseret, og kom hurtigere til de gode samtaler!",
      "SuperSwipe på Bumble",
      "Det er ikke altid nemt at skille sig ud fra mængden, men SuperSwipe kan hjælpe dig med at gøre et godt førstehåndsindtryk.{{__EOL__}}{{__EOL__}}Når du bruger et SuperSwipe, bliver der sendt en notifikation til den pågældende person. Du kommer derefter forrest i køen hos personen og har et label på din profil. Hvis vedkommende også er interesseret i dig, er det tid til at starte en chat.",
      "Hvad er et SuperSwipe?",
      "Vær modig, og skil dig ud",
      "Start flere samtaler",
      "Skab flere forbindelser",
      "SuperSwipe hjælper dig",
      "Hvis du har <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> eller Bumble {{service_level_lexeme_name_one}}, får du {{num}} gratis [[superswipe:num]] om ugen. Derudover kan alle medlemmer købe pakker af op til {{num_two}} [[superswipe:num_two]] under profilfanen. Hvis du har {{service_level_lexeme_name_three}}, får du {{num_three}} [[superswipe:num_three]] om ugen.{{__EOL__}}{{__EOL__}}Det er nemt at bruge SuperSwipe – hvis du er interesseret i en person, trykker du bare på det gule stjerneikon nederst på vedkommendes profil.",
      "Sådan bruger du SuperSwipe",
      "Apple og Apple-logoet er varemærker tilhørende Apple Inc.\nGoogle Play er et varemærke tilhørende Google LLC.",
      "Date på farten med Rejse-tilstand på Bumble",
      "<a href={{bumble_premium_url}}>{{service_level_lexeme_name}}-medlemmer</a> kan benytte Rejse-tilstand. Slå den til, og få vist nye ansigter i din valgte by.",
      "Hvordan bruger jeg Rejse-tilstand?",
      "Stort set – vi har et par sikkerhedsbegrænsninger, der kan ændre sig med tiden, men Rejse-tilstand er tilgængelig for de fleste destinationer.",
      "Kan jeg bruge Rejse-tilstand overalt?",
      "Rejse-tilstand vil vise din lokation som centrum af den valgte by, så nye forbindelser i området kan finde dig. De vil se en note på din profil om, at du er i Rejse-tilstand, så de ved, at du er på besøg, eller at du planlægger et besøg.",
      "Pakker du kufferten til en ferie, eller skal du på en forretningsrejse til en ny by? Slå Rejse-tilstand til i Bumble for at tage dit datingliv med på farten.",
      "Rejse-tilstand på Bumble",
      "Rejse-tilstand er dit ultimative værktøj til at skabe forbindelser, mens du er væk. Indstil din lokation til hvor som helst i verden, og din profil vil blive vist i den by, du vælger. Det betyder, at du kan få adgang til datingverdenen, uanset hvor du tager på eventyr henne.",
      "Hvad er Rejse-tilstand?",
      "Ingen kender et sted bedre end de lokale. Rejse-tilstand kan hjælpe dig med at få forbindelse til de personer, der kan gøre din rejse ekstra speciel.",
      "Indstil din lokation til din rejsedestination op til 7 dage, før du rejser. På den måde kan du planlægge dates eller møde nye matches på forhånd.",
      "Tænk ud over badeferier – det kan være du besøger din hjemby til jul, rejser med dit arbejde eller udforsker en ny by. Find romance, uanset hvor du lander.",
      "Find de bedste lokale datesteder",
      "Tag på eventyr",
      "Skab forbindelser, der holder",
      "Årsager til at elske Rejse-tilstand",
      "Rejse-tilstand er tilgængelig for medlemmer på Bumble med <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} og {{service_level_lexeme_name_two}}</a>. Tryk på indstillingshjulet i øverste højre hjørne af din profil, og scroll ned, indtil du ser Rejse-tilstand. Vælg din nye lokation for at se personer i det område. Din profil vil give potentielle dates besked om, at du er i Rejse-tilstand.",
      "Hvordan fungerer Rejse-tilstand?",
      "Prøv det nu",
      "Prøv webudgaven af Bumble",
      "Selvfølgelig. Bumble-webappen fungerer på PC, Mac og tablets i alle de kendte webbrowsere.",
      "Kan jeg bruge Bumble på min PC eller Mac?",
      "Ja, du kan. Du kan sende beskeder eller endda lave et video- eller audioopkald på Bumble-webappen.",
      "Kan jeg chatte med matches på webappen?",
      "For at logge ind skal du vælge \"Log ind\" eller klikke <a href={{sign_in_url}}>her</a>. Du kan logge ind med dit Apple ID, din Facebook-konto eller dit telefonnummer.",
      "Hvordan logger jeg ind på webudgaven af Bumble?",
      "Har du brug for en pause fra din telefon? Prøv Bumble-webbappen – den samme Bumble-oplevelse, du kender og elsker, men på en større skærm.",
      "Brug webudgaven af Bumble",
      "Det er nemt at logge på webudgaven af Bumble. Gå blot til vores website, klik på \"Log ind\", og vælg din normale login-metode med dit Apple ID, dine Facebook-oplysninger eller dit telefonnummer. Sådan! Du er inde, og dine chats og forbindelser er lige der, hvor de plejer at være.",
      "Log ind",
      "Log ind via din browser",
      "Rediger eller lav ændringer på din profil, og tilføj Avancerede filtre.",
      "Bekræft, at du er dig, for at øge chancen for, at du finder et perfekt match.",
      "Del nye, skønne billeder, eller slet de gamle.",
      "Opdater din profil",
      "Vis andre, at du virkelig er dig",
      "Tilføj eller fjern billeder",
      "Dette kan du i webudgaven af Bumble",
      "Du kan finde dine chats og din liste over forbindelser i venstre side og potentielle nye matches i højre side.{{__EOL__}}{{__EOL__}}Brug piletasterne til venstre og højre, eller klik på symbolerne på højre side af skærmen, for at gennemse profiler og give et like til personer – på samme måde som på din telefon.",
      "Hvordan bruger jeg webudgaven af Bumble?",
      "Her er det sexet at være venlig, her er du perfekt på din måde, og her kommer kvinder først. Altid.",
      "Stedet at tage det første skridt",
      "Sunde relationer er helt essentielle for at leve et positivt og produktivt liv. Bumble er designet til at hjælpe dig med at føle dig godt tilpas, mens du skaber dine forbindelser, uanset om du dater, leder efter venner eller udvider dit professionelle netværk.",
      "Sådan gør Bumble en forskel",
      "Vi går op i integritet, venlighed, lighed, selvsikkerhed og respekt på alle stadier af et forhold. Vi er ikke bare åbne overfor forskellige orienteringer, vi fejrer dem – uanset om du er her for at finde kærlighed eller bare vil have det sjovt.",
      "Bumbles værdier",
      "På Bumble er det kvinderne, der sætter tempoet ved at tage det første skridt eller oprette et Opening Move, som matches kan svare på. Vi bytter rundt på forældede kønsroller og sætter fokus på ligestilling fra begyndelsen.",
      "Hvordan fungerer Bumble",
      "Om os",
      "Download nu",
      "Det er bedre på appen.",
      "Funktioner som gør det sjovt og behageligt at date online",
      "12. februar 2024",
      "Sådan styrker I den følelsesmæssige intimitet i forholdet",
      "25. januar 2024",
      "Sådan bruger du Bumble, når du aldrig har brugt en datingapp før",
      "5. februar 2024",
      "Deception Detector™",
      "Interessante nyheder fra datingverdenen",
      "Hvis størrelsen er vigtig, så gå til vores hjemmeside. Den er ligesom vores app – bare større.",
      "Webapp",
      "Sæt dating på pause med <b>Snooze-tilstand</b>. Når du er klar til at date igen, ved du, hvor vi er.",
      "Tag en pause",
      "Brug <b>videoopkald</b> og <b>audiochats</b> for at komme så tæt på IRL som muligt, uden at tage hjemmefra.",
      "Sæt lyd på",
      "Se mere",
      "Vælg <b>Opening Moves</b> for at invitere dit match til at starte chatten, så du ikke behøver at gøre det.",
      "Gør mindre, date mere",
      "Pak din kuffert, og match over hele verden med <b>Rejse-tilstand</b>.",
      "Tag os med",
      "Var du vild med profilen? Fortæl dem det med et <b>SuperSwipe</b>.",
      "Fang deres opmærksomhed",
      "Send nogle <b>komplimenter</b> til dem, så du skiller dig ud fra mængden.",
      "Få dem til at rødme",
      "Brug <b>Inkognito-tilstand</b> for at skjule din profil for din chef, familie eller ekskærester.",
      "Gå undercover",
      "Date på din måde",
      "Et par måneder senere mødte hun også en af sine brudepiger der.",
      "Hannah mødte sin mand på Bumble.",
      "Før de byggede deres tiny house sammen, mødtes Carène og You-Liang på Bumble, hvor You-Liang blev nysgerrig på Carènes rejsebilleder.",
      "Carène og You-Liang",
      "Brian og Kaci var udstationeret i udlandet sammen – men de mødte hinanden på Bumble",
      "Brian og Kaci",
      "Hvis det lykkedes for dem, kunne det også lykkes for dig",
      "Tag det næste skridt",
      "Se det nye",
      "Du fortjener det bedste. Derfor har vi designet fantastiske måder du kan date mere og stresse mindre på.",
      "Sæt barren tilbage til der, hvor den skal være",
      "Om Bumble",
      "Vi er den eneste app, der gør dating bedre ved at tage udgangspunkt i kvinders oplevelse. For når ting er bedre for kvinder, er de bedre for alle.",
      "Make the first move™",
      "Find en, du har lyst til at date, og tag så det første skridt. Skab stærke forbindelser, find det I har tilfælles, og – som det allervigtigste – hav det sjovt.",
      "Mød nogen med Bumble Date",
      "Find venner, der hjælper din karriere på vej. Mød andre professionelle for at finde dit næste job, en mentor eller en helt ny karriere.",
      "Giv din karriere et skub med Bumble Bizz",
      "Mød nogen ligesom dig. Eller helt anderledes end dig. Få nye venner, og find ting at lave sammen – uanset om det er ude eller hjemme.",
      "Find nye venner på Bumble For Friends",
      "Vi har ikke kun fokus på dating",
      "Bumble er datingappen, hvor kvinder tager det første skridt. Det er appen, der ændrede måden, som folk dater, skaber meningsfulde relationer og bygger netværk på. Download nu.",
      "Bumble på {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "Beklager, men vi kan ikke finde den side, du ledte efter.",
      "Kontakt us",
      "Log ind",
      "Hovsa, noget gik galt",
      "(opens in new window)",
      "FAQ",
      "Cookie Policy",
      "Premium",
      "Bumble - Blog",
      "Jobmuligheder",
      "Chat",
      "Byer",
      "Administrer Cookies",
      "Copyright © 2006 – til nu. Bumble. Alle rettigheder forbeholdes.",
      "Rapport: Dating med tillid",
      "Apple og Apple-logoet er varemærker tilhørende Apple Inc.{{__EOL__}}Google Play er et varemærke tilhørende Google LLC.",
      "Download",
      "Facebook",
      "Følg os",
      "Help Center",
      "Instagram",
      "Sprog",
      "LinkedIn",
      "Bumble er en del af Inc.",
      "Privatlivspolitik",
      "Safety Centre",
      "Sitemap",
      "Modern Slavery Act statement",
      "Vilkår og betingelser",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "plural": [
                  "komplimenter",
                  "komplimenters"
            ],
            "singular": [
                  "kompliment",
                  "kompliments"
            ]
      },
      "day": {
            "plural": [
                  "dage",
                  "dages"
            ],
            "singular": [
                  "dag",
                  "dags"
            ]
      },
      "hour": {
            "plural": [
                  "timer",
                  "timers"
            ],
            "singular": [
                  "time",
                  "times"
            ]
      },
      "minute": {
            "plural": [
                  "minutter",
                  "minutters"
            ],
            "singular": [
                  "minut",
                  "minuts"
            ]
      },
      "month": {
            "plural": [
                  "måneder",
                  "måneders"
            ],
            "singular": [
                  "måned",
                  "måneds"
            ]
      },
      "second": {
            "plural": [
                  "sekunder",
                  "sekunders"
            ],
            "singular": [
                  "sekund",
                  "sekunds"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipes",
                  "SuperSwipes"
            ],
            "singular": [
                  "SuperSwipe",
                  "SuperSwipe"
            ]
      },
      "year": {
            "plural": [
                  "år",
                  "årenes"
            ],
            "singular": [
                  "år",
                  "årets"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
