/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Annuleren",
      "Downloaden via de App Store",
      "Downloaden via Google Play",
      "Carrousel",
      "Slide {{number}}",
      "Volgende slide",
      "Vorige slide",
      "Slide",
      "Slide {{number}} van {{total}}",
      "Mijn persoonsgegevens niet verkopen of delen",
      "Mijn persoonsgegevens niet verkopen of delen",
      "Stuur Compliments op Bumble om te laten zien dat je het profiel van de ander leuk vindt, en trek iemands aandacht voordat je matcht. Probeer het nu",
      "Compliments | Een compliment sturen | Bumble",
      "Een persoonlijke pagina waarop je mensen vindt die goed bij je passen, dagelijks bijgewerkt.",
      "Ontdekken | Ontmoet mensen die bij je passen | Bumble",
      "Date op je eigen tempo en blijf in controle van wie je Bumble-profiel ziet met Privémodus. Lees meer over het gebruiken van Privémodus op Bumble in onze gids",
      "Privémodus | Je profiel verbergen | Bumble",
      "Nadat je iemand hebt ontmoet op een IRL-event van Bumble, kun je het gesprek voortzetten in de app.",
      "Instant match | Ga verder met je gesprek in de app | Bumble",
      "Probeer een nieuwe manier om het gesprek te beginnen met de functie Opening Moves van Bumble. Trek iemands aandacht, check de vibe en kom sneller tot de goede gesprekken.",
      "Bumble Opening Moves | Bumble",
      "Heb je een kleine pauze of wat tijd voor jezelf nodig? Ontdek hoe je je Bumble-account kunt pauzeren met de Snooze-modus van Bumble. Zo date jij op je eigen tempo.",
      "Snooze-modus | Pauzeer je profiel | Bumble",
      "Net op Bumble iemand gezien die je echt leuk vindt? Gebruik een SuperSwipe om aan te geven dat je serieus bent. Druk op de ster op iemands profiel om te beginnen, of bekijk hier meer details",
      "Bumble SuperSwipe | Wat is een SuperSwipe? | Bumble",
      "Reis je voor werk of voor je plezier? Met de Reismodus op Bumble vind je leuke nieuwe contacten op een nieuwe locatie en kun je daten waar je ook gaat. Ontdek Reismodus nu hier",
      "De Reismodus van Bumble | Daten, waar je ook bent | Bumble",
      "Wil je weten of de verbinding die je via de berichten voelt, ook chemie oplevert in het echt? Probeer nu een videochat of audiogesprek op Bumble en check de vibe",
      "Videochat | Een echt gesprek voeren | Bumble",
      "Ontdek zelf hoe leuk Bumble Web is, de ultieme ervaring.",
      "Bumble Web | Bumble",
      "Hier annuleren",
      "Homepage van Bumble",
      "Menu sluiten",
      "Menu openen",
      "Talenselectie",
      "Voettekst",
      "Toegankelijkheid bij Bumble",
      "Apple en het Apple-logo zijn handelsmerken van Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Carrière",
      "Evenementen",
      "Google Play is een handelsmerk van Google LLC.",
      "Richtlijnen",
      "Investeerders",
      "Cookies beheren",
      "Cookies beheren",
      "Verklaring Modern Slavery Act",
      "Bericht over gegevensverzameling",
      "Privacybeleid",
      "Datinggidsen per seizoen",
      "Algemene voorwaarden",
      "De app",
      "Privacybeleid gegevens consumentengezondheid Washington",
      "Over",
      "Ambassadeurs",
      "Blog",
      "Vind nieuwe vrienden en je eigen community",
      "Netwerk en zet carrièrestappen",
      "Zoek een relatie, iets dat niet bindend is of iets daartussenin",
      "Lees hier meer",
      "Ga internationaal met Reismodus",
      "Functies",
      "Compliments",
      "Geef de ander wat Compliments en val op tussen de rest.",
      "Ontdekken",
      "Een persoonlijke pagina waarop je mensen vindt die goed bij je passen, dagelijks bijgewerkt.",
      "Privémodus",
      "Gebruik Privémodus om je profiel te verbergen van je baas, familie of ex(en).",
      "Instant match",
      "Een gesprek met iemand begonnen op een IRL-event van Bumble? Zet het voort in de app.",
      "Opening Moves",
      "Kies Opening Moves: nodig je match uit om de chat te beginnen.",
      "Snooze-modus",
      "Even pauze met Snooze-modus. Als je weer wilt daten, weet je ons te vinden.",
      "SuperSwipe",
      "Heeft een profiel de juiste vibe? Laat het weten met een SuperSwipe.",
      "Reismodus",
      "Vind je dutyfree snack en match overal ter wereld met Reismodus.",
      "Videochat",
      "Gebruik videochat en audiobellen en kijk of je er klaar voor bent om elkaar in het echt te ontmoeten.",
      "Web-app",
      "De Bumble-ervaring in je browser.",
      "De winkel",
      "Hoofdmenu",
      "Door naar hoofdpagina",
      "Logo van Bumble",
      "Heb je interesse? Videochat op Bumble",
      "Tik op het videopictogram in je chat om een videogesprek te beginnen. Je hoeft geen persoonlijke gegevens te delen.",
      "Hoe werkt videochat op Bumble?",
      "Nee. Bij alle gesprekken en chats op Bumble blijven je persoonlijke gegevens veilig. De ander ontvangt je telefoonnummer of andere gegevens pas wanneer je ze zelf deelt.",
      "Kan mijn match mijn telefoonnummer zien?",
      "Zeker. Als je nog niet klaar bent voor een ontmoeting in het echt, kun je via een audiogesprek toch in contact komen. Je kunt dan het pictogram voor een audiogesprek gebruiken.",
      "Kan ik ook gewoon een audiogesprek kiezen?",
      "Niets beter dan de melding dat je een nieuw bericht hebt ontvangen. Maar gaat het gesprek in het echt ook zo leuk zijn? Dus: een video- of audiogesprek om de vibe te checken.",
      "Videochatten op Bumble",
      "Met <a href={{video_chat_url}}>videochats en audiogesprekken</a> kun je elkaar wat beter leren kennen en beslissen of je elkaar in het echt wilt ontmoeten. En het beste? Als je een gesprek voert via Bumble, hoef je je telefoonnummer (nog) niet te geven. Je persoonlijke gegevens blijven dus veilig, tot je er zelf voor kiest om ze te delen.",
      "Kijk of er chemie is met een videochat",
      "Online daten wordt iets meer offline, en je kunt erachter komen of je elkaar in het echt wilt gaat ontmoeten.",
      "Leer iemand beter (en sneller) kennen. Een video- of audiogesprek kan veel over iemand onthullen.",
      "Jij hebt de controle. We houden je contactgegevens privé, zodat je zelf kan kiezen wanneer je ze wilt delen.",
      "Een ontmoeting in het echt?",
      "Het is een snelle vibecheck",
      "Wel contact, geen persoonlijke gegevens",
      "Redenen om videochats en{{__EOL__}}-gesprekken te gebruiken",
      "Klaar om een gesprek te beginnen? Je kunt kiezen voor een audio- of videogesprek in de chat op de Bumble-app. Voor een videogesprek tik je in de rechterbovenhoek op het grijze pictogram met de videocamera. Je ziet naast het pictogram ook drie punten staan. Als je hierop tikt, vind je de optie om een audiogesprek te beginnen.",
      "Videochatten",
      "Compliments proberen?",
      "Wanneer je een Compliment verstuurt, zal de ontvangende persoon deze zien wanneer je in Voor jou verschijnt.",
      "Wat gebeurt er als je iemand een Compliment geeft op Bumble?",
      "Als het Compliment je aandacht heeft getrokken, ga dan voor een match. Je ziet het Compliment in je chat en de regels voor het <a href={{make_the_first_move_url}}>zetten van de eerste stap</a> zijn van toepassing.",
      "Hoe reageer ik op een Compliment?",
      "Je kunt gratis {{num}} [[Compliments:num]] sturen. Als ze op zijn, kun je er via de Bumble-app meer kopen in bundels van maximaal 30 stuks. Van deze wordt er één gebruikt om dit Compliment te sturen, en de rest kun je bewaren voor andere profielen die je leuk vindt. Geen zorgen, er is geen uiterste gebruiksdatum.",
      "Hoeveel Compliments kan ik sturen?",
      "Een leuk bericht (of twee) kan tot veel leiden. Als je een Compliment wilt sturen, maar je duim over het toetsenbord blijft zweven, kijk dan eens naar deze inspiratie:",
      "Meer voorbeelden bekijken",
      "\"Iedereen die [naam artiest] goed vindt, heeft smaak.\"",
      "\"Je glimlach is besmettelijk!\"",
      "\"Je bio heeft me laten lachen. Goed gewerkt.\"",
      "Betere compliments sturen op Bumble",
      "Wil je iemands dag mooier maken? Gebruik Compliments.",
      "Compliments op Bumble",
      "Gebruik Compliments op Bumble om een mogelijke match te laten weten dat je diens profiel leuk vindt (en wellicht een lach op diens gezicht te toveren). Je krijgt Compliments bij je {{service_level_lexeme_name}}-abonnement. Het is ook een manier om berichten te kunnen sturen en ontvangen voordat je hebt gematcht.",
      "Hoe de functie Compliments werkt",
      "Als je een Compliment ontvangt, verschijnt deze in Voor jou, bij het profiel van de afzender. Je krijgt ook een melding dat iemand een onderdeel van je profiel leuk vindt.",
      "Je compliments bekijken",
      "Je kunt een Compliment sturen door onder iemands profiel op het gele pictogram met het hartje te tikken. Let op waar je precies tikt, want je geeft een specifieke foto of deel van het profiel een compliment! Als je tikt, opent er een tekstvak waarin je je bericht kunt typen (zorg er wel voor dat het max. 150 tekens is!).",
      "Stuur een Compliment",
      "DOWNLOAD Bumble EN PROBEER ONTDEKKEN NU",
      "Elke dag vanaf 9 uur zie je in Ontdekken nieuwe mensen. Je kunt met ze kletsen over de dingen die jullie gemeen hebben.",
      "Wanneer wordt Ontdekken bijgewerkt?",
      "De pagina Ontdekken sorteert mogelijke matches in drie hoofdcategorieën: dezelfde interesses, dezelfde datedoelen en gemeenschappelijke community's. Profielaanbevelingen worden ook getoond, gebaseerd op eerdere matches en contacten.",
      "Hoe wordt mijn pagina Ontdekken samengesteld?",
      "Een persoonlijke pagina die de mensen highlight die goed bij je passen, dagelijks bijgewerkt",
      "VIND MENSEN MET DEZELFDE VIBE MET {{qr_code}} ONTDEKKEN",
      "Ontdekken toont je een selectie van mensen waarvan wij denken dat je ze leuk gaat vinden, op basis van dezelfde interesses, datedoelen en community's.",
      "Probeer Ontdekken",
      "ZOEK JE EEN NIEUWE MANIER OM RAAKVLAKKEN TE VINDEN?",
      "De pagina Ontdekken wordt elke dag bijgewerkt, om je nog meer mensen te laten zien die goed bij je passen. Zo hoef jij minder tijd te besteden aan zoeken naar een match, en houd je meer tijd over om diepe gesprekken te voeren met de mensen waarmee je een vibe voelt.",
      "AANBEVELINGEN WORDEN DAGELIJKS BIJGEWERKT",
      "De aanbevelingen van Ontdekken zijn gebaseerd op je profiel en met wie je eerder een match hebt gehad, dus je ziet mensen die hetzelfde leuk vinden en zoeken als jij. Of dat nu een vegan lobster roll of een vaste relatie is.",
      "PERSONLIJK GEMAAKT{{__EOL__}}VOOR JOU",
      "Bumble downloaden",
      "Veelgestelde vragen",
      "Klaar om Bumble anoniem te gebruiken?",
      "Als je de Privémodus inschakelt, word je niet aan andere Bumble-leden getoond, tenzij je iemands profiel liket.",
      "Hoe werkt Privémodus?",
      "Zonder Privémodus is je profiel zichtbaar voor andere mensen op Bumble, als je voldoet aan hun voorkeuren zoals leeftijd en locatie. Als je Privémodus inschakelt, word je alleen gezien door mensen die je hebt geliket.",
      "Wie kan mij zien in Privémodus op Bumble?",
      "Het gebruik van Privémodus is niet hetzelfde als <a href={{blocking_on_bumble_url}}>iemand op Bumble blokkeren</a>. Met deze functie kan de ander je nog steeds zien als je diegene leuk vindt (of als je diegene in het verleden een bericht hebt gestuurd).",
      "Is Privémodus hetzelfde als iemand blokkeren?",
      "Bepaal wie jouw profiel kan zien met Privémodus op Bumble.",
      "Privémodus op Bumble",
      "Als je wilt stoppen met daten of Bumble wil gebruiken zonder dat andere mensen je profiel zien, kun je Privémodus inschakelen. Als je deze functie activeert, verbergen we je voor iedereen (tenzij je iemand liket). Zo houd je de controle over wat je deelt en wie je ziet.",
      "Verberg je profiel met Privémodus",
      "Jij kiest wie je foto's en persoonlijke gegevens kan zien.",
      "Wil je iets persoonlijks in je profiel delen? Je kunt discreet delen wat je wilt met je huidige matches.",
      "Gebruik de app zonder dat je je zorgen hoeft te maken dat je je ex, collega of iemand anders tegenkomt die je liever niet date.",
      "Houd alles onder controle",
      "Bepaal zelf wat je deelt",
      "Ontwijk ongemakkelijke situaties",
      "Redenen waarom Privémodus geweldig is",
      "Mensen met een abonnement op {{service_level_lexeme_name}} en {{service_level_lexeme_name_two}} kunnen Privémodus gebruiken. Als je je hebt aangemeld, kun je deze modus gebruiken door naar je profiel te gaan, in de rechterbovenhoek op Instellingen te tikken en Privémodus in te schakelen.",
      "{{service_level_lexeme_name}} activeren",
      "Privémodus op Bumble gebruiken",
      "DOWNLOAD Bumble EN PROBEER INSTANT MATCH NU",
      "Ga in de Bumble-app naar het profielpictogram onderaan je scherm. Hier vind je vervolgens het pictogram voor de QR-code, rechtsboven in het scherm. Tik erop om de code te bekijken.",
      "Waar vind ik mijn persoonlijke QR-code?",
      "Nee. Je moet verbinding maken met wifi en de huidige, live QR-code gebruiken voor Instant match.",
      "Kan ik een screenshot van mijn QR-code maken om te delen?",
      "Nee, hoor. Als één persoon de code scant, is het automatisch een match.",
      "Moeten beide mensen de QR-code scannen?",
      "Met Instant match kun je automatisch zien wat jij en je nieuwe match gemeen hebben. Zo krijg je meteen een kijkje  in hoe goed jullie bij elkaar passen.",
      "VIND SNELLER JE RAAKVLAKKEN",
      "Een gesprek met iemand begonnen op een IRL-event van Bumble? Zet het voort in de app.",
      "MAAK VAN JE MEET-CUTE EEN {{qr_code}} INSTANT MATCH",
      "Met Instant match kun je verder kletsen met de meet-cute die je hebt ontmoet, zonder telefoonnummers of socialmediagegevens uit te wisselen. Laat de ander je QR-code scannen en het is een match op Bumble.",
      "Probeer Instant match",
      "IEMAND ONTMOET OP EEN IRL-EVENT VAN Bumble?",
      "In plaats van handles of telefoonnummers uit te wisselen, kun je met Instant match eerst de sfeer proeven via de app. Zo leer je elkaar beter kennen op je eigen voorwaarden, dankzij de veiligheidsfuncties van Bumble.",
      "EEN VEILIGERE MANIER OM IN CONTACT TE KOMEN",
      "Nadat je iemand hebt ontmoet op een IRL-event van Bumble, kun je het gesprek voortzetten in de app en kletsen over gedeelde interesses. Van een voorliefde voor het perfecte koffiezetapparaat tot het voortplanten van zeldzame vetplanten.",
      "HOUD HET GESPREK GAANDE",
      "Probeer Opening Moves op Bumble",
      "Opening Moves is een andere manier om te chatten met mensen die je leuk vindt. Zodra je een bericht hebt gekozen om te sturen, kun je rustig wachten op de reacties en reageren op wat je leuk vindt.",
      "Bumble Opening Moves gebruiken",
      "Voor non-binaire en gendergelijke contacten geldt dat iedereen een Opening Move kan instellen en erop kan reageren.",
      "Kan ik Bumble Opening Moves gebruiken als ik mensen van hetzelfde gender wil daten?",
      "Als iemand op je Opening Move reageert, verschijnt het profiel van die persoon in je chats. Vrouwen hebben 24 uur om te reageren voordat de match verloopt. Je kunt dan op het antwoord reageren of een nieuw gesprek beginnen.",
      "Hoe kan ik zien of iemand heeft gereageerd op mijn Opening Moves?",
      "Met Opening Moves willen we de druk verminderen om die eerste stap te zetten. Dit doen we met een snellere, makkelijkere manier om tot een goed gesprek te komen.",
      "Opening Moves op Bumble",
      "Goed dat je het vraagt. Opening Moves is een nieuwe, makkelijkere manier om een gesprek te beginnen. Je kunt een voorgestelde Opening Move kiezen, zoals 'Wat is je favoriete nummer als je de microfoon in je handen gedrukt krijgt op een feestje?' of er zelf een verzinnen. Als je een Opening Move hebt ingesteld, kunnen je matches op je vragen reageren als je profiel bij hen in het scherm verschijnt.",
      "Wat is een Opening Move?",
      "Ervaar je minder druk",
      "Laat je zien wat jou uniek maakt",
      "Begin je leukere gesprekken",
      "Met Opening Moves...",
      "Je kunt een Opening Move instellen die wordt verstuurd als je met andere mensen matcht. Ga naar Opening Moves in je profiel en kies een van de voorgestelde vragen (of verzin je eigen). Je kunt je Opening Moves altijd veranderen.",
      "Probeer Opening Moves",
      "Bumble Opening Moves gebruiken",
      "<a href={{see_more_url}}>Meer info</a>",
      "Even pauze? Snooze-modus",
      "Ja. Je kunt naar je instellingen of je wachtrij gaan en Snooze uitschakelen wanneer je maar wilt.",
      "Kan ik de Snooze-modus eerder dan gepland weer uitschakelen?",
      "Ga naar instellingen, tik op Snooze en kies hoelang je weg wilt zijn. Kom terug wanneer je er weer klaar voor bent.",
      "Hoe gebruik ik de Snooze-modus in mijn profiel?",
      "Nee. Als de Snooze-modus is ingeschakeld, worden alle swipe- en chatactiviteiten gepauzeerd. Als je naar je wachtrij gaat, zie je hoelang de Snooze-modus ingeschakeld is en kun je de modus weer uitschakelen.",
      "Kan ik in de Snooze-modus nog steeds swipen en chatten?",
      "Mensen waarmee je al berichten hebt uitgewisseld, zien je 'Afwezig'-status, als je ervoor kiest deze in te stellen. Zo niet, dan zien ze je eerdere berichten in hun inbox zoals normaal.",
      "Wat zien huidige matches als ik de Snooze-modus gebruikt?",
      "Jij hebt de volledige controle over je activiteiten op Bumble en kunt altijd even pauzeren.",
      "Je kunt bestaande contacten op de hoogte houden met een statusupdate, zodat het niet lijkt alsof je ze hebt <a href={{ghosted_article_url}}>geghost</a>. Dat gaat allemaal automatisch, dus je hoeft niet zelf nog berichten te sturen.",
      "Neem even pauze van actief daten zonder dat je je contacten of chats verliest.",
      "Date (of juist niet) op je eigen manier",
      "Houd je contacten op de hoogte",
      "Pauzeer je activiteiten, maar niet je contacten",
      "Waarom de Snooze-modus geweldig is",
      "Open de Bumble-app, ga naar je profiel en tik in de rechterbovenhoek op het pictogram voor de instellingen. In je Instellingen tik je op Snooze en kies je hoelang je weg bent. Je kunt je profiel voor 24 uur, 72 uur, een week of onbepaalde tijd verbergen.{{__EOL__}}{{__EOL__}}Houd er rekening mee dat matches zonder actieve chats in de Snooze-modus verlopen. Als je een status instelt, kunnen de mensen waarmee je chat zien dat je weg bent.",
      "Hoe de Snooze-modus op Bumble werkt",
      "Als het leven even te druk is, staat Snooze-modus voor je klaar. Pauzeer je Bumble-profiel en date op je eigen tempo.",
      "Snooze-modus op Bumble",
      "Snooze-modus is een gemakkelijke manier om je profiel op pauze te zetten als je even niet wilt daten. Je kunt nog steeds chatten met bestaande contacten, maar je profiel wordt voor andere daters verborgen totdat je er weer klaar voor bent om te swipen. Je verliest geen profielgegevens of bestaande contacten als je Snooze-modus inschakelt.",
      "Snooze je Bumble-profiel",
      "Als Snooze-modus is ingeschakeld, kun je ervoor kiezen om bestaande contacten met een statusupdate te laten weten dat je even pauze neemt. Kies uit 'Ik ben op reis', 'Ik ben druk met werk', 'Ik ben op een digitale detox' of 'Ik richt me even op mezelf'. Wat je vibe ook is, we staan voor je klaar.",
      "Voeg de status 'Afwezig' toe",
      "Als je Snooze-modus inschakelt, pauzeer je je Bumble-profiel (of {{page_partner_name_bff}}-profiel). Je matches weten niet dat je Snooze-modus gebruikt, tenzij je het ze laat weten. Wil je Snooze-modus eerder dan je had gepland weer uitschakelen? Dat kun je doen via je instellingen door op 'Snooze-modus uitschakelen' te tikken.",
      "Pauzeer je chats",
      "Probeer SuperSwipe op Bumble",
      "Zoveel als je wilt! Gebruikers van {{service_level_lexeme_name}} krijgen {{num}} [[superswipe:num]] per week, maar je kunt je saldo altijd aanvullen. Gratis leden kunnen losse pakketten met SuperSwipes kopen.",
      "Hoeveel SuperSwipes kan ik op Bumble versturen?",
      "Als iemand je profiel een SuperSwipe geeft, ontvang je direct een melding. Je ziet ook een markering in het profiel van diegene, waardoor je weet dat die persoon je leuk vindt.",
      "Hoe weet ik of ik een SuperSwipe krijg van iemand?",
      "Écht geïnteresseerd in iemand? We hebben een leuke manier om het ijs te breken. Gebruik een SuperSwipe om te laten zien dat je de ander leuk vindt en kom sneller tot leuke gesprekken.",
      "SuperSwipe op Bumble",
      "Het is niet altijd makkelijk om op te vallen tussen de rest, maar met een SuperSwipe kun je een goede eerste indruk maken.{{__EOL__}}{{__EOL__}}Als je een SuperSwipe gebruikt, ontvangt de persoon die je leuk vindt een melding. Je komt bij deze persoon vooraan te staan met een markering in je profiel. Als de ander jou ook leuk vindt, is het tijd voor een gesprek!",
      "Wat is een SuperSwipe?",
      "Val je meer op",
      "Begin je meer chats",
      "Ontmoet je meer contacten",
      "Met een SuperSwipe...",
      "Als je <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> of Bumble {{service_level_lexeme_name_one}} hebt, krijg je {{num}} gratis [[superswipe:num]] per week. Alle leden kunnen via hun profiel pakketten van maximaal {{num_two}} [[superswipe:num_two]] kopen. Als je {{service_level_lexeme_name_three}} hebt, krijg je {{num_three}} [[superswipe:num_three]] per week.{{__EOL__}}{{__EOL__}}Een SuperSwipe gebruiken is simpel: als je iemand ziet die je leuk vindt, tik je onderaan diens profiel op het gele sterpictogram.",
      "SuperSwipe gebruiken",
      "Apple en het Apple-logo zijn handelsmerken van Apple Inc. Google Play is een handelsmerk van Google LLC.",
      "Date on the go met de Reismodus van Bumble",
      "<a href={{bumble_premium_url}}>{{service_level_lexeme_name}}-leden kunnen Reismodus gebruiken</a>. Als je deze modus inschakelt, zie je de nieuwe gezichten in de door jou gekozen stad.",
      "Hoe gebruik ik Reismodus?",
      "Bijna wel. We hebben een paar veiligheidsbeperkingen die in de loop van de tijd kunnen veranderen, maar Reismodus is op de meeste bestemmingen beschikbaar.",
      "Kan ik Reismodus overal gebruiken?",
      "Reismodus geeft je locatie weer als het centrum van de gekozen stad, zodat nieuwe contacten in de buurt je profiel kunnen zien. In een notitie in je profiel staat dat je Reismodus gebruikt, zodat ze weten dat je op bezoek bent of komt.",
      "Je koffers aan het inpakken voor een vakantie of op weg naar een nieuwe stad voor je werk? Gebruik Reismodus van Bumble en date on the go.",
      "Reismodus op Bumble",
      "Reismodus is dé manier om contacten te leggen als je weg bent. Je kunt je locatie instellen op alle plekken van de wereld, en je profiel wordt getoond in de stad die je kiest. Dat betekent dat je kunt daten, overal waar je avontuur je brengt.",
      "Wat is Reismodus?",
      "Niemand kent een plek beter dan de locals. Met Reismodus kom je in contact met de mensen die je reis fantastisch gaan maken.",
      "Stel je locatie in op de stad tot zeven dagen voordat je vertrekt, zodat je dates en matches van tevoren kunt plannen.",
      "Denk verder dan alleen een strandreis. Misschien blijf je wel thuis voor de feestdagen, moet je reizen voor je werk of ga je een nieuwe stad ontdekken. Vind romantiek, waar je ook landt.",
      "Vind de beste lokale plekken voor een date",
      "Zoek het avontuur op",
      "Ontmoet waardevolle contacten",
      "Waarom Reismodus geweldig is",
      "Mensen met een abonnement op <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} en {{service_level_lexeme_name_two}} op Bumble</a> kunnen Reismodus gebruiken. Ga in de rechterbovenhoek van je profiel naar het pictogram voor instellingen en scrol tot je Reismodus ziet. Kies een nieuwe locatie om mensen in dat gebied voorbij te zien komen. Je profiel laat mogelijke dates weten dat je Reismodus gebruikt.",
      "Hoe werkt Reismodus?",
      "Proberen",
      "Probeer Bumble op het web",
      "Absoluut. De webapp van Bumble werkt op alle grote webbrowsers op de pc, Mac en tablet.",
      "Kan ik Bumble op mijn pc of mac gebruiken?",
      "Ja, dat kan. Je kunt berichten versturen of zelfs een video- of audiogesprek voeren op de webapp van Bumble.",
      "Kan ik met matches chatten in de webapp?",
      "Kies 'Aanmelden' of klik <a href={{sign_in_url}}>hier</a> om in te loggen. Je kunt inloggen met je Apple ID, Facebook of telefoonnummer.",
      "Hoe log ik in op Bumble op het web?",
      "Wil je even pauze van je telefoon? Probeer de webapp van Bumble. Het is dezelfde Bumble, maar dan op een groter scherm.",
      "De webversie van Bumble",
      "Je logt eenvoudig in bij Bumble op het web. Ga naar onze website, klik op 'Inloggen' en kies je gebruikelijke aanmeldmethode (met je Apple ID, Facebook-gegevens of telefoonnummer). Hoppa! Je bent binnen, en je chats en contacten zijn hetzelfde als op de app.",
      "Inloggen",
      "Log in via je browser",
      "Bewerk of pas je profiel aan en gebruik Extra zoekfilters.",
      "Bevestig dat jij het bent en vergroot de kans op een match.",
      "Deel leuke nieuwe foto's of verwijder oudere.",
      "Je profiel bijwerken",
      "Laat mensen dat jij het echt bent",
      "Foto's toevoegen of verwijderen",
      "Wat je kunt doen op de webapp van Bumble",
      "Aan de linkerkant vind je je chats en lijst met contacten en aan de rechterkant je potentiële nieuwe matches.{{__EOL__}}{{__EOL__}}Gebruik je pijltjestoetsen links en rechts, of klik op de symbolen aan de rechterkant van je scherm om profielen te bekijken en mensen te liken zoals je op je telefoon zou doen.",
      "Hoe gebruik ik de webapp van Bumble?",
      "Hier is vriendelijk zijn sexy, jezelf zijn het beste en staan vrouwen altijd op de eerste plaats.",
      "Hier wordt de eerste stap gezet",
      "Gezonde relaties vormen een essentieel onderdeel van een goed leven. Bumble is ontwikkeld zodat jij vol vertrouwen nieuwe contacten kunt vinden, of je nu wilt daten, op zoek bent naar nieuwe vrienden of je professionele netwerk wilt uitbreiden.",
      "Wat Bumble bijdraagt",
      "Wij vinden integriteit, vriendelijkheid, gelijkheid, vertrouwen en respect tijdens alle fases van een relatie belangrijk. We verwelkomen niet alleen elke geaardheid, we vieren ze! Of je hier nu bent om liefde te vinden of gewoon wat plezier.",
      "Wat voor Bumble belangrijk is",
      "Op Bumble bepalen de vrouwen door de eerste stap te zetten of een Opening Move in te stellen waar matches op kunnen reageren. De ouderwetse dynamiek is verleden tijd. Nu gaan we vanaf het allereerste begin voor gelijkheid.",
      "Hoe Bumble werkt",
      "Over ons",
      "Nu downloaden",
      "Beter op de app.",
      "Functies om online daten weer leuk te maken",
      "12 feb. 2024",
      "De emotionele intimiteit in je relatie vergroten",
      "25 jan. 2024",
      "Bumble gebruiken als je nog nooit een datingapp hebt gebruikt",
      "5 feb. 2024",
      "Deception Detector™",
      "Al het datingnieuws dat je moet weten",
      "Vind je de app te klein? Ga dan naar onze website. Die is hetzelfde, maar dan groter.",
      "Web-app",
      "Even pauze met <b>Snooze-modus</b>. Als je weer wilt daten, weet je ons te vinden.",
      "Even pauze",
      "Gebruik <b>videogesprekken</b> en <b>audiochats</b>. Zo is het bijna IRL, zonder dat je je huis uit hoeft.",
      "Gebruik eens geluid",
      "Meer bekijken",
      "Kies <b>Opening Moves</b> en nodig je match uit om de chat te beginnen.",
      "Minder doen, meer daten",
      "Vind je dutyfree snack en match overal ter wereld met <b>Reismodus</b>.",
      "Neem ons mee",
      "Heeft een profiel de juiste vibe? Laat het weten met een <b>SuperSwipe</b>.",
      "Trek iemands aandacht",
      "Geef de ander wat <b>Compliments</b> en val op tussen de rest.",
      "Een blos op de wangen",
      "Gebruik <b>Privémodus</b> om je profiel te verbergen voor je baas, familie of ex(en).",
      "Ga undercover",
      "Date hoe jij wilt",
      "Een paar maanden later ontmoette ze daar ook een van haar bruidsmeisjes.",
      "Hannah ontmoette haar man op Bumble",
      "Voordat ze samen hun tiny house zijn gaan bouwen, ontmoetten Carène en You-Liang elkaar op Bumble. You-Liang was totaal onder de indruk van de reisfoto's van Carène.",
      "Carène en You-Liang",
      "Brian en Kaci waren samen gestationeerd in het buitenland, maar ze ontmoetten elkaar op Bumble.",
      "Brian en Kaci",
      "Het werkte voor hen, dus waarom niet voor jou?",
      "Zet de volgende stap",
      "Bekijken wat nieuw is",
      "Jij verdient beter, dus we hebben leuke manieren ontworpen voor meer dates en minder stress.",
      "Leg de lat weer hoog",
      "Over Bumble",
      "Wij zijn de enige app waar daten een leukere ervaring wordt doordat vrouwen op de eerste plaats komen. Want als dingen beter zijn voor vrouwen, zijn dingen beter voor iedereen.",
      "Make the first move™",
      "Vind iemand waarmee je wilt daten en zet de eerste stap. Vind echte contacten, ontdek waar jullie van houden en vergeet niet om plezier te hebben.",
      "Ontmoet iemand met Bumble Date",
      "Friends with 'benefits', maar dan voor op de werkvloer. Kom in contact met andere professionals en vind je volgende baan, een mentor of misschien wel een hele nieuwe carrière.",
      "Zet stappen in je carrière met Bumble Bizz",
      "Ontmoet iemand net als jij. Of juist niet zoals jij. Maak nieuwe vrienden en ontdek nieuwe dingen om samen te doen, of dat nu thuis is of ergens anders.",
      "Vind nieuwe vrienden op Bumble For Friends",
      "Zoveel meer dan dating",
      "Bumble is de datingapp waar vrouwen de eerste stap zetten. Het is de app die heeft gezorgd voor een verandering in de manier waarop mensen daten, waarbij waardevolle relaties en netwerkcontacten zich ontwikkelen. Download nu.",
      "Bumble op {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "Helaas is de pagina die je zoekt niet te vinden",
      "Contact",
      "Inloggen",
      "Oeps, er ging iets fout",
      "(opens in new window)",
      "Veelgestelde vragen",
      "Cookie Policy",
      "Premium",
      "Bumble – Blog",
      "Vacatures",
      "Chatten",
      "Steden",
      "Cookies beheren",
      "Copyright © 2006 – heden. Bumble. Alle rechten voorbehouden.",
      "Rapport Vertrouwen in daten",
      "Apple en het Apple-logo zijn handelsmerken van Apple Inc.{{__EOL__}}Google Play is een handelsmerk van Google LLC.",
      "Downloaden",
      "Facebook",
      "Volg ons",
      "Hulppagina",
      "Instagram",
      "Taal",
      "LinkedIn",
      "Bumble is onderdeel van Bumble Inc.",
      "Privacybeleid",
      "Safety centre",
      "Sitemap",
      "Verklaring inzake moderne slavernij",
      "Algemene voorwaarden",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "plural": [
                  "complimenten"
            ],
            "singular": [
                  "compliment"
            ]
      },
      "day": {
            "plural": [
                  "dagen"
            ],
            "singular": [
                  "dag"
            ]
      },
      "hour": {
            "plural": [
                  "uur"
            ],
            "singular": [
                  "uur"
            ]
      },
      "minute": {
            "plural": [
                  "minuten"
            ],
            "singular": [
                  "minuut"
            ]
      },
      "month": {
            "plural": [
                  "maanden"
            ],
            "singular": [
                  "maand"
            ]
      },
      "second": {
            "plural": [
                  "seconden"
            ],
            "singular": [
                  "seconde"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipes"
            ],
            "singular": [
                  "SuperSwipe"
            ]
      },
      "year": {
            "plural": [
                  "jaar"
            ],
            "singular": [
                  "jaar"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
